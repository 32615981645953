import React, { useEffect, useState } from "react";
import {
  Box,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Chip,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useFetchObjects from "../../../api/useFetchObjects";
import { useAuth } from "../../../context/AuthContext";
import InboxLoading from "./loading/InboxLoading";
import InboxError from "./error/InboxError";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import useUpdate from "../../../api/useUpdate";
import { useTranslation } from "react-i18next";

const Inbox = ({ selectedChat, handleChatSelect }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;
  const userID = user?.user?.id; // ID of the logged-in user
  const location = useLocation();
  const product = location?.state?.product;
  const { handleUpdate } = useUpdate("update-message", token);

  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["my-messages"],
    `my-messages/${userID}/`, // Fetch messages specific to the logged-in user
    token
  );

  const { data: adminData } = useFetchObjects(
    ["search-user"],
    "search-user/?is_staff=true", // Fetch messages specific to the logged-in user
    token
  );

  useEffect(() => {
    if (product) {
      navigate(`/chat/${product?.display_user?.id}`, {
        state: {
          chatData: {
            receiver: userID,
            sender: product?.display_user?.id,
            userInfo: {
              first_name: product?.display_user?.first_name,
              last_name: product?.display_user?.last_name,
              phone_number: product?.display_user?.phone_number,
              username: product?.display_user?.username,
              image: product?.display_user?.image,
            },
            product: product,
          },
        },
      });
    }
  }, [product]);

  if (isLoading) {
    return <InboxLoading />;
  }

  if (isError) {
    return <InboxError message={t("chat.failedMessage")} onRetry={refetch} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: { xs: "84vh", sm: "90vh", md: "80vh" },
      }}
    >
      <Box
        sx={{
          padding: "12.5px",
          borderBottom: "1px solid #e0e0e0",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" fontWeight={"bold"}>
          {t("chat.inbox")}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ textTransform: "capitalize" }}
          onClick={() => {
            navigate(`/chat/${adminData[0]?.id}`, {
              state: {
                chatData: {
                  id: adminData[0]?.id,
                  receiver: adminData[0].id,
                  sender: userID,
                  userInfo: {
                    first_name: adminData[0]?.first_name,
                    last_name: adminData[0]?.last_name,
                    phone_number: adminData[0]?.phone_number,
                    username: adminData[0]?.username,
                    image: adminData[0]?.image,
                    is_staff: adminData[0]?.is_staff,
                  },
                  product: "",
                },
              },
            });
          }}
        >
          {t("chat.chatMessage")}
        </Button>
      </Box>

      <List sx={{ height: "100%", overflowY: "auto", overflowX: "hidden" }}>
        <>
          {data?.map((item) => {
            // Determine if the logged-in user is the sender or receiver

            return (
              <>
                {item?.sender === userID ? (
                  <ListItem
                    button
                    key={item?.id}
                    onClick={() => {
                      handleUpdate(item.id, { is_read: true });
                      navigate(`/chat/${item?.id}`, {
                        state: {
                          chatData: {
                            sender: item?.sender,
                            receiver: item?.receiver,
                            userInfo: {
                              first_name: item?.receiver_profile?.first_name,
                              last_name: item?.receiver_profile?.last_name,
                              phone_number:
                                item?.receiver_profile?.phone_number,
                              username: item?.receiver_profile?.username,
                              image: item?.receiver_profile?.image,
                            },
                          },
                        },
                      });
                    }}
                    selected={selectedChat?.id === item.id}
                    sx={{
                      padding: "10px 15px",
                      borderBottom: "1px solid #e0e0e0",
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        src={item?.receiver_profile?.image}
                        alt={item?.receiver_profile?.first_name}
                      />
                    </ListItemAvatar>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <ListItemText
                        primary={
                          item?.receiver_profile?.first_name &&
                          item?.receiver_profile?.last_name
                            ? `${item?.receiver_profile?.first_name} ${item?.receiver_profile?.last_name}`
                            : item?.receiver_profile?.username
                        }
                        secondary={
                          item?.message?.length <= 40
                            ? item?.message
                            : `${item?.message?.slice(0, 40)}...`
                        }
                        sx={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          textTransform: "capitalize",
                        }}
                      />
                      {!item.is_read && (
                        <Chip
                          label={t("chat.new")}
                          size="small"
                          color="success"
                        />
                      )}
                    </Box>
                  </ListItem>
                ) : (
                  <ListItem
                    button
                    key={item?.id}
                    onClick={() => {
                      handleUpdate(item.id, { is_read: true });
                      navigate(`/chat/${item?.id}`, {
                        state: {
                          chatData: {
                            sender: item?.sender,
                            receiver: item?.receiver,
                            userInfo: {
                              first_name: item?.sender_profile?.first_name,
                              last_name: item?.sender_profile?.last_name,
                              phone_number: item?.sender_profile?.phone_number,
                              username: item?.sender_profile?.username,
                              image: item?.sender_profile?.image,
                            },
                          },
                        },
                      });
                    }}
                    selected={selectedChat?.id === item.id}
                    sx={{
                      padding: "10px 15px",
                      borderBottom: "1px solid #e0e0e0",
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        src={item?.sender_profile?.image}
                        alt={item?.sender_profile?.first_name}
                      />
                    </ListItemAvatar>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <ListItemText
                        primary={
                          item?.sender_profile?.first_name &&
                          item?.sender_profile?.last_name
                            ? `${item?.sender_profile?.first_name} ${item?.sender_profile?.last_name}`
                            : item?.sender_profile?.username
                        }
                        secondary={
                          item?.message?.length <= 40
                            ? item?.message
                            : `${item?.message?.slice(0, 40)}...`
                        }
                        sx={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          textTransform: "capitalize",
                        }}
                      />
                      {!item.is_read && (
                        <Chip
                          label={t("chat.new")}
                          size="small"
                          color="success"
                        />
                      )}
                    </Box>
                  </ListItem>
                )}
              </>
            );
          })}
        </>
      </List>
    </Box>
  );
};

export default Inbox;
