import React from "react";
import {
  Button,
  Typography,
  Container,
  Grid,
  Box,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PlaceIcon from "@mui/icons-material/Place";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBackToRegister = () => {
    navigate("/register");
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={9}>
        <Box
          sx={{
            p: { xs: 2, md: 4 },
            mt: { xs: 3, md: 5 },
            mb: { xs: 3, md: 5 },

            boxShadow: "0px 0px 0px ",
          }}
        >
          <Typography
            variant="h3"
            gutterBottom
            align="center"
            sx={{
              fontWeight: 600,
              color: "#1976d2",
              fontSize: { xs: "1.8rem", md: "2.5rem" },
              lineHeight: 1.3,
            }}
          >
            {t("privacy_policy.privacyPolicy")}
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontWeight: 500,
                  fontSize: { xs: "1.2rem", md: "1.5rem" },
                  lineHeight: 1.4,
                }}
              >
                {t("privacy_policy.generalTerms")}
              </Typography>
              <Typography
                paragraph
                sx={{ fontSize: { xs: "0.9rem", md: "1rem" } }}
              >
                {t("privacy_policy.toCreateAccount")}
              </Typography>

              <Typography variant="h6" sx={{ mt: 3, fontWeight: "bold" }}>
                {t("privacy_policy.creatingAccount")}
              </Typography>
              <ol style={{ marginLeft: "20px", fontSize: "1rem" }}>
                <li>{t("privacy_policy.firstName")}</li>
                <li>{t("privacy_policy.phoneNumber")}</li>
                <li>{t("privacy_policy.seller_phone_verification")}</li>
                <li>{t("privacy_policy.seller_account_update_risk")}</li>
                <li>{t("privacy_policy.seller_id_image_username_address")}</li>
                <li>{t("privacy_policy.seller_optional_info")}</li>
              </ol>

              <Typography variant="h6" sx={{ mt: 3, fontWeight: "bold" }}>
                {t("privacy_policy.buyerAccount")}
              </Typography>
              <ol style={{ marginLeft: "20px", fontSize: "1rem" }}>
                <li>{t("privacy_policy.buyerAccountContent")}</li>
                <li>{t("privacy_policy.buyer_phone_verification")}</li>
                <li>{t("privacy_policy.buyer_account_update_risk")}</li>
                <li>{t("privacy_policy.buyerEmail")}</li>
              </ol>

              <Typography variant="h6" sx={{ mt: 3, fontWeight: "bold" }}>
                {t("privacy_policy.sharingInformation")}
              </Typography>
              <ul style={{ marginLeft: "20px", fontSize: "1rem" }}>
                <li>{t("privacy_policy.allTransactions")}</li>
                <li>{t("privacy_policy.actualPicture")}</li>
                <li>{t("privacy_policy.eachAd")}</li>
                <li>{t("privacy_policy.prohibitedItems")}</li>
                <li>{t("privacy_policy.adsPromote")}</li>
                <li>{t("privacy_policy.no_links_in_ads")}</li>
                <li>{t("privacy_policy.prohibited_items_policy")}</li>
                <li>{t("privacy_policy.no_offensive_content")}</li>
                <li>{t("privacy_policy.no_derogatory_content")}</li>
                <li>{t("privacy_policy.no_political_ads")}</li>
                <li>{t("privacy_policy.no_inciting_ads")}</li>
              </ul>

              <Typography variant="h6" sx={{ mt: 3, fontWeight: "bold" }}>
                {t("privacy_policy.responsibilities")}
              </Typography>
              <ol style={{ marginLeft: "20px", fontSize: "1rem" }}>
                <li>{t("privacy_policy.asanBawarResponsible")}</li>
                <li>{t("privacy_policy.however")}</li>
                <li>{t("privacy_policy.fraud_report_policy")}</li>
              </ol>

              <Typography variant="h6" sx={{ mt: 3, fontWeight: "bold" }}>
                {t("privacy_policy.copyright")}
              </Typography>
              <Typography
                paragraph
                sx={{ fontSize: { xs: "0.9rem", md: "1rem" } }}
              >
                {t("privacy_policy.allContent")}
              </Typography>

              <Typography variant="h6" sx={{ mt: 3, fontWeight: "bold" }}>
                {t("privacy_policy.dataProtection")}
              </Typography>
              <Typography
                paragraph
                sx={{ fontSize: { xs: "0.9rem", md: "1rem" } }}
              >
                {t("privacy_policy.personalInformation")}
              </Typography>

              <Typography variant="h6" sx={{ mt: 3, fontWeight: "bold" }}>
                {t("privacy_policy.policyChanges")}
              </Typography>
              <Typography
                paragraph
                sx={{ fontSize: { xs: "0.9rem", md: "1rem" } }}
              >
                {t("privacy_policy.asanBawarReserves")}
              </Typography>

              <Typography
                variant="h5"
                sx={{
                  mt: 4,
                  fontWeight: 500,
                  fontSize: { xs: "1.3rem", md: "1.5rem" },
                }}
              >
                {t("privacy_policy.contactAsanBawar")}
              </Typography>

              {/* Contact Information with Icons */}
              <Box
                sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton aria-label="email" color="primary">
                    <PlaceIcon />
                  </IconButton>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {t("privacy_policy.address")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton aria-label="email" color="primary">
                    <EmailIcon />
                  </IconButton>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {t("privacy_policy.email")}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton aria-label="phone" color="primary">
                    <PhoneIcon />
                  </IconButton>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {t("privacy_policy.phone")}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton aria-label="whatsapp" color="primary">
                    <WhatsAppIcon />
                  </IconButton>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {t("privacy_policy.whatsApp")}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PrivacyPolicy;
