import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import PersonIcon from "@mui/icons-material/Person";

import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import StatBox from "../../components/StatBox";
import LoadingSkeleton from "./LoadingSkeleton"; // Import LoadingSkeleton
import ErrorPage from "./ErrorPage"; // Import ErrorPage
import useFetchObjects from "../../../api/useFetchObjects";
import { useAuth } from "../../../context/AuthContext";
import { useTheme } from "@emotion/react";
import DateFormatter from "../../../components/DateFormatter/DateFormatter";
import { useNavigate } from "react-router-dom";
import ReportDetails from "./ReportDetails";
import HailIcon from '@mui/icons-material/Hail';
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(null);

  const { data, isError, isLoading, refetch } = useFetchObjects(
    ["total-all"],
    "total-all",
    token
  );

  if (isLoading) return <LoadingSkeleton />;
  if (isError)
    return <ErrorPage message={t("dashboard.failedLoad")} onRetry={refetch} />;

  const chartData = [
    {
      id: t("dashboard.mostSold"),
      color: tokens("dark").greenAccent[500],
      data: data?.products_report?.map((item) => ({
        x:
          item?.product_name?.length < 15
            ? item.product_name
            : `${item.product_name.slice(0, 15)}...`,
        y: item.sold_count,
      })),
    },
  ];

  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title={t("dashboard.dashboard")}
          subtitle={t("dashboard.welcome")}
        />
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}

        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={data.total_customers}
            subtitle={t("dashboard.totalUsers")}
            progress="0.75"
            increase="+14%"
            icon={
              <PersonIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={data?.total_sellers}
            subtitle={t("dashboard.totalSellers")}
            progress="0.30"
            increase="+5%"
            icon={
              <TransferWithinAStationIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={data?.total_buyers}
            subtitle={t("dashboard.totalBuyers")}
            progress="0.80"
            increase="+43%"
            icon={
              <HailIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={data?.total_today_orders}
            subtitle={t("dashboard.todayOrders")}
            progress="0.50"
            increase="+21%"
            icon={
              <ShoppingCartCheckoutIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                {t("dashboard.mostSoldProduct")}({data?.most_sold_product_name})
              </Typography>

              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {`${data?.most_sold_product_quantity} ${t("dashboard.sold")}` ||
                  0}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handleOpenDialog}>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart data={chartData || []} isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              {t("dashboard.todayOrders")}
            </Typography>
          </Box>
          {data?.recent_orders?.map((order, i) => (
            <Box
              key={order.id}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
              onClick={() => {
                navigate("/dashboard/orders", { state: order?.id });
              }}
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {t("dashboard.orderId")}: {order?.id}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {order?.display_user?.username
                    ? order?.display_user?.username
                    : `${order?.display_user?.first_name} ${order?.display_user?.last_name}`}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>
                {DateFormatter(order?.created_at)}
              </Box>
              <Box
                backgroundColor={colors.greenAccent[700]}
                p="5px 10px"
                borderRadius="4px"
              >
                {order.status === 1 && t("dashboard.pending")}
                {order.status === 2 && t("dashboard.canceled")}
                {order.status === 3 && t("dashboard.accepted")}
                {order.status === 4 && t("dashboard.inProgress")}
                {order.status === 4 && t("dashboard.delivered")}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Dialog maxWidth={"md"} open={openDialog} onClose={handleOpenDialog}>
        <DialogTitle sx={{ backgroundColor: "#3f51b5", color: "#fff" }}>
          <Typography variant="h3" fontWeight={"bold"}>
            {t("dashboard.reportDetails")}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{ backgroundColor: colors.primary[400], maxHeight: "65vh" }}
        >
          {data ? (
            <ReportDetails data={data} />
          ) : (
            <Alert severity="info">
              <Typography variant="h4"> {t("dashboard.noData")}</Typography>
            </Alert>
          )}
        </DialogContent>
        <DialogActions sx={{ backgroundColor: colors.primary[400] }}>
          <Button
            variant="outlined"
            color="secondary"
            sx={{ textTransform: "capitalize" }}
            onClick={handleOpenDialog}
          >
            {t("dashboard.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Dashboard;
