import React from "react";
import { Box, Typography, Grid, Stepper, Step, StepLabel } from "@mui/material";
import "./style.css";
import { useTranslation } from "react-i18next";
const OrderDetails = ({ buyerInfo, orderInfo, orderItems }) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={3}>
      {/* Buyer Information Table */}
      <Grid item xs={12} mt={2}>
        <Typography variant="h4" fontWeight={"bold"} mb={1}>
          {t("manage_order.buyerInformation")}
        </Typography>
        <Box
          sx={{
            border: "1px solid #555",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <table className="table">
            <thead>
              <tr>
                <th>{t("manage_order.id")}</th>
                <th>{t("manage_order.name")}</th>
                <th>{t("manage_order.gender")}</th>
                <th>{t("manage_order.email")}</th>
                <th>{t("manage_order.phone")}</th>
                <th>{t("manage_order.addressROrder")}</th>
                <th>{t("manage_order.activeNumber")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{buyerInfo?.display_user?.id}</td>
                <td>
                  {buyerInfo?.display_user?.first_name &&
                  buyerInfo?.display_user?.last_name
                    ? `${buyerInfo?.display_user?.first_name} ${buyerInfo?.display_user?.last_name}`
                    : buyerInfo?.display_user?.username}
                </td>

                <td>
                  {buyerInfo?.display_user?.gender === 1
                    ? t("manage_order.male")
                    : t("manage_order.female")}
                </td>

                <td>{buyerInfo?.display_user?.email}</td>

                <td>{buyerInfo?.display_user?.phone_number || "N/A"}</td>

                <td>{buyerInfo?.address || "N/A"}</td>

                <td>{buyerInfo?.contact || "N/A"}</td>
              </tr>
            </tbody>
          </table>
        </Box>
      </Grid>

      <Grid item xs={12} mt={2}>
        <Typography variant="h4" fontWeight="bold" mb={1}>
          {t("manage_order.orderInformation")}
        </Typography>
        <Box
          sx={{
            border: "1px solid #555",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <table className="table">
            <thead>
              <tr>
                <th>{t("manage_order.id")}</th>
                <th>{t("manage_order.date")}</th>
                <th>{t("manage_order.time")}</th>
                <th>{t("manage_order.totalAmount1")}</th>
                <th>{t("manage_order.totalAmount2")}</th>
                <th>{t("manage_order.status")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{orderInfo?.id}</td>
                <td>{orderInfo?.order_date}</td>
                <td>{orderInfo?.order_time.split(".")[0]}</td>
                <td>{orderInfo?.total_amount_afg || "N/A"}</td>
                <td>{orderInfo?.total_amount_usd || "N/A"}</td>
                <td>{orderInfo?.status || "N/A"}</td>
              </tr>
            </tbody>
          </table>
          {/* Order Steps */}
          <Box mt={3} px={2}>
            <Typography variant="h5" fontWeight="bold" mb={1}>
              {t("manage_order.orderStatusSteps")}:
            </Typography>
            <Stepper activeStep={orderInfo?.status - 1} alternativeLabel>
              <Step>
                <StepLabel>{t("manage_order.pending")}</StepLabel>
              </Step>
              <Step>
                <StepLabel>{t("manage_order.canceled")}</StepLabel>
              </Step>
              <Step>
                <StepLabel>{t("manage_order.accepted")}</StepLabel>
              </Step>
              <Step>
                <StepLabel>{t("manage_order.progress")}</StepLabel>
              </Step>
              <Step>
                <StepLabel>{t("manage_order.delivered")}</StepLabel>
              </Step>
            </Stepper>
          </Box>
          {/* Product Description */}
          <Typography
            variant="h4"
            padding={"0px 10px"}
            fontWeight={"bold"}
            mt={2}
          >
            {t("manage_order.description")}:
          </Typography>
          <Typography padding={"0px 10px"} className="description-text">
            {orderInfo?.description}
          </Typography>
        </Box>
      </Grid>

      {/* Seller Information Table */}
      {orderItems?.map((item) => (
        <Grid
          container
          spacing={3}
          justifyContent={"center"}
          margin={"0px 5px"}
        >
          <Grid item xs={12}>
            <Typography variant="h4" fontWeight={"bold"} mb={1}>
              {t("manage_order.sellerInformation")}
            </Typography>
            <Box
              sx={{
                border: "1px solid #555",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <table className="table">
                <thead>
                  <tr>
                    <th>{t("manage_order.id")}</th>
                    <th>{t("manage_order.name")}</th>
                    <th>{t("manage_order.gender")}</th>
                    <th>{t("manage_order.email")}</th>
                    <th>{t("manage_order.phone")}</th>

                    <th>{t("manage_order.shopName")}</th>
                    <th>{t("manage_order.shopAddress")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{item?.product?.display_user?.id}</td>
                    <td>
                      {item?.product?.display_user?.first_name &&
                      item?.product?.display_user?.last_name
                        ? `${item?.product?.display_user?.first_name} ${item?.product?.display_user?.last_name}`
                        : item?.product?.display_user?.username}
                    </td>

                    <td>
                      {item?.product?.display_user?.gender === 1
                        ? t("manage_order.male")
                        : t("manage_order.female")}
                    </td>

                    <td>{item?.product?.display_user?.email}</td>

                    <td>
                      {item?.product?.display_user?.phone_number || "N/A"}
                    </td>

                    <td>{item?.product?.display_user?.shop_name || "N/A"}</td>

                    <td>
                      {item?.product?.display_user?.shop_address || "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Grid>

          {/* Product Information Table */}
          <Grid item xs={12}>
            <Typography variant="h4" fontWeight={"bold"} mb={1}>
              {t("manage_order.productInformation")}
            </Typography>
            <Box
              sx={{
                border: "1px solid #555",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <table className="table">
                <thead>
                  <tr>
                    <th> {t("manage_order.id")}</th>
                    <th> {t("manage_order.productName")}</th>
                    <th> {t("manage_order.price")}</th>
                    <th> {t("manage_order.wholePrice")}</th>
                    <th> {t("manage_order.quantity")}</th>
                    <th> {t("manage_order.color")}</th>
                    <th> {t("manage_order.size")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{item?.product?.id}</td>
                    <td>{item?.product?.name}</td>

                    <td>
                      {item?.price} {item?.product?.currency === 1 ? " ؋" : "$"}
                    </td>

                    <td>
                      {item?.whole_price || 0}{" "}
                      {item?.product?.currency === 1 ? " ؋" : "$"}
                    </td>

                    <td>{item?.quantity}</td>

                    <td>
                      {item?.product?.colors
                        ?.map((color) => color.name)
                        .join(", ")}
                    </td>

                    <td>
                      {item?.product?.size === "S"
                        ? t("manage_order.small")
                        : item?.product?.size === "M"
                        ? t("manage_order.medium")
                        : item?.product?.size === "L"
                        ? t("manage_order.large")
                        : item?.product?.size === "XL"
                        ? t("manage_order.xL")
                        : item?.product?.size === "XXL"
                        ? t("manage_order.xxL")
                        : item?.product?.size}
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* Product Images */}
              {item?.product?.images?.length > 0 && (
                <Box className="img-container" ml={1}>
                  {item?.product?.images?.map((image, idx) => (
                    <img
                      key={idx}
                      src={`${process.env.REACT_APP_IMAGE_URL1}${image?.image}`} // Ensure this path is correct
                      alt={item?.product?.name}
                      style={{
                        maxWidth: "100px",
                        maxHeight: "100px",
                        marginRight: "10px",
                      }} // Optional styling for image size
                    />
                  ))}
                </Box>
              )}

              {/* Product Description */}
              <Typography
                variant="h4"
                padding={"0px 10px"}
                fontWeight={"bold"}
                mt={2}
              >
                {t("manage_order.description")}:
              </Typography>
              <Typography padding={"0px 10px"} className="description-text">
                {item?.product?.description}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default OrderDetails;
