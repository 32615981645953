import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import { Button, Box, Slider, Paper, Typography } from "@mui/material";
import getCroppedImg from "./cropImage";
import { useTranslation } from "react-i18next";

const ImageCropper = ({ imageSrc, onCropComplete, onCancel }) => {
  const { t } = useTranslation();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropChange = (newCrop) => setCrop(newCrop);
  const onZoomChange = (newZoom) => setZoom(newZoom);

  const onCropCompleteHandler = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCrop = async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      onCropComplete(croppedImage);
    } catch (error) {
      console.error("Crop failed:", error);
    }
  };

  return (
    <Paper
      sx={{
        position: "relative",
        width: "100%",
        maxWidth: 600,
        height: "auto",
        margin: "auto",
        boxShadow: 3,
        borderRadius: 2,
        overflow: "hidden",
      }}
    >
      <Box sx={{ position: "relative", width: "100%", height: 400 }}>
        <Cropper
          image={imageSrc}
          crop={crop}
          zoom={zoom}
          aspect={4 / 3}
          onCropChange={onCropChange}
          onZoomChange={onZoomChange}
          onCropComplete={onCropCompleteHandler}
        />
      </Box>
      <Box
        sx={{
          mt: 2,
          px: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
        }}
      >
        <Typography variant="body1" sx={{ color: "text.secondary" }}>
          {t("manage_product.zoom")}
        </Typography>
        <Slider
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          onChange={(e, newValue) => setZoom(newValue)}
          sx={{
            width: "60%",
            color: "secondary.main",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 2,
          px: 3,
          pb: 3,
        }}
      >
        <Button
          variant="outlined"
          color="error"
          onClick={onCancel}
          sx={{
            textTransform: "capitalize",
            borderRadius: 2,
            width: "45%",
          }}
        >
          {t("manage_product.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCrop}
          sx={{
            textTransform: "capitalize",
            borderRadius: 2,
            width: "45%",
          }}
        >
          {t("manage_product.crop")}
        </Button>
      </Box>
    </Paper>
  );
};

export default ImageCropper;
