import React from "react";
import { Route, Routes } from "react-router-dom";
import Products from "../../web/pages/products/Products";
import ProductDetails from "../../web/pages/products/ProductDetails";

const ProductsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Products />} />
      <Route path="/:id" element={<ProductDetails />} />
    </Routes>
  );
};

export default ProductsRoutes;
