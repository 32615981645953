import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAdd from "../../../api/useAdd"; // Import useAdd hook
import { useAuth } from "../../../context/AuthContext"; // Import auth context
import { useTranslation } from "react-i18next";

function EmailVerify() {
  const { t } = useTranslation();
  const location = useLocation();
  const userEmail = location?.state?.email;
  const auth = useAuth(); // Use the auth context
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [formData, setFormData] = useState({
    email: userEmail || "", // Assuming you pass email in the form
  });

  const [formErrors, setFormErrors] = useState({});
  const { handleAdd, loading, resetForm } = useAdd(
    "resend-email-otp",
    "", // Optional redirect URL
    t("user.otpResendSuccess"), // Success message
    t("user.otpResendFailure") // Failure message
  );

  useEffect(() => {
    if (resetForm) {
      setFormData({
        email: "",
      });

      navigate("/otp-verify", { state: { email: formData.email } }); // Redirect to login page after successful verification
      setFormErrors({});
    }
  }, [resetForm]);

  const handleResendOPT = (e) => {
    e.preventDefault();
    let errors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) errors[key] = t("user.fieldRequired");
    });

    if (Object.keys(errors).length === 0) {
      const submitData = new FormData();
      Object.keys(formData).forEach((key) =>
        submitData.append(key, formData[key])
      );
      handleAdd(submitData); // Submit the OTP form data
    } else {
      setFormErrors(errors);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: "" });
  };

  const textFieldStyles = {
    input: {
      color: theme.palette.text.primary,
      bgcolor: "rgba(255, 255, 255, 0.9)",
      borderRadius: "10px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.grey[300],
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main,
      },
    },
    label: {
      color: theme.palette.grey[600],
      "&.Mui-focused": {
        color: theme.palette.primary.main,
      },
    },
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "88vh",
        width: "100%",
        background: `linear-gradient(135deg, #e0f7fa 30%, #fce4ec 90%)`,
      }}
    >
      <CssBaseline />
      <Grid
        item
        xs={11.5}
        sm={9}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Card
          elevation={12}
          sx={{
            width: "100%",
            maxWidth: "600px", // Set max width for larger screens
            borderRadius: "10px", // Rounded corners
            padding: isSmallScreen ? 1 : 2, // Adjust padding for smaller screens
            boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.2)", // Subtle shadow
            background: "rgba(255, 255, 255, 0.8)", // Semi-transparent background
            backdropFilter: "blur(20px)", // Glassmorphism effect
            transition: "transform 0.3s ease-in-out",
            "&:hover": {
              transform: "translateY(-10px)", // Subtle hover effect
            },
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                component="h1"
                variant={isSmallScreen ? "h6" : "h5"}
                color={theme.palette.primary.main}
                fontWeight="700"
                sx={{ mb: 2 }}
              >
                {t("user.verifyEmail1")}
              </Typography>
              <Typography
                variant="body2"
                color={theme.palette.text.secondary}
                sx={{ mb: 1, fontStyle: "italic" }}
              >
                {t("user.enterOtp")}
              </Typography>
              <Box
                component="form"
                onSubmit={handleResendOPT}
                sx={{ mt: 2, width: "100%" }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  size="small"
                  id="email"
                  label={t("user.email")}
                  name="email"
                  value={formData.email}
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: textFieldStyles.label,
                  }}
                />

                <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: "capitalize",
                        ":hover": { backgroundColor: theme.palette.grey[200] },
                      }}
                      onClick={handleResendOPT}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={20} />
                      ) : (
                        t("user.sendOtp")
                      )}
                    </Button>
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <Link to="/login">
                      <Typography
                        variant="body2"
                        color={theme.palette.text.secondary}
                        sx={{
                          ":hover": { color: theme.palette.primary.main },
                        }}
                      >
                        {t("user.backToLogin")}
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default EmailVerify;
