import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Paper,
  CircularProgress,
} from "@mui/material";
import { LocationOn, Phone, Email } from "@mui/icons-material";
import { useSSR, useTranslation } from "react-i18next";
import useAdd from "../../../api/useAdd";
import { useAuth } from "../../../context/AuthContext";
import MessageIcon from "@mui/icons-material/Message";
import { useNavigate } from "react-router-dom";

const Contact = () => {
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;
  const navigate = useNavigate();

  const { handleAdd, loading, resetForm } = useAdd(
    "contact",
    token,
    "Your comment sended successfully!",
    "Field to submit your comment!"
  );

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    text: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setFormData({
      name: "",
      email: "",
      text: "",
    });
  }, [resetForm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};

    if (!formData.name) {
      errors.name = t("contact.nameRequired");
    }

    if (!formData.email) {
      errors.email = t("contact.emailRequired");
    }
    if (!formData.text) {
      errors.text = t("contact.descriptionRequired");
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    handleAdd(formData);
  };
  const { t } = useTranslation();
  return (
    <Container maxWidth="lg" sx={{ py: { xs: 4, sm: 6, md: 8 } }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: {
                xs: "30vh",
                sm: "40vh",
                md: "50vh",
                lg: "55vh",
                xl: "60vh",
              },
              borderRadius: "16px",
              overflow: "hidden",
            }}
          >
            <Box
              component="img"
              src={`${process.env.REACT_APP_IMAGE_URL}images/logo1.jpg`}
              alt="Contact Us"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background:
                  "linear-gradient(to top, rgba(255, 255, 255, 0.7) 5%, rgba(255, 255, 255, 0) 50%)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "16px",
              }}
            ></Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              p: { xs: 2, sm: 4 },
              height: {
                xs: "auto",
                sm: "auto",
                md: "100%",
              },
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                {t("contact.getInTouch")}
              </Typography>
              <Typography variant="body1" color="text.secondary" gutterBottom>
                {t("contact.content")}
              </Typography>
            </Box>

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    label={t("contact.name")}
                    variant="outlined"
                    error={!!formErrors.name}
                    helperText={formErrors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    label={t("contact.email")}
                    variant="outlined"
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="text"
                    value={formData.text}
                    onChange={handleInputChange}
                    label={t("contact.message")}
                    variant="outlined"
                    multiline
                    rows={4}
                    error={!!formErrors.text}
                    helperText={formErrors.text}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={loading}
                  >
                    {loading && <CircularProgress size={"small"} />}{" "}
                    {t("contact.submit")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              p: { xs: 2, sm: 4 },
              height: {
                xs: "auto",
                sm: "auto",
                md: "100%",
              },
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                {t("contact.contactInfo")}
              </Typography>
              <Box display="flex" alignItems="center" mb={2}>
                <LocationOn color="primary" />
                <Typography variant="body1" style={{ margin: "0em 1em" }}>
                  {t("contact.kabul")} 🇦🇫
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={2}>
                <Phone color="primary" />
                <Typography variant="body1" style={{ margin: "0em 1em" }}>
                 {t("contact.phone")} 
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Email color="primary" />
                <Typography variant="body1" style={{ margin: "0em 1em" }}>
                  info@asanbawar.com
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mt={1}>
                <MessageIcon color="primary" />
                <Button
                  variant="outlined"
                  size="small"
                  style={{ margin: "0em 1em" }}
                  startIcon={<MessageIcon style={{margin:"0px 4px"}} />}
                  onClick={() => {
                    navigate("/chat");

                  }}
                >
                  {t("contact.chat")}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Contact;
