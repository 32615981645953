import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Avatar,
  InputAdornment,
  Checkbox,
  IconButton,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import useAdd from "../../../api/useAdd";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

const generateRandomNumbers = () => {
  // Generate a random number between 100 and 999
  const randomNumber = Math.floor(100 + Math.random() * 900);
  return randomNumber.toString(); // Convert to string format
};

function Register() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isSeller, setIsSeller] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    password: "",
    tazkira_number: "",
    phone_number: "",
    is_seller: isSeller,
    email: "",
    address: "",
    shop_name: "",
    work_license_copy: null,
    image: null,
    gender: "",
    is_visible_phone: true,
    shop_address: "",
    tazkira_copy: null,
    verified: false,
  });
  const [agreed, setAgreed] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [imagePreview, setImagePreview] = useState(null);
  const [workLicensePreview, setWorkLicensePreview] = useState(null);
  const [tazkiraCopyPreview, setTazkiraCopyPreview] = useState(null);

  const { handleAdd, resetForm, loading } = useAdd(
    "register",
    "",
    t("user.registerSuccess"),
    t("user.failedRegister")
  );

  useEffect(() => {
    if (formData?.last_name) {
      setFormData({
        ...formData,
        username: formData?.last_name + generateRandomNumbers(),
      });
    }
  }, [formData.last_name]);

  useEffect(() => {
    if (resetForm) {
      if (formData.is_seller) {
        navigate("/otp-verify", { state: { email: formData.email } });
      } else {
        navigate("/login");
      }

      setFormData({
        first_name: "",
        last_name: "",
        username: "",
        password: "",
        tazkira_number: "",
        phone_number: "",
        is_seller: isSeller,
        email: "",
        address: "",
        shop_name: "",
        image: "",
        gender: "",
        is_visible_phone: false,
        shop_address: "",
        work_license_copy: null,
        tazkira_copy: null,
      });

      setFormErrors({});
    }
  }, [resetForm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};

    const phoneNumberRegex = /^07\d{8}$/;
    // Conditional validation based on account type (is_seller)
    if (isSeller) {
      // Validation for sellers
      if (!formData.first_name) errors.first_name = t("user.firstNameRequired");
      if (!formData.last_name) errors.last_name = t("user.lastNameRequired");
      if (!formData.password) errors.password = t("user.passwordRequired");
      if (formData.password.length < 6)
        errors.password = t("user.passwordCharacters");
      if (!formData.gender) {
        errors.gender = t("user.genderRequired");
      }

      if (!formData.username) errors.username = t("user.userNameRequired");
      if (!formData.email) errors.email = t("user.emailRequired");
      if (!formData.phone_number)
        errors.phone_number = t("user.phonNumberRequired");
      if (!formData.tazkira_number)
        errors.tazkira_number = t("user.tazkiraNumberRequired");
      if (!formData.tazkira_copy)
        errors.tazkira_copy = t("user.tazkiraCopyRequired");

      if (!formData.shop_address)
        errors.shop_address = t("user.shopAddressRequired");
      if (!formData.phone_number) {
        errors.phone_number = t("user.phoneNumberRequired");
      } else if (!phoneNumberRegex.test(formData.phone_number)) {
        errors.phone_number = t("user.phoneNumberStart");
      }
    } else {
      // Validation for buyers
      if (!formData.first_name) errors.first_name = t("user.firstNameRequired");
      if (!formData.last_name) errors.last_name = t("user.lastNameRequired");
      if (!formData.password) errors.password = t("user.passwordRequired");
      if (formData.password.length < 6)
        errors.password = t("user.passwordCharacters");
      if (!formData.username) errors.username = t("user.userNameRequired");

      if (!formData.gender) {
        errors.gender = t("user.genderRequired");
      }
      if (!formData.phone_number) {
        errors.phone_number = t("user.phoneNumberRequired");
      } else if (!phoneNumberRegex.test(formData.phone_number)) {
        errors.phone_number = t("user.phoneNumberStart");
      }
    }

    // Validate form data
    if (isSeller) {
      if (!formData.phone_number) {
        errors.phone_number = t("user.phoneNumberRequired");
      }
    } else {
      if (!formData.phone_number) {
        errors.phone_number = t("user.phoneNumberRequired");
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const submitData = new FormData();

    submitData.append("first_name", formData.first_name);
    submitData.append("last_name", formData.last_name);
    submitData.append("username", formData.username);
    submitData.append("password", formData.password);
    submitData.append("tazkira_number", formData.tazkira_number);
    submitData.append("phone_number", formData.phone_number);
    submitData.append("is_seller", formData.is_seller);
    submitData.append("email", formData.email);
    submitData.append("address", formData.address);
    submitData.append("shop_name", formData.shop_name);
    submitData.append("gender", formData.gender);

    if (formData.work_license_copy) {
      submitData.append("work_license_copy", formData.work_license_copy);
    }
    if (formData.tazkira_copy) {
      submitData.append("tazkira_copy", formData.tazkira_copy);
    }

    if (formData.image) {
      submitData.append("image", formData.image);
    }
    submitData.append("is_visible_phone", formData.is_visible_phone);
    submitData.append("shop_address", formData.shop_address);

    handleAdd(submitData);
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const { name } = event.target;

    if (file) {
      if (name === "image") {
        setImagePreview(URL.createObjectURL(file));
        setFormData({ ...formData, image: file });
      }

      if (name === "work_license_copy") {
        setWorkLicensePreview(URL.createObjectURL(file));
        setFormData({ ...formData, work_license_copy: file });
      }

      if (name === "tazkira_copy") {
        setTazkiraCopyPreview(URL.createObjectURL(file));
        setFormData({ ...formData, tazkira_copy: file });
      }
    }
  };

  const handleAccountTypeChange = (event) => {
    const selectedValue = event.target.value === "true";
    setIsSeller(selectedValue);
    setFormData({ ...formData, is_seller: selectedValue });
  };

  const handleAgreeChange = (event) => {
    setAgreed(event.target.checked);
  };

  return (
    <Grid
      container
      justifyContent={"center"}
      sx={{
        width: "100%",
        background: `linear-gradient(135deg, #e0f7fa 30%, #fce4ec 90%)`,
      }}
    >
      <Grid
        item
        xs={12}
        sm={11}
        md={8}
        sx={{
          pt: isSmallScreen ? 2 : 4,
          padding: { xs: "25px 20px", md: "20px 40px" },
          boxShadow: "0px 0px 5px 2px #e6ebf2",
          borderRadius: "10px",
          mt: 2,
          mb: 5,
          backgroundColor: "white",
        }}
      >
        <CssBaseline />
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{ mt: 2 }}
          enctype="multipart/form-data"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* Logo section */}
            <Box sx={{ mb: 3 }}>
              <Avatar
                alt="Logo"
                src={imagePreview && imagePreview}
                sx={{ width: 100, height: 100 }}
              />
            </Box>
            <Typography component="h1" variant={isSmallScreen ? "h6" : "h5"}>
              {t("user.register")}
            </Typography>

            <Box>
              <Grid container spacing={5}>
                {/* Profile Image Upload */}
                <Grid item xs={12} textAlign={"center"}>
                  <Typography variant="body1">
                    {t("user.uploadProfilePicture")}
                  </Typography>
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="profile-image-upload"
                  />
                  <label htmlFor="profile-image-upload">
                    <Button variant="outlined" component="span" sx={{ mt: 1 }}>
                      {t("user.chooseImage")}
                    </Button>
                  </label>
                </Grid>
                {/* First and Last Name */}
                <Grid item xs={12} sm={6}>
                  <FormLabel>{t("user.firstName")}</FormLabel>
                  <TextField
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    required
                    fullWidth
                    size="small"
                    error={!!formErrors.first_name}
                    helperText={formErrors.first_name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel>{t("user.lastName")}</FormLabel>
                  <TextField
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    required
                    fullWidth
                    size="small"
                    error={!!formErrors.last_name}
                    helperText={formErrors.last_name}
                  />
                </Grid>
                {/* Username and Email */}
                <Grid item xs={12} sm={6}>
                  <FormLabel>{t("user.username")}</FormLabel>
                  <TextField
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    required
                    fullWidth
                    size="small"
                    error={!!formErrors.username}
                    helperText={formErrors.username}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel>{t("user.email")}</FormLabel>
                  <TextField
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    fullWidth
                    type="email"
                    size="small"
                    required
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                  />
                </Grid>
                {/* Password */}
                <Grid item xs={12} sm={6}>
                  <FormLabel>{t("user.password")}</FormLabel>
                  <TextField
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    size="small"
                    error={!!formErrors.password}
                    helperText={formErrors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel sx={{ mb: 1 }}>{t("user.phoneNumber")}</FormLabel>
                  <TextField
                    type="number"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    required
                    error={!!formErrors.phone_number}
                    helperText={formErrors.phone_number}
                  />
                </Grid>
                {/* Account Type */}
                <Grid item xs={12} sm={3.5}>
                  <FormLabel sx={{ mb: 1 }}>{t("user.accountType")}</FormLabel>
                  <TextField
                    select
                    fullWidth
                    required
                    size="small"
                    name="is_seller"
                    value={formData.is_seller.toString()}
                    onChange={handleAccountTypeChange}
                  >
                    <MenuItem value={"true"}>{t("user.seller")}</MenuItem>
                    <MenuItem value={"false"}>{t("user.buyer")}</MenuItem>
                  </TextField>
                </Grid>

                {/* Phone Visibility
                <Grid item xs={12} sm={5}>
                  <FormControl fullWidth>
                    <FormLabel>{t("user.phoneNumberVisible")}</FormLabel>
                    <RadioGroup
                      defaultValue={true}
                      name="is_visible_phone"
                      onChange={handleChange}
                      row
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={t("user.yes")}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={t("user.no")}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid> */}

                {/* Phone Visibility */}
                <Grid item xs={12} sm={3.5}>
                  <FormControl fullWidth>
                    <FormLabel>{t("user.gender")}</FormLabel>
                    <RadioGroup name="gender" onChange={handleChange} row>
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label={t("user.male")}
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio />}
                        label={t("user.female")}
                      />
                    </RadioGroup>
                  </FormControl>
                  <Typography variant="body2" color={"red"}>
                    {formErrors.gender}
                  </Typography>
                </Grid>
              </Grid>

              {/* Seller specific fields */}
              {isSeller && (
                <Grid container spacing={4} mt={1}>
                  <Grid item xs={12} sm={4} textAlign={"start"}>
                    <FormLabel>{t("user.shopName")}</FormLabel>
                    <TextField
                      fullWidth
                      name="shop_name"
                      placeholder={t("user.shopName1")}
                      value={formData.shop_name}
                      onChange={handleChange}
                      size="small"
                      error={!!formErrors.shop_name}
                      helperText={formErrors.shop_name}
                      sx={{ pt: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormLabel>{t("user.shopAddress")}</FormLabel>
                    <TextField
                      fullWidth
                      id="shop_address"
                      name="shop_address"
                      value={formData.shop_address}
                      onChange={handleChange}
                      size="small"
                      error={!!formErrors.shop_address}
                      helperText={formErrors.shop_address}
                      sx={{ pt: 1 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} textAlign={"start"}>
                    <Typography variant="body1">
                      {t("user.uploadLicense")}
                    </Typography>
                    <input
                      type="file"
                      name="work_license_copy"
                      accept="*"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      id="work_license_copy"
                    />
                    <label htmlFor="work_license_copy">
                      <Button
                        variant="outlined"
                        component="span"
                        sx={{ mt: 1 }}
                        fullWidth
                      >
                        {t("user.chooseFile")}
                      </Button>
                      {formErrors.work_license_copy && (
                        <Typography variant="body2" color="red">
                          {formErrors.work_license_copy}
                        </Typography>
                      )}
                    </label>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormLabel>{t("user.tazkiraNumber")}</FormLabel>
                    <TextField
                      fullWidth
                      name="tazkira_number"
                      value={formData.tazkira_number}
                      onChange={handleChange}
                      size="small"
                      error={!!formErrors.tazkira_number}
                      helperText={formErrors.tazkira_number}
                      sx={{ pt: 1 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} textAlign={"start"}>
                    <Typography variant="body1">
                      {t("user.uploadTazkira")}
                    </Typography>
                    <input
                      type="file"
                      name="tazkira_copy"
                      accept="*"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      id="tazkira_copy"
                    />
                    <label htmlFor="tazkira_copy">
                      <Button
                        variant="outlined"
                        component="span"
                        sx={{ mt: 1 }}
                        fullWidth
                      >
                        {t("user.chooseFile")}
                      </Button>
                      {formErrors.tazkira_copy && (
                        <Typography variant="body2" color="red">
                          {formErrors.tazkira_copy}
                        </Typography>
                      )}
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6} display={"flex"}>
                    {workLicensePreview && (
                      <Box
                        component={"img"}
                        src={workLicensePreview}
                        sx={{
                          width: "16vw",
                          height: { xs: "12vh", md: "20vh" },
                        }}
                      ></Box>
                    )}
                    {tazkiraCopyPreview && (
                      <Box
                        component={"img"}
                        src={tazkiraCopyPreview}
                        sx={{
                          width: "16vw",
                          height: { xs: "12vh", md: "20vh" },
                          ml: 2,
                        }}
                      ></Box>
                    )}
                  </Grid>
                </Grid>
              )}

              {/* Submit button for buyers */}

              <Grid conatiner>
                <Box sx={{ mt: 4, display: "flex", flexDirection: "column" }}>
                  <Link
                    to={"/privacy-policy"}
                    target="_blank"
                    style={{ textDecoration: "underline", color: "blue" }}
                  >
                    {t("user.readOurPrivacy")}
                  </Link>
                  <FormControlLabel
                    control={
                      <Checkbox checked={agreed} onChange={handleAgreeChange} />
                    }
                    label={t("user.iAgree")}
                  />
                </Box>
                <Box>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={loading || !agreed}
                  >
                    {loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      t("user.register")
                    )}
                  </Button>
                </Box>
              </Grid>
            </Box>

            <Grid container justifyContent="flex-end">
              <Grid item>
                {t("user.alreadyHaveAccount")}
                <Link to="/login">{t("user.signIn")}</Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Register;
