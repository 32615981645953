import React from 'react'
import {Route, Routes} from "react-router-dom"
import Wishlist from '../../web/pages/wishList/Wishlist'

const WishListRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Wishlist />} />
    </Routes>
  )
}

export default WishListRoutes