import React from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  Container,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const footerContainerStyle = {
  padding: { xs: 4, md: 6 },
  backgroundColor: "#2E2E2E",
  color: "#ffffff",
  borderTop: "1px solid #444",
  width: "100%",
};

const contactStyle = {
  fontSize: "0.9rem",
  lineHeight: "1.7",
};

const linkStyle = {
  display: "block",
  color: "#bdbdbd",
  textDecoration: "none",
  marginBottom: "8px",
  "&:hover": {
    color: "#ffffff",
  },
};

const iconButtonStyle = {
  color: "#ffffff",
  margin: "0 8px",
  "&:hover": {
    color: "#FFB400",
  },
};

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Box sx={footerContainerStyle}>
      <Container sx={{ margin: "0px auto" }} maxWidth="lg">
        <Grid container spacing={6}>
          {/* Contact Us Section */}
          <Grid item xs={12} md={3}>
            <Typography variant="h6" fontWeight="bold" mb={2}>
              {t("footer.contactUs")}
            </Typography>
            <Box sx={contactStyle}>
              <Typography variant="body2">{t("footer.address")}</Typography>
              
              <Typography variant="body2">
                {t("footer.phone")}
                {""}:{t("footer.phone1")}
              </Typography>
              
              <Typography variant="body2">
                {t("footer.email")}
                {""}: info@asanbawar.com
              </Typography>
            </Box>
          </Grid>

          {/* Quick Links Section */}
          <Grid item xs={12} md={3}>
            <Typography variant="h6" fontWeight="bold" mb={2}>
              {t("footer.quickLink")}
            </Typography>
            <Link to="/" style={linkStyle}>
              {t("footer.home")}
            </Link>
            <Link to="/privacy-policy" style={linkStyle}>
              {t("footer.privacyPolicy")}
            </Link>
            <Link to="/about" style={linkStyle}>
              {t("footer.aboutUs")}
            </Link>
            <Link to="/contact" style={linkStyle}>
              {t("footer.contactUs")}
            </Link>
          </Grid>

          {/* Social Media Section */}
          <Grid item xs={12} md={3}>
            <Typography variant="h6" fontWeight="bold" mb={2}>
              {t("footer.followUs")}
            </Typography>
            <Box>
              <IconButton href="#" sx={iconButtonStyle}>
                <FacebookIcon />
              </IconButton>
              <IconButton href="#" sx={iconButtonStyle}>
                <TwitterIcon />
              </IconButton>
              <IconButton href="#" sx={iconButtonStyle}>
                <InstagramIcon />
              </IconButton>
              <IconButton href="#" sx={iconButtonStyle}>
                <LinkedInIcon />
              </IconButton>
            </Box>
          </Grid>

          {/* Newsletter Section */}
          <Grid item xs={12} md={3}>
            <Typography variant="h6" fontWeight="bold" mb={2}>
              {t("footer.newsLetter")}
            </Typography>
            <Typography variant="body2" mb={2}>
              {t("footer.subscribe")}
            </Typography>
            <Box component="form" noValidate autoComplete="off">
              <TextField
                label={t("footer.emailAddress")}
                variant="outlined"
                size="small"
                fullWidth
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#bdbdbd",
                    },
                    "&:hover fieldset": {
                      borderColor: "#ffffff",
                    },
                  },
                  input: { color: "#ffffff" },
                  label: { color: "#bdbdbd" },
                }}
              />
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                sx={{
                  backgroundColor: "#FFB400",
                  color: "#000",
                  "&:hover": {
                    backgroundColor: "#ffc833",
                  },
                }}
              >
                {t("footer.subscribe1")}
              </Button>
            </Box>
          </Grid>
        </Grid>

        {/* Footer Bottom */}
        <Box mt={4} textAlign="center">
          <Typography variant="body2" sx={{ color: "#bdbdbd" }}>
            &copy;{t("footer.reserved")}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
