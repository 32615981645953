import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import React from "react";

import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import useFetchObjects from "../../../api/useFetchObjects";
import useDelete from "../../../api/useDelete";
import useUpdate from "../../../api/useUpdate";
import { useAuth } from "../../../context/AuthContext";
import useAdd from "../../../api/useAdd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Categories = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState({});
  const [imagePreview, setImagePreview] = useState([]);

  const navigate = useNavigate();

  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  const [formData, setFormData] = useState({});

  const [formErrors, setFormErrors] = useState({
    name: "",
    image: "",
  });

  const { handleAdd, resetForm, loading } = useAdd("categories", token);

  const { handleUpdate } = useUpdate(
    `categories`,
    token,
    "/dashboard/categories"
  );

  useEffect(() => {
    if (resetForm) {
      setFormData({
        name: "",
        image: "",
      });
      setFormErrors({});
    }
  }, [resetForm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    console.log(formData);
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) errors[key] = t("categories.fieldRequired");
    });

    if (Object.keys(errors).length === 0) {
      const categoryData = new FormData();
      Object.keys(formData).forEach((key) =>
        categoryData.append(key, formData[key])
      );
      handleUpdate(currentCategory?.id, categoryData);
      setOpen(false);
    } else {
      setFormErrors(errors);
    }
  };

  const handleAddCategory = (e) => {
    e.preventDefault();
    let errors = {};

    Object.keys(formData).forEach((key) => {
      if (!formData[key]) errors[key] = t("categories.fieldRequired");
    });

    if (Object.keys(errors).length === 0) {
      const productData = new FormData();
      Object.keys(formData).forEach((key) => {
        if (key === "image" && formData[key].length > 0) {
          // Append each image separately
          formData[key].forEach((file) => {
            productData.append("image", file);
          });
        } else {
          productData.append(key, formData[key]);
        }
      });
      handleAdd(productData);
    } else {
      setFormErrors(errors);
    }
  };

  useEffect(() => {
    if (resetForm) {
      navigate("/dashboard/categories");
    }
  }, [resetForm]);

  const columns = [
    { field: "id", headerName: t("categories.id"), flex: 0.5 },
    {
      field: "image",
      headerName: t("categories.categoryPhoto"),
      renderCell: (params) => {
        return (
          <img
            src={params.row.image}
            style={{ width: "6rem", height: "auto", padding: "4px" }}
          />
        );
      },
    },
    {
      field: "name",
      headerName: t("categories.categoryName"),
      flex: 2,
      cellClassName: "name-column--cell",
    },
    {
      field: "actions",
      headerName: t("categories.action"),
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEdit(params.row)}
            style={{ margin: "0em 1em" }}
          >
            {t("categories.edit")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDelete(params.row.id)}
          >
            {t("categories.delete")}
          </Button>
        </Stack>
      ),
    },
  ];

  const { handleDelete, ConfirmDialog } = useDelete(`categories`, token);

  const handleEdit = (row) => {
    setOpen(true);
    setImagePreview(row?.image);
    setCurrentCategory(row);
    setFormData({
      name: row?.name || "",
    });
  };

  const [filter, setFilter] = useState("");

  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["CategoriesList"],
    `categories/?filter=${filter}`,
    token
  );

  useEffect(() => {
    refetch();
  }, [filter, refetch]);

  const handleImageChange = (event) => {
    if (event.target.files[0]) {
      const file = event.target.files[0];
      const url = URL.createObjectURL(file);

      setImagePreview(url);
      setFormData({ ...formData, image: file });
      setFormErrors({ ...formErrors, image: "" });
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: "" });
  };

  return (
    <Box m="20px">
      <Header
        title={t("categories.categories")}
        subtitle={t("categories.listCategories")}
      />
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setAddOpen(true)}
      >
        {t("categories.addCategory")}
      </Button>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {isLoading ? (
          t("categories.loading")
        ) : isError ? (
          t("categories.fieldLoading")
        ) : (
          <DataGrid
            rows={data}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            rowHeight={80}
          />
        )}
      </Box>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle
            style={{
              textAlign: "center",
              backgroundColor: "#3f51b5",
              color: "#fff",
            }}
          >
            <Typography variant="h5">{t("categories.editCategory")}</Typography>
          </DialogTitle>
          <DialogContent
            sx={{ backgroundColor: colors.primary[400], maxHeight: "65vh" }}
          >
            <br /> <br />
            <Grid spacing={3} container>
              <Grid xs={12} item>
                <TextField
                  autoFocus
                  size="small"
                  label={t("categories.categoryName")}
                  name="name"
                  value={formData.name}
                  error={!!formErrors.name}
                  helperText={formErrors.name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid xs={12} item>
                <Box sx={{ width: "90%", margin: "auto", mt: 4 }}>
                  <input
                    id="image-input"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />

                  {/* Custom button to trigger the input field */}
                  <label htmlFor="image-input">
                    <Button
                      variant="contained"
                      sx={{ textTransform: "capitalize" }}
                      component="span"
                      color="secondary"
                    >
                      {t("categories.changeImages")}
                    </Button>
                  </label>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {imagePreview && (
                      <Box
                        sx={{
                          width: "12rem",
                          height: "10rem",
                          cursor: "pointer",
                          border: "1px solid grey",
                          borderRadius: "8px",
                          overflow: "hidden",
                          marginTop: 2, // Space between boxes
                        }}
                      >
                        <img
                          src={imagePreview}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: colors.primary[400],
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => setOpen(false)}
              color="primary"
              variant="contained"
              style={{ margin: "0em 1em" }}
            >
              {t("categories.cancel")}
            </Button>
            <Button
              type="submit"
              disabled={loading}
              color="secondary"
              variant="contained"
            >
              {loading ? <CircularProgress /> : t("categories.save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* add category */}
      <Dialog
        open={addOpen}
        onClose={() => setAddOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <form onSubmit={handleAddCategory}>
          <DialogTitle
            style={{
              textAlign: "center",
              backgroundColor: "#3f51b5",
              color: "#fff",
            }}
          >
            <Typography variant="h5">{t("categories.addCategory")}</Typography>
          </DialogTitle>
          <DialogContent
            sx={{ backgroundColor: colors.primary[400], maxHeight: "65vh" }}
          >
            <br /> <br />
            <Grid spacing={3} container>
              <Grid xs={12} item>
                <TextField
                  autoFocus
                  size="small"
                  label={t("categories.categoryName")}
                  name="name"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid xs={12} item>
                <Box sx={{ width: "90%", margin: "auto", mt: 4 }}>
                  <input
                    id="image-input"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />

                  {/* Custom button to trigger the input field */}
                  <label htmlFor="image-input">
                    <Button
                      variant="contained"
                      sx={{ textTransform: "capitalize" }}
                      component="span"
                      color="secondary"
                    >
                      {t("categories.uploadImage")}
                    </Button>
                  </label>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {imagePreview && imagePreview.length > 0 && (
                      <Box
                        sx={{
                          width: "12rem",
                          height: "10rem",
                          cursor: "pointer",
                          border: "1px solid grey",
                          borderRadius: "8px",
                          overflow: "hidden",
                          marginTop: 2, // Space between boxes
                        }}
                      >
                        <img
                          src={imagePreview}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: colors.primary[400],
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => setAddOpen(false)}
              color="primary"
              variant="contained"
              style={{ margin: "0em 1em" }}
            >
              {t("categories.cancel")}
            </Button>
            <Button
              disabled={loading}
              type="submit"
              color="secondary"
              variant="contained"
            >
              {loading ? <CircularProgress /> : t("categories.save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <ConfirmDialog />
    </Box>
  );
};

export default Categories;
