import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import { DeleteOutline, AddShoppingCart } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Context from "../../../context/Context";
import { useTranslation } from "react-i18next";

const Wishlist = () => {
  const { t } = useTranslation();
  const [wishList, setWishList] = useState([]);
  const navigate = useNavigate();
  const { addToCart } = useContext(Context);

  useEffect(() => {
    const storedWishlist = JSON.parse(localStorage.getItem("wishList")) || [];
    setWishList(storedWishlist);
  }, []);

  const removeWishListItem = (id) => {
    const updatedWishlist = wishList.filter((item) => item.id !== id);
    setWishList(updatedWishlist);
    localStorage.setItem("wishList", JSON.stringify(updatedWishlist));
  };

  const handleProductClick = (product) => {
    navigate(`/product/${product.id}`);
  };

  const updateWishListItemQuantity = (id, quantity) => {
    const updatedWishlist = wishList.map((item) =>
      item.id === id ? { ...item, quantity } : item
    );
    setWishList(updatedWishlist);
    localStorage.setItem("wishList", JSON.stringify(updatedWishlist));
  };

  const handleAddToCart = (product) => {
    addToCart(product);
    removeWishListItem(product.id);
  };

  console.log(wishList);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      padding="20px"
      mt={2}
      spacing={2}
    >
      <Grid item xs={12} md={11.7} borderBottom={"4px solid lightblue"} pb={2}>
        <Typography mt={2} variant="h3" fontWeight={"bold"}>
          {t("wish.wishlist")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={10}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ bgcolor: "#dee3e0" }}>
              <TableRow>
                <TableCell align="center">{t("wish.id")}</TableCell>
                <TableCell align="center">{t("wish.product")}</TableCell>
                <TableCell align="center">{t("wish.price")}</TableCell>
                <TableCell align="center">{t("wish.wholePrice")}</TableCell>
                <TableCell align="center">
                  {t("wish.wholePriceCount")}
                </TableCell>
                <TableCell align="center">{t("wish.quantity")}</TableCell>
                <TableCell align="center">{t("wish.action")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wishList.map((item) => (
                <TableRow key={item.id} hover>
                  <TableCell align="center">{item.id}</TableCell>
                  <TableCell
                    align="center"
                    onClick={() => handleProductClick(item)}
                    style={{ cursor: "pointer" }}
                  >
                    <Box display="flex" alignItems="center">
                      <Box mr={2}>
                        <img
                          src={item.images[0].image}
                          alt={item.name}
                          style={{
                            width: "80px",
                            height: "80px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                      <Typography variant="body1" fontWeight="bold">
                        {item.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1" fontWeight="bold">
                      {item.price.toFixed(2)} {item.currency === 1 ? "؋" : "$"}
                    </Typography>
                  </TableCell>

                  <TableCell align="center">
                    <Typography variant="body1" fontWeight="bold">
                      {item.whole_price.toFixed(2)}{" "}
                      {item.currency === 1 ? "؋" : "$"}
                    </Typography>
                  </TableCell>

                  <TableCell align="center">{item.whole_price_count}</TableCell>

                  <TableCell align="center">
                    <Typography variant="body1" fontWeight="bold">
                      {item.quantity}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => removeWishListItem(item.id)}>
                      <DeleteOutline />
                    </IconButton>
                    <IconButton
                      onClick={() => handleAddToCart(item)}
                      color="primary"
                    >
                      <AddShoppingCart />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default Wishlist;
