import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Divider,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Alert,
} from "@mui/material";
import SkeletonLoading from "../../../../components/skeletonLoading/SkeletonLoading";
import { useAuth } from "../../../../context/AuthContext";
import useFetchObjects from "../../../../api/useFetchObjects";
import useAdd from "../../../../api/useAdd";
import useDelete from "../../../../api/useDelete";
import useUpdate from "../../../../api/useUpdate";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Comments = ({ product }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;
  const [newComment, setNewComment] = useState({
    user: user?.user.id,
    product: product,
    text: "",
  });
  const [editingCommentId, setEditingCommentId] = useState(null); // Track the ID of the comment being edited

  const { data, isLoading, isError, error } = useFetchObjects(
    ["comments"],
    `comments/?product=${product}`
  );
  const { handleDelete, ConfirmDialog } = useDelete(`comments`, token);
  const { handleUpdate } = useUpdate("comments", token);
  const { handleAdd, resetForm } = useAdd("comments");

  useEffect(() => {
    if (resetForm) {
      setNewComment({
        // user: user?.user.id,
        user: user,
        product: product,
        text: "",
      });
      setEditingCommentId(null); // Reset editing state when form resets
    }
  }, [resetForm, user, product]);

  const handleInputChange = (e) => {
    setNewComment({ ...newComment, text: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editingCommentId) {
      handleUpdate(editingCommentId, { text: newComment.text });
      setEditingCommentId(null);
    } else {
      if (token) {
        handleAdd(newComment);
      } else {
        navigate("/login");
      }
    }
  };

  const startEditing = (comment) => {
    setNewComment({ ...newComment, text: comment.text });
    setEditingCommentId(comment.id);
    setTimeout(() => {
      // Move the cursor to the text field
      document.getElementById("comment-input").focus();
    }, 0);
  };

  if (isLoading) {
    return (
      <Grid container mt={1} padding={"20px"} spacing={2}>
        {Array.from({ length: 6 }).map((_, index) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <SkeletonLoading />
          </Grid>
        ))}
      </Grid>
    );
  }

  if (isError) {
    return (
      <Grid container>
        <Alert severity="error" sx={{ width: "100%", padding: "10px" }}>
          {error.message}
        </Alert>
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center" alignItems="center" padding="20px">
      <Grid item xs={12} md={10}>
        <Divider>
          <Typography variant="h5" fontWeight="bold" color="primary">
            {t("product.comments")}
          </Typography>
        </Divider>
        <Box mt={2}>
          <TextField
            id="comment-input"
            label={t("product.addComments")}
            name="text"
            value={newComment.text}
            onChange={handleInputChange}
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            sx={{
              backgroundColor: "grey.200",
              borderRadius: "8px",
            }}
          />

          <Button
            variant="contained"
            color="primary"
            sx={{ ml: 2, borderRadius: "8px", margin: "1em 0em" }}
            onClick={handleSubmit}
          >
            {editingCommentId
              ? t("product.updateComments")
              : t("product.addComments")}
          </Button>
        </Box>
        <Box mt={3}>
          {data?.length === 0 ? (
            <Grid container>
              <Alert severity="info" sx={{ width: "100%", padding: "10px" }}>
            
                {t("product.noComments")}
              </Alert>
            </Grid>
          ) : (
            data?.map((comment) => (
              <Card key={comment.id} sx={{ mb: 2 }}>
                <CardHeader
                  avatar={<Avatar alt={comment.user} src={comment.user} />}
                  title={comment.user_display.username}
                  titleTypographyProps={{
                    fontWeight: "bold",
                    color: "primary",
                  }}
                  subheader={
                    <Box display="flex" alignItems="center">
                      <Typography variant="body2" ml={1} color="textSecondary">
                        {comment.date}
                      </Typography>
                    </Box>
                  }
                />
                <CardContent>
                  <Typography variant="body1">{comment.text}</Typography>
                </CardContent>
                <Box sx={{ textAlign: "end", marginBottom: "1em" }}>
                  {user?.user.id === comment.user && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleDelete(comment.id)}
                        sx={{ ml: 2, borderRadius: "8px", margin: "0em 1em" }}
                      >
                        
                        {t("product.delete")}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => startEditing(comment)} // Start editing this comment
                        sx={{ ml: 2, borderRadius: "8px", margin: "0em 1em" }}
                      >
                        {t("product.update")}
                       
                      </Button>
                    </>
                  )}
                </Box>
              </Card>
            ))
          )}
        </Box>
        <ConfirmDialog />
      </Grid>
    </Grid>
  );
};

export default Comments;
