// src/components/ChatLoading.js
import React from "react";
import { Box, Skeleton, Avatar, Paper, Divider } from "@mui/material";

const ChatLoading = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
      {/* Chat Header Skeleton */}
      <Box
        sx={{
          padding: 2,
          backgroundColor: "#1976d2",
          display: "flex",
          alignItems: "center",
          color: "#fff",
          justifyContent: "space-between",
        }}
      >
        <Box display="flex" alignItems="center">
          <Skeleton variant="circular">
            <Avatar />
          </Skeleton>
          <Skeleton
            variant="text"
            width={120}
            height={30}
            sx={{ marginLeft: 2, bgcolor: "rgba(255, 255, 255, 0.3)" }}
          />
        </Box>
        <Skeleton
          variant="text"
          width={60}
          height={20}
          sx={{ bgcolor: "rgba(255, 255, 255, 0.3)" }}
        />
      </Box>

      {/* Chat Body Skeleton */}
      <Box
        sx={{
          flexGrow: 1,
          padding: 2,
          backgroundColor: "#e3f2fd",
          display: "flex",
          flexDirection: "column-reverse",
          maxHeight: 460,
          overflow: "auto",
        }}
      >
        {[...Array(6)].map((_, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: index % 2 === 0 ? "flex-end" : "flex-start",
              padding: "10px 0",
            }}
          >
            {index % 2 === 0 ? null : (
              <Skeleton variant="circular" sx={{ marginRight: 1 }}>
                <Avatar />
              </Skeleton>
            )}
            <Paper
              sx={{
                padding: "12px 15px",
                backgroundColor:
                  index % 2 === 0
                    ? "rgba(208, 217, 216, 0.6)"
                    : "rgba(206, 242, 239, 0.6)",
                color: "#1a1f1e",
                borderRadius:
                  index % 2 === 0 ? "15px 15px 0 15px" : "15px 15px 15px 0",
                boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)",
                width: "70%",
                wordWrap: "break-word",
              }}
            >
              <Skeleton variant="text" width="100%" height={40} />
              <Skeleton
                variant="text"
                width="40%"
                height={10}
                sx={{ marginTop: "5px", textAlign: "right" }}
              />
            </Paper>
          </Box>
        ))}
      </Box>

      {/* Divider */}
      <Divider />

      {/* Chat Input Skeleton */}
      <Box
        sx={{
          padding: 2,
          display: "flex",
          alignItems: "center",
          backgroundColor: "#fff",
          borderTop: "1px solid #e0e0e0",
        }}
      >
        <Skeleton
          variant="rectangular"
          width="100%"
          height={40}
          sx={{ borderRadius: "25px", marginRight: 2 }}
        />
        <Skeleton variant="circular" width={40} height={40} />
      </Box>
    </Box>
  );
};

export default ChatLoading;
