import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const ErrorPage = ({ message, onRetry }) => {
  const {t}=useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Typography variant="h4" color="error" gutterBottom>
      {t("dashboard.somethingWentWrong")}
        
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        {message}
      </Typography>
      <Button variant="contained" color="primary" onClick={onRetry}>
        
        {t("dashboard.retry")}
      </Button>
    </Box>
  );
};

export default ErrorPage;
