import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import React from "react";
import { Avatar, Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import AddHomeIcon from "@mui/icons-material/AddHome";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import {
  AddToPhotosOutlined,
  BorderTop,
  Inventory2Outlined,
  InventoryOutlined,
  LayersOutlined,
  ReceiptLongOutlined,
} from "@mui/icons-material";

import { useAuth } from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const auth = useAuth();
  const user = auth?.user?.user;
  const token = user?.token;
  const firstLetter = user?.username.charAt(0).toUpperCase();
  const direction = localStorage.getItem("direction");
  const getColor = (letter) => {
    const colors = {
      A: "#f44336",
      B: "#e91e63",
      C: "#9c27b0",
      D: "#673ab7",
      E: "#3f51b5",
      F: "#2196f3",
      G: "#03a9f4",
      H: "#00bcd4",
      I: "#009688",
      J: "#4caf50",
      K: "#8bc34a",
      L: "#cddc39",
      M: "#ffeb3b",
      N: "#ffc107",
      O: "#ff9800",
      P: "#ff5722",
      Q: "#795548",
      R: "#9e9e9e",
      S: "#607d8b",
      T: "#000000",
      U: "#ffffff",
    };
    return colors[letter] || "#607d8b";
  };

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                {user?.is_staff ? (
                  <Typography variant="h3" color={colors.grey[100]}>
                    {t("global.admin")}
                  </Typography>
                ) : (
                  <Typography variant="h3" color={colors.grey[100]}>
                    {t("global.seller")}
                  </Typography>
                )}
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px" sx={{ borderBottom: "2ox solid white" }}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Avatar
                  sx={{
                    fontSize: "2rem",
                    width: 80,
                    height: 80,
                    backgroundColor: getColor(firstLetter),
                  }}
                  src={user?.image}
                  alt={firstLetter}
                ></Avatar>
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h3"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0", textTransform: "capitalize" }}
                >
                  {user?.first_name || user?.last_name ? (
                    <>
                      {user?.first_name} {user?.last_name}
                    </>
                  ) : (
                    <>{user?.username}</>
                  )}
                </Typography>
              </Box>
            </Box>
          )}

          {!user?.is_seller ? (
            <Box
              paddingLeft={isCollapsed ? undefined : "10%"}
              sx={{
                textAlign: direction === "rtl" ? "right" : "left",
              }}
            >
              <Item
                title={t("global.dashboard")}
                to="/dashboard"
                icon={<HomeOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title={t("global.manageUser")}
                to="/dashboard/users"
                icon={<PeopleOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title={t("global.manageProducts")}
                to="/dashboard/products"
                icon={<Inventory2Outlined />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title={t("global.addProduct")}
                to="/dashboard/seller/add_product"
                icon={<AddCircleIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title={t("global.manageOrders")}
                to="/dashboard/orders"
                icon={<ReceiptLongOutlined />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title={t("global.categories")}
                to="/dashboard/categories"
                icon={<LayersOutlined />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title={t("global.manageHomePage")}
                to="/dashboard/home"
                icon={<AddHomeIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title={t("global.payments")}
                to="/dashboard/payments"
                icon={<MonetizationOnIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title={t("global.contact")}
                to="/dashboard/contact"
                icon={<ContactPhoneIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </Box>
          ) : (
            <Box paddingLeft={isCollapsed ? undefined : "10%"}>
              <Item
                title={t("global.addProduct")}
                to="/dashboard/seller/add_product"
                icon={<AddToPhotosOutlined />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title={t("global.manageOrders")}
                to="/dashboard/seller/orders"
                icon={<ReceiptLongOutlined />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title={t("global.manageProducts")}
                to="/dashboard/seller/products"
                icon={<Inventory2Outlined />}
                selected={selected}
                setSelected={setSelected}
              />
            </Box>
          )}
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
