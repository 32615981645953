// src/utils/DateFormatter.js
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime); // Extend dayjs to support relative time

/**
 * Function to format and calculate date-time based on an ISO date or separate date and time.
 * @param {string} isoDate - An ISO date string.
 * @param {string} passedData - A date string in the format "YYYY-MM-DD".
 * @param {string} passedTime - A time string in the format "HH:mm:ss.SSSSSS".
 * @returns {string} - A formatted string based on the date's age.
 */
const DateFormatter = (isoDate, passedData = null, passedTime = null) => {
  let date;

  if (isoDate) {
    date = dayjs(isoDate);
  } else if (passedData && passedTime) {
    date = dayjs(`${passedData}T${passedTime}`);
  } else if (passedData) {
    date = dayjs(passedData);
  } else {
    throw new Error("No valid date input provided");
  }

  const now = dayjs();

  // Check if the date is within the last 7 days
  if (now.diff(date, "day") < 7) {
    // If it's less than 7 days ago, return "time ago" format
    return date.fromNow();
  } else {
    // Otherwise, return the full date and time format
    return date.format("D MMM YYYY, h:mm A");
  }
};

export default DateFormatter;
