import React from 'react'
import {Route, Routes} from "react-router-dom"
import FAQ from '../../web/pages/faq/FAQ'

const FaqsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<FAQ />} />
    </Routes>
  )
}

export default FaqsRoutes