import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const InboxError = ({ message, onRetry }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        padding: 2,
      }}
    >
      <Typography variant="h6" color="error">
        {t("chat.oops")}
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        {message || t("chat.unable")}
      </Typography>
      <Button variant="contained" onClick={onRetry}>
        {t("chat.retry")}
      </Button>
    </Box>
  );
};

export default InboxError;
