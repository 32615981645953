import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import useFetchObjects from "../../../api/useFetchObjects";
import ErrorPage from "../../../components/errorPage/ErrorPage";
import { useAuth } from "../../../context/AuthContext";
import useDelete from "../../../api/useDelete";
import Header from "../../components/Header";
import { SearchOutlined } from "@mui/icons-material";
import useAdd from "../../../api/useAdd";
import useUpdate from "../../../api/useUpdate";

import formatDateTime from "../global/FormatDateTime";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [search, setSearch] = useState("");
  const auth = useAuth();
  const token = auth?.user?.token;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null); // Store the message to be displayed

  const { data, isLoading, isError } = useFetchObjects(
    ["contact"],
    "contact/",
    token
  );

  const { handleDelete, ConfirmDialog } = useDelete("contact", token);

  // Transform the data to match the expected DataGrid format
  const rows =
    data?.map((item) => ({
      id: item?.id,
      name: item?.name,
      email: item?.email,
      text: item?.text,
      date: item?.date,
    })) || [];

  const columns = [
    { field: "id", headerName: t("dash_contact.id"), flex: 0.5 },
    {
      field: "name",
      headerName: t("dash_contact.name"),
      flex: 1,
    },
    {
      field: "email",
      headerName: t("dash_contact.email"),
      flex: 1,
      renderCell: (params) => <div>{params.row.email}</div>,
    },
    {
      field: "text",
      headerName: t("dash_contact.message"),
      flex: 1,
      renderCell: (params) => (
        <Grid container >
          <Button
            size="small"
            sx={{ textTransform: "capitalize", color: "white",bgcolor:"gray"}}

            onClick={() => {
              setSelectedMessage(params.row.text); // Set selected message for dialog
              setDialogOpen(true); // Open dialog to show full text
            }}
          >
            {t("dash_contact.showMore")}
          </Button>
        </Grid>
      ),
    },
    {
      field: "date",
      headerName: t("dash_contact.date"),
      flex: 1,
      renderCell: (params) => <div>{formatDateTime(params.row.date)}</div>,
    },
    {
      field: "actions",
      headerName: t("dash_contact.actions"),
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              handleDelete(params.id);
            }}
          >
            {t("dash_contact.delete")}
          </Button>
        </Stack>
      ),
    },
  ];

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <Box m={"10px"}>
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item xs={6}>
          <Header
            title={t("dash_contact.contact")}
            subtitle={t("dash_contact.contactList")}
          />
        </Grid>
      </Grid>

      <Box
        m="40px 0 0 0"
        mt={0}
        height="72vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          rowHeight={60}
          components={{ Toolbar: GridToolbar }}
          loading={isLoading}
          error={isError}
        />
        <ConfirmDialog />
      </Box>

      {/* Dialog to show full message */}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{t("dash_contact.fullMessage")}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{selectedMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            {t("dash_contact.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Contact;
