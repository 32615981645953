import React, { Suspense, lazy } from "react";
import { Box, Grid, Typography } from "@mui/material";
const Hero = lazy(() => import("./hero/Hero"));
const Categories = lazy(() => import("./categories/Categories"));
const HotDealToday = lazy(() => import("./hotDealToday/HotDealToday"));
const FeaturedProducts = lazy(() =>
  import("./featuredProducts/FeaturedProducts")
);
const SpecialOffer = lazy(() => import("./specialOffer/SpecialOffer"));
const FeaturedBarands = lazy(() => import("./featuredBrands/FeaturedBarands"));
const TopRatedProducts = lazy(() =>
  import("./TopRatedProducts/TopRatedProducts")
);
const PopularReviews = lazy(() => import("./popularReviews/PopularReviews"));

const HomeIndex = () => {
  return (
  <Grid container justifyContent={"center"}>
      <Grid container>
        <Suspense>
          <Hero />
        </Suspense>
      </Grid>
      <Grid item xs={11.5}>
        <Suspense>
          <Categories />
        </Suspense>

        <Suspense>
          <HotDealToday />
        </Suspense>

        <Suspense>
          <FeaturedProducts />
        </Suspense>

        <Suspense>
          <SpecialOffer />
        </Suspense>

        <Suspense>
          <FeaturedBarands />
        </Suspense>

        <Suspense>
          <TopRatedProducts />
        </Suspense>

        <Suspense>
          <PopularReviews />
        </Suspense>
      </Grid>
    </Grid>
  );
};

export default HomeIndex;
