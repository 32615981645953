// Move all imports to the top of the file
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  MenuItem,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  InputBase,
  Autocomplete,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import useFetchObjects from "../../../api/useFetchObjects";
import useDelete from "../../../api/useDelete";
import useUpdate from "../../../api/useUpdate";
import ErrorPage from "../../../components/errorPage/ErrorPage";
import {
  Add,
  ArrowBack,
  ArrowForward,
  SearchOutlined,
  TextFieldsOutlined,
} from "@mui/icons-material";
import { useAuth } from "../../../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import useAdd from "../../../api/useAdd";
import { useTranslation } from "react-i18next";

const Products = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState({});
  const [imagePreview, setImagePreview] = useState([]);
  const [filter, setFilter] = useState("");
  const [offerOpen, setOfferOpen] = useState(false);
  const [value, setValue] = useState();
  const auth = useAuth();
  const user = auth?.user;
  const token = auth?.user?.token;
  const navigate = useNavigate();
  const [product, setProduct] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = React.useState(4);
  const [heroImage, setHeroImage] = useState(null);
  const [heroImagePreview, setHeroImagePreview] = useState(null);
  const [imageError, setImageError] = useState(null);
  const [searchType, setSearchType] = useState("search");
  const [category, setCategory] = useState("");
  const location = useLocation();
  const [userId, setUserId] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [search, setSearch] = useState("");
  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["ProductsList", page],
    `products/?${searchType}=${search}&page=${
      page + 1
    }&page_size=${pageSize}&category=${category}&user=${userId}`,
    token
  );

  const {
    data: users,
    isLoading: isLoadingUser,
    isError: isErrorUser,
    refetch: refetchUsers,
  } = useFetchObjects(["UsersList"], `admin/users/`, token);

  const [formData, setFormData] = useState({
    user: product?.user || "",
    name: product?.name || "",
    category: product?.category || "",
    price: product?.price || "",
    whole_price: product?.whole_price || "",
    whole_price_count: product?.whole_price_count || "",
    currency: product?.currency || "",
    quantity: product?.quantity || "",
    description: product?.description || "",
    discount: product?.discount || "",
    color: product?.colors || [],
    size: product?.size || "",
    uploaded_images: product?.uploaded_images || [],
    location: product?.location || "",
    is_visible_seller_info: product?.is_visible_seller_info || "",
  });

  const [formErrors, setFormErrors] = useState({
    user: "",
    name: "",
    category: "",
    price: "",
    currency: "",
    quantity: "",
    description: "",
    discount: "",
    color: [],
    size: "",
    uploaded_images: [],

    location: "",
    is_visible_seller_info: "",
  });

  const { handleUpdate, Buttons, loading } = useUpdate(
    `products`,
    token,
    "/dashboard/products"
  );

  const {
    handleAdd: handleAddFeaturedProduct,
    loading: featuredProductLoading,
  } = useAdd("featured-products", token);

  const { handleAdd: handleAddFeatureBrand, loading: featuredBrandLoading } =
    useAdd("featured-brands", token);

  const { handleAdd: handleAddSpecialOffer, loading: specialOfferLoading } =
    useAdd("special-offer", token);

  const {
    handleAdd: handleAddHero,
    resetForm,
    loading: heroLoading,
  } = useAdd("hero", token);

  useEffect(() => {
    if (location.state) {
      setUserId(location?.state?.userInfo?.id);
      setUserInfo(location?.state?.userInfo);
    } else {
      setUserId("");
    }
  }, [location]);

  useEffect(() => {
    if (resetForm) {
      setImageError(null);
      setHeroImage("");
      setCurrentProduct("");
    }
  }, [resetForm]);

  useEffect(() => {
    refetch();
  }, [filter, refetch, search, page, pageSize, searchType, category, userId]);

  const [homePageData, setHomePageData] = useState({
    product: "",
    time: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};

    // Validate required fields
    if (!formData.name) errors.name = t("manage_product.nameRequired");
    if (!formData.category)
      errors.category = t("manage_product.categoryRequired");
    if (!formData.currency) errors.currency = "manage_product.currencyRequired";
    if (!formData.description)
      errors.description = t("manage_product.descriptionRequired");
    if (!formData.price) errors.price = t("manage_product.priceRequired");
    if (!formData.quantity)
      errors.quantity = t("manage_product.quantityRequired");
    if (!formData.location)
      errors.location = t("manage_product.locationRequired");

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const productData = new FormData();

    // Append all form fields to FormData
    Object.keys(formData).forEach((key) => {
      if (key === "uploaded_images" && formData[key].length > 0) {
        formData[key].forEach((file) => {
          productData.append("uploaded_images", file);
        });
      } else if (key === "color" && formData[key].length > 0) {
        // Append only the color name instead of the entire color object
        formData[key].forEach((color) => {
          productData.append("color", color.name); // Send just the name
        });
      } else {
        productData.append(key, formData[key]);
      }
    });

    handleUpdate(product?.id, productData);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: "" });
  };

  useEffect(() => {
    if (loading) {
      setOpen(false);
    }
  }, [loading]);

  const columns = [
    { field: "id", headerName: t("manage_product.id"), flex: 0.5 },
    {
      field: "images",
      headerName: t("manage_product.photo"),
      align: "center",
      flex: 1.2,
      renderCell: (params) => {
        return (
          <img
            src={params.value[0]?.image}
            style={{ width: "5rem", height: "auto", padding: "4px" }}
          />
        );
      },
    },
    {
      field: "name",
      headerName: t("manage_product.productName"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "category",
      headerName: t("manage_product.category"),
      headerAlign: "left",
      renderCell: (params) => <div>{params?.row?.display_category}</div>,
      align: "left",
    },
    {
      field: "price",
      headerName: t("manage_product.price"),
      renderCell: (params) => (
        <div>
          {params?.row?.currency === 1
            ? `${params?.value} ؋`
            : `${params?.value} $`}
        </div>
      ),
      flex: 1,
    },

    {
      field: "quantity",
      headerName: t("manage_product.quantity"),
      flex: 1,
    },
    {
      field: "created_at",
      headerName: t("manage_product.created"),
      flex: 1,
      renderCell: (params) => (
        <div>
          <span>{params.row.created_at.split("T")[0]}</span>
        </div>
      ),
    },
    {
      field: "discount",
      headerName: t("manage_product.discount"),
      renderCell: (params) => (
        <div>
          <span>{params.value}</span>
          <span>%</span>
        </div>
      ),
      flex: 1,
    },
    {
      field: "actions",
      headerName: t("manage_product.actions"),
      flex: 2,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setProduct(params.row);
              handleEdit(params.row);
            }}
          >
            {loading ? <CircularProgress /> : t("manage_product.edit")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDelete(params.row.id)}
            style={{ margin: "0em 1em" }}
          >
            {t("manage_product.delete")}
          </Button>
          <IconButton onClick={() => handleAddToHomePage(params.row.id)}>
            <Add />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const {
    data: categories,
    isLoading: isLoadingCategories,
    isError: isErrorCategories,
    refetch: refetchCategories,
  } = useFetchObjects(["CategoriesList"], `categories/`, token);

  const { handleDelete, ConfirmDialog } = useDelete(`products`, token);
  const { handleDelete: deleteImage, ConfirmDialog: ConfirmDialogImage } =
    useDelete(`images`, token);

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleEdit = (row) => {
    setOpen(true);
    setCurrentProduct(row);

    // Map existing product images to objects with url and id
    const existingImages = row.images
      ? row.images.map((img) => ({ url: img.image, id: img.id }))
      : [];

    setFormData({
      name: row?.name || "",
      category: row?.category || "",
      price: row?.price || "",
      whole_price: row?.whole_price || "",
      whole_price_count: row?.whole_price_count || "",
      currency: row?.currency || "",
      quantity: row?.quantity || "",
      description: row?.description || "",
      discount: row?.discount || "",
      color: row?.colors || [],
      location: row?.location || "",
      is_visible_seller_info: row?.is_visible_seller_info || "",
      size: row?.size || "",
      user: row?.user || "",
    });
    setImagePreview(existingImages); // Show existing images in preview
  };

  const handleImageChange = (event) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      const newImageUrls = files.map((file) => ({
        url: URL.createObjectURL(file),
        id: null, // Newly uploaded images won't have an ID yet.
      }));

      // Combine the new images with the existing image previews
      setImagePreview((prevPreview) => [
        ...(prevPreview || []),
        ...newImageUrls,
      ]);

      // Update form data with the actual files
      setFormData((prevData) => ({
        ...prevData,
        uploaded_images: [...(prevData.uploaded_images || []), ...files],
      }));

      // Clear any error related to image uploads
      setFormErrors((prevErrors) => ({ ...prevErrors, uploaded_images: "" }));
    }
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < imagePreview.length - 3 ? prevIndex + 1 : 0
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : imagePreview.length - 3
    );
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleAddToHomePage = (productId) => {
    setOfferOpen(true);
    setCurrentProduct(productId);
    setHomePageData({
      product: productId,
      time: formData?.time,
    });
  };

  const handleChangeOfferTime = (e) => {
    setHomePageData({ ...homePageData, time: e.target.value });
  };

  const handleDeleteImage = (index, imageId) => {
    if (imageId) {
      // Call the API or use the `deleteImage` function to remove the image from the database.
      deleteImage(imageId);
    }

    // Remove the image from the local preview.
    setImagePreview((prevPreview) =>
      (prevPreview || []).filter((_, i) => i !== index)
    );

    // Update the form data to remove the corresponding file from the uploaded images.
    setFormData((prevData) => {
      const updatedUploadedImages = (prevData.uploaded_images || []).filter(
        (_, i) => i !== index
      );
      return { ...prevData, uploaded_images: updatedUploadedImages };
    });
  };

  const handleSubmitHeroData = () => {
    if (!heroImage) {
      setImageError(t("manage_product.imageRequired"));
      return;
    }
    if (!currentProduct) {
      setImageError(t("manage_product.productRequired"));
      return;
    }

    const submitData = new FormData();

    submitData.append("product", currentProduct);
    submitData.append("image", heroImage);

    handleAddHero(submitData);
  };

  if (isError || isErrorCategories || isErrorUser) {
    return <ErrorPage />;
  }
  const options = [
    t("product.red"),
    t("product.blue"),
    t("product.green"),
    t("product.yellow"),
    t("product.black"),
    t("product.white"),
    t("product.orange"),
    t("product.purple"),
    t("product.pink"),
    t("product.brown"),
    t("product.gray"),
    t("product.violet"),
    t("product.cyan"),
    t("product.magenta"),
    t("product.turquoise"),
    t("product.lavender"),
    t("product.maroon"),
    t("product.navy"),
    t("product.olive"),
    t("product.teal"),
    t("product.gold"),
    t("product.silver"),
    t("product.beige"),
    t("product.coral"),
    t("product.mint"),
    t("product.peach"),
    t("product.other"),
  ];

  return (
    <Box m="20px" mt="0">
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item xs={6} display={"flex"} alignItems={"center"}>
          <Header
            title={t("manage_product.product1")}
            subtitle={
              userInfo
                ? `${t("manage_product.product")} ${userInfo?.username}`
                : t("manage_product.listAllProduct")
            }
          />
          {userInfo && (
            <Button
              variant="outlined"
              color="secondary"
              sx={{ textTransform: "capitalize", mt: 2, mr: 1 }}
              onClick={() => {
                setUserId("");
                setUserInfo("");
              }}
            >
              {t("manage_product.seeAllProduct")}
            </Button>
          )}
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            spacing={2}
            justifyContent={"center"}
            alignItems={"cneter"}
          >
            <Grid item xs={3}>
              <TextField
                fullWidth
                name="searchType"
                onChange={(e) => {
                  setSearchType(e.target.value);
                }}
                size="small"
                label={t("manage_product.searchType")}
                select
              >
                <MenuItem value={"id"}>{t("manage_product.id")}</MenuItem>
                <MenuItem value={"search"}>{t("manage_product.name")}</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={9}>
              <Box
                display="flex"
                backgroundColor={colors.primary[400]}
                borderRadius="3px"
                gap={1}
              >
                <InputBase
                  onChange={handleSearchChange}
                  sx={{ m: "0em 1em", flex: 1 }}
                  placeholder={t("manage_product.search")}
                />
                <IconButton type="button" sx={{ p: 1 }}>
                  <SearchOutlined />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label={t("manage_product.filterByCategory")}
              name="category"
              size="small"
              select
              onChange={(e) => {
                setCategory(e.target.value);
              }}
              style={{
                backgroundColor:
                  theme.palette.mode === "dark" ? "#272a40" : "#f2f3f5", // Dark mode: #424242, Light mode: #f5f5f5
                borderRadius: "4px", // Optional: Add border-radius for rounded corners
              }}
              InputLabelProps={{
                style: {
                  color: theme.palette.mode === "dark" ? "white" : "gray", // Change label text color based on theme
                },
              }}
            >
              {categories?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>

      <Box
        m="40px 0 0 0"
        mt={0}
        height="72vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {isLoading ? (
          t("manage_product.loading")
        ) : (
          <DataGrid
            rows={data?.results && data?.results}
            columns={columns}
            rowHeight={80}
            components={{ Toolbar: GridToolbar }}
            pagination
            pageSize={pageSize}
            rowCount={data?.count}
            page={page}
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            loading={loading}
          />
        )}
      </Box>
      {/* Edit dialog */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <DialogTitle
            style={{
              textAlign: "center",
              backgroundColor: "#3f51b5",
              color: "#fff",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5">
              {t("manage_product.editProduct")}
            </Typography>
            <Button
              onClick={() => setOpen(false)}
              color="primary"
              variant="contained"
              sx={{ textTransform: "capitalize" }}
            >
              {t("manage_product.close")}
            </Button>
          </DialogTitle>
          <DialogContent
            sx={{ backgroundColor: colors.primary[400], maxHeight: "65vh" }}
          >
            <br /> <br />
            <Grid spacing={3} container>
              <Grid xs={12} item>
                <TextField
                  fullWidth
                  autoFocus
                  size="small"
                  label={t("manage_product.productName")}
                  name="name"
                  value={formData.name}
                  error={!!formErrors.name}
                  helperText={formErrors.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid xs={12} item>
                <TextField
                  fullWidth
                  autoFocus
                  size="small"
                  label={t("manage_product.description")}
                  name="description"
                  value={formData.description}
                  error={!!formErrors.description}
                  helperText={formErrors.description}
                  onChange={handleChange}
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid md={6} sm={6} xs={12} item>
                <TextField
                  select
                  fullWidth
                  size="small"
                  label={t("manage_product.category")}
                  name="category"
                  value={formData.category}
                  error={!!formErrors.category}
                  helperText={formErrors.category}
                  onChange={handleChange}
                >
                  {categories &&
                    categories.map((category) => (
                      <MenuItem value={category.id}>{category.name}</MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid xs={12} md={6} sm={6} item>
                <TextField
                  select
                  fullWidth
                  autoFocus
                  size="small"
                  label={t("manage_product.sellerName")}
                  name="user"
                  value={formData.user}
                  error={!!formErrors.user}
                  helperText={formErrors.user}
                  onChange={handleChange}
                >
                  {users &&
                    users.map((user) => (
                      <MenuItem value={user.id}>{user.first_name}</MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid md={6} sm={6} xs={12} item>
                <TextField
                  fullWidth
                  size="small"
                  label={t("manage_product.price")}
                  name="price"
                  type="number"
                  value={formData.price}
                  error={!!formErrors.price}
                  helperText={formErrors.price}
                  onChange={handleChange}
                />
              </Grid>
              <Grid xs={12} sm={6} item>
                <TextField
                  fullWidth
                  type="number"
                  label={t("manage_product.wholePrice")}
                  name="whole_price"
                  value={formData.whole_price}
                  error={!!formErrors.whole_price}
                  helperText={formErrors.whole_price}
                  onChange={handleChange}
                />
              </Grid>

              <Grid xs={12} sm={6} item>
                <TextField
                  fullWidth
                  type="number"
                  label={t("manage_product.wholePriceCount")}
                  name="whole_price_count"
                  value={formData.whole_price_count}
                  error={!!formErrors.whole_price_count}
                  helperText={formErrors.whole_price_count}
                  onChange={handleChange}
                />
              </Grid>
              <Grid md={6} sm={6} xs={12} item>
                <TextField
                  select
                  fullWidth
                  size="small"
                  label={t("manage_product.currency")}
                  name="currency"
                  value={formData.currency}
                  error={!!formErrors.currency}
                  helperText={formErrors.currency}
                  onChange={handleChange}
                >
                  <MenuItem value={1}>{t("manage_product.afg")}</MenuItem>
                  <MenuItem value={2}>{t("manage_product.usd")}</MenuItem>
                </TextField>
              </Grid>
              <Grid md={6} sm={6} xs={12} item>
                <TextField
                  fullWidth
                  size="small"
                  label={t("manage_product.quantity")}
                  name="quantity"
                  type="number"
                  value={formData.quantity}
                  error={!!formErrors.quantity}
                  helperText={formErrors.quantity}
                  onChange={handleChange}
                />
              </Grid>
              <Grid md={6} sm={6} xs={12} item>
                <TextField
                  fullWidth
                  size="small"
                  label={t("manage_product.discount")}
                  name="discount"
                  value={formData.discount}
                  error={!!formErrors.discount}
                  helperText={formErrors.discount}
                  onChange={handleChange}
                  InputProps={{
                    min: 0,
                    max: 100,
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid md={6} sm={6} xs={12} item>
                <TextField
                  select
                  fullWidth
                  size="small"
                  label={t("manage_product.size")}
                  name="size"
                  value={formData.size}
                  error={!!formErrors.size}
                  helperText={formErrors.size}
                  onChange={handleChange}
                >
                  <MenuItem value="XS"> {t("product.xs")}</MenuItem>
                  <MenuItem value="S"> {t("product.s")}</MenuItem>
                  <MenuItem value="M"> {t("product.m")}</MenuItem>
                  <MenuItem value="L"> {t("product.l")}</MenuItem>
                  <MenuItem value="XL"> {t("product.xl")}</MenuItem>
                  <MenuItem value="XXL">{t("product.xxl")}</MenuItem>
                </TextField>
              </Grid>
              <Grid md={6} sm={6} xs={12} item>
                <Autocomplete
                  size="small"
                  multiple
                  options={options}
                  value={formData?.color?.map((color) => color.name)} // Display the names of selected colors
                  onChange={(event, newValue) => {
                    // Map the selected names back to the color objects
                    const updatedColors = newValue.map((name) => {
                      // Find the existing object with the same name or create a new one.
                      const existingColor = formData?.color?.find(
                        (color) => color.name === name
                      );
                      return existingColor || { id: null, name };
                    });
                    setFormData({ ...formData, color: updatedColors });
                  }}
                  getOptionLabel={(option) => option} // Options are strings, so return them directly.
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t("manage_product.selectColor")}
                      placeholder={t("manage_product.chooseColor")}
                    />
                  )}
                />
              </Grid>

              <Grid sm={6} xs={12} item>
                <TextField
                  select
                  fullWidth
                  type="text"
                  size="small"
                  label={t("manage_product.location")}
                  name="location"
                  value={formData.location}
                  error={!!formErrors.location}
                  helperText={formErrors.location}
                  onChange={handleChange}
                >
                  <MenuItem value={1}>
                    {t("manage_product.asanbawarStock")}
                  </MenuItem>
                  <MenuItem value={2}>
                    {t("manage_product.sellerShop")}
                  </MenuItem>
                </TextField>
              </Grid>

              <Grid sm={6} xs={12} item>
                <FormControl>
                  <FormLabel>{t("manage_product.doYouWant")}</FormLabel>
                  <RadioGroup
                    name="is_visible_seller_info"
                    value={String(formData.is_visible_seller_info)}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        is_visible_seller_info: e.target.value, // Convert string to boolean
                      });
                    }}
                    row
                  >
                    <FormControlLabel
                      value="true" // Use string values "true" and "false"
                      label={t("manage_product.yes")}
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value="false" // Use string values "true" and "false"
                      label={t("manage_product.no")}
                      control={<Radio />}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid>
              <Box sx={{ width: "90%", margin: "auto", mt: 4 }}>
                <input
                  id="image-input"
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />

                {/* Custom button to trigger the input field */}
                <Stack alignItems="center">
                  <label htmlFor="image-input">
                    <Button
                      variant="contained"
                      sx={{ textTransform: "capitalize" }}
                      component="span"
                      color="secondary"
                    >
                      {t("manage_product.uploadImages")}
                    </Button>
                  </label>
                </Stack>
                <br />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    onClick={prevSlide}
                    disabled={imagePreview.length <= 3}
                  >
                    <ArrowBack />
                  </IconButton>
                  <Box
                    sx={{
                      display: "flex",
                      overflow: "hidden",
                      width: "36rem", // Width to accommodate 3 images
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        transition: "transform 0.5s ease-in-out",
                        transform: `translateX(-${currentIndex * 12}rem)`, // Shift by one image (12rem) at a time
                      }}
                    >
                      {imagePreview.map((image, index) => (
                        <Box
                          key={index}
                          sx={{
                            width: "12rem",
                            height: "10rem",
                            marginRight: "0.5rem",
                            position: "relative",
                            border: "1px solid grey",
                            borderRadius: "8px",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={image.url} // Change this based on your image object structure.
                            alt={`product-image-${index}`}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                          <Box
                            sx={{
                              position: "absolute",
                              top: "0%",
                              right: "0%",
                            }}
                          >
                            <CancelIcon
                              fontSize="large"
                              sx={{
                                color: "red",
                                ":hover": { fontSize: "2rem" },
                              }}
                              onClick={() => handleDeleteImage(index, image.id)}
                            />
                          </Box>
                          <ConfirmDialogImage />
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  <IconButton
                    onClick={nextSlide}
                    disabled={imagePreview.length <= 3}
                  >
                    <ArrowForward />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: colors.primary[400],
              justifyContent: "center",
            }}
          >
            <Buttons onSubmit={handleSubmit} />
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={offerOpen}
        onClose={() => {
          setOfferOpen(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <form encType="multipart/form-data">
          <DialogTitle
            style={{
              textAlign: "center",
              backgroundColor: "#3f51b5",
              color: "#fff",
            }}
          >
            <Typography variant="h5">
              {t("manage_product.specialOffer")}
            </Typography>
          </DialogTitle>
          <DialogContent
            sx={{ backgroundColor: colors.primary[400], maxHeight: "65vh" }}
          >
            <br /> <br />
            <Grid spacing={3} container justifyContent={"center"}>
              {value === 4 ? (
                <>
                  <Stack alignItems="center">
                    <input
                      id="image-input"
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={(e) => {
                        setHeroImagePreview(
                          URL.createObjectURL(e.target.files[0])
                        );
                        setHeroImage(e.target.files[0]);
                      }}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="image-input">
                      <Button
                        variant="contained"
                        sx={{ textTransform: "capitalize" }}
                        component="span"
                        color="secondary"
                      >
                        {t("manage_product.uploadHeroImage")}
                      </Button>
                    </label>
                    {heroImage && (
                      <>
                        <Box
                          component={"img"}
                          src={heroImagePreview}
                          sx={{
                            width: "10vw",
                            height: "14vh",
                            borderRadius: "10px",
                            mt: 2,
                          }}
                        />

                        <Button
                          variant="contained"
                          sx={{ textTransform: "capitalize", mt: 2 }}
                          component="span"
                          color="secondary"
                          onClick={handleSubmitHeroData}
                        >
                          {t("manage_product.submitHeroData")}
                        </Button>
                      </>
                    )}
                  </Stack>
                </>
              ) : (
                <Grid xs={12} md={6} sm={6} item>
                  <TextField
                    fullWidth
                    autoFocus
                    size="small"
                    label={t("manage_product.dateTime")}
                    name="time"
                    type="datetime-local"
                    value={homePageData.time}
                    onChange={handleChangeOfferTime}
                    InputLabelProps={{
                      shrink: true, // Ensures the label stays shrunk when there is a value
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: colors.primary[400],
              justifyContent: "center",
            }}
          >
            <Grid container justifyContent={"space-between"} mt={2}>
              <Button
                onClick={() => setOfferOpen(false)}
                color="primary"
                variant="contained"
              >
                {t("manage_product.cancel")}
              </Button>
              <Button
                variant="contained"
                color={value === 1 ? "secondary" : "primary"}
                disabled={value === 1}
                onClick={() => {
                  setValue(1);
                  handleAddSpecialOffer(homePageData);
                }}
                sx={{ textTransform: "capitalize" }}
              >
                {t("manage_product.specialOffer1")}
              </Button>

              <Button
                variant="contained"
                color={value === 2 ? "secondary" : "primary"}
                disabled={value === 2}
                onClick={() => {
                  setValue(2);
                  handleAddFeatureBrand(homePageData);
                }}
                sx={{ textTransform: "capitalize" }}
              >
                {t("manage_product.featuredBrands")}
              </Button>

              <Button
                variant="contained"
                color={value === 3 ? "secondary" : "primary"}
                onClick={() => {
                  setValue(3);
                  handleAddFeaturedProduct(homePageData);
                }}
                sx={{ textTransform: "capitalize" }}
              >
                {t("manage_product.featuredProducts")}
              </Button>

              <Button
                variant="contained"
                color={value === 4 ? "secondary" : "primary"}
                onClick={() => {
                  setValue(4);
                }}
                sx={{ textTransform: "capitalize" }}
              >
                {t("manage_product.hero")}
              </Button>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
      <ConfirmDialog />
    </Box>
  );
};

export default Products;
