import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../../web/components/user/Login";
import Register from "../../web/components/user/Register";
import OTPVerify from "../../web/components/user/OTPVerify";
import EmailVerify from "../../web/components/user/EmailVerify";
import PrivacyPolicy from "../../web/components/user/PrivacyPolicy";
import ResetPassword from "../../web/components/user/ResetPassword";
import ConfirmResetPassword from "../../web/components/user/ConfirmResetPassword";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/otp-verify" element={<OTPVerify />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="/reset-password-confirm"
        element={<ConfirmResetPassword />}
      />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/email-verify" element={<EmailVerify />} />
    </Routes>
  );
};

export default AuthRoutes;
