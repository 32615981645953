import React, { useState } from "react";
import { Grid } from "@mui/material";
import Inbox from "./Inbox"; // Import Inbox
import { useAuth } from "../../../context/AuthContext";
import { Outlet, useLocation } from "react-router-dom";

const ChatIndex = () => {
  const location = useLocation();
  const pathname = location?.pathname;
  const [isMobile, setIsMobile] = useState(null);
  return (
    <Grid container>
      <Grid
        container
        sx={{
          backgroundColor: "#f0f4f8",
          width: "100%",
          height: "90vh",
          display: { xs: "none", md: "flex" },
        }}
      >
        {/* Inbox Section */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            borderRight: { md: "1px solid #e0e0e0" },
            backgroundColor: "#fff",
            overflowY: "auto",
          }}
        >
          <Inbox />
        </Grid>

        {/* Chat Section */}
        <Grid
          item
          xs={12}
          md={8}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Outlet />
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          backgroundColor: "#f0f4f8",
          width: "100%",
          height: "63vh",
          display: { xs: "block", md: "none" },
        }}
      >
        {pathname === "/chat" ? (
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              borderRight: { md: "1px solid #e0e0e0" },
              backgroundColor: "#fff",
              overflowY: "auto",
            }}
          >
            <Inbox />
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              display: { xs: "flex", md: "none" },
              flexDirection: "column",
            }}
          >
            <Outlet />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ChatIndex;
