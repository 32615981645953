import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton,
  Paper,
  Stack,
  FormLabel,
  InputAdornment,
} from "@mui/material";
import { toast } from "react-toastify";
import MessageIcon from "@mui/icons-material/Message";
import { DeleteOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Context from "../../../../context/Context";
import useAdd from "../../../../api/useAdd";
import { useAuth } from "../../../../context/AuthContext";
import { useTranslation } from "react-i18next";

const ShoppingCart = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { updateCartItemQuantity, removeCartItem } = useContext(Context);
  const [cartItems, setCartItems] = useState([]);

  const afghanPhoneRegex = /^(\+93|0)?(7[0-9]{8})$/;

  const [validationMessages, setValidationMessages] = useState({
    phone: "",
    address: "",
    quantity: {},
  });

  const [quantityErrorMessage, setQuantityErrorMessage] = useState("");

  const [totalDollarsAmount, setTotalDollarsAmount] = useState(0);
  const [totalAfghanisAmount, setTotalAfghanisAmount] = useState(0);

  const [formData, setFormData] = useState({
    user: user?.user.id,
    address: "",
    contact: "",
    description: "",
    orderItems: [],
  });

  const { handleAdd, resetForm } = useAdd("orders", token);

  useEffect(() => {
    if (resetForm) {
      setFormData({
        user: user?.user.id,
        address: "",
        contact: "",
        orderItems: [],
      });
      // Clear cart items immediately after successful submission
      cartItems?.forEach((item) => handleRemoveCartItem(item.id));

      navigate("/products");
    }
  }, [resetForm]);

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    setCartItems(storedCartItems);
  }, []);

  useEffect(() => {
    let dollarTotal = 0;
    let afghaniTotal = 0;

    const orderItems = cartItems.map((item) => {
      const price =
        item.userQuantity < item.whole_price_count
          ? item.price
          : item.whole_price;

      if (item.currency === 1) {
        afghaniTotal += item.userQuantity * price;
      } else {
        dollarTotal += item.userQuantity * price;
      }

      return {
        product: item.id,
        quantity: item.userQuantity,
        price,
        user: item.user,
      };
    });

    setTotalDollarsAmount(dollarTotal);
    setTotalAfghanisAmount(afghaniTotal);

    setFormData((prevData) => ({
      ...prevData,
      order_items: orderItems,
    }));
  }, [cartItems]);

  const handleUpdateCartItemQuantity = (itemId, newQuantity) => {
    const item = cartItems.find((i) => i.id === itemId);

    if (newQuantity >= item.whole_price_count) {
      setQuantityErrorMessage(
        `${t("product.greatNews")} ${item?.whole_price_count} ${t(
          "product.unitOf"
        )} ${item?.name}${t("product.qualify")}`
      );
    }

    if (newQuantity > item.quantity) {
      setValidationMessages((prev) => ({
        ...prev,
        quantity: {
          [itemId]: `${item.name} ${t("product.notEnough")}`,
        },
      }));
    } else {
      setValidationMessages((prev) => ({
        ...prev,
        quantity: { ...prev.quantity, [itemId]: "" },
      }));
    }

    const updatedCartItems = cartItems.map((item) =>
      item.id === itemId ? { ...item, userQuantity: newQuantity } : item
    );
    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    updateCartItemQuantity(itemId, newQuantity);
  };

  const handleRemoveCartItem = (itemId) => {
    const updatedCartItems = cartItems.filter((item) => item.id !== itemId);
    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    removeCartItem(itemId);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Reset validation messages on input change
    setValidationMessages((prev) => ({ ...prev, [name]: "" }));

    // Clear quantity error message if address or contact is being corrected
    if (name === "address" || name === "contact") {
      setQuantityErrorMessage("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Phone validation
    if (!afghanPhoneRegex.test(formData.contact)) {
      setValidationMessages((prev) => ({
        ...prev,
        phone: t("product.phoneNum"),
      }));
      return;
    }

    // Address validation
    if (!formData.address) {
      setValidationMessages((prev) => ({
        ...prev,
        address: t("product.enterAddress"),
      }));
      return;
    }

    // Quantity validation
    for (const item of cartItems) {
      if (validationMessages.quantity[item.id]) {
        setQuantityErrorMessage(validationMessages.quantity[item.id]);
        return;
      }
    }

    if (token) {
      handleAdd(formData);
    } else {
      navigate("/login");
    }
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="flex-start"
      padding={isMobile ? "20px" : "0px 20px"}
      mb={4}
      spacing={2}
    >
      <Grid item xs={12} md={11.7} borderBottom={"5px solid green"} pb={2}>
        <Typography
          mt={2}
          variant="h2"
          textAlign={"center"}
          fontWeight={"bold"}
        >
          {t("product.shoppingCart")}
        </Typography>
      </Grid>
      <Grid container justifyContent={"center"}>
        <Typography
          height={"4vh"}
          textAlign={"center"}
          padding={"0px 10px"}
          pt={"4"}
          color="error.main"
        >
          {quantityErrorMessage}
        </Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <TableContainer component={Paper} sx={{ maxHeight: "69vh" }}>
          <Table>
            <TableHead sx={{ bgcolor: "#dee3e0" }}>
              <TableRow>
                <TableCell align="center"> {t("product.product")}</TableCell>
                <TableCell align="center">
                  {" "}
                  {t("product.retailPrice")}
                </TableCell>
                <TableCell align="center">
                  {" "}
                  {t("product.wholePrice")}{" "}
                </TableCell>
                <TableCell align="center"> {t("product.quantity")}</TableCell>
                <TableCell align="center"> {t("product.inStock")} </TableCell>
                <TableCell align="center"> {t("product.total")}</TableCell>
                <TableCell align="center"> {t("product.action")}</TableCell>
                <TableCell align="center"> {t("product.chat")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cartItems.map((product) => (
                <TableRow key={product.id} hover>
                  <TableCell align="center">
                    <Box display="flex" alignItems="center">
                      <Box mr={2}>
                        <img
                          src={
                            product.images && product.images.length > 0
                              ? product.images[0].image
                              : "placeholder-image-url.jpg"
                          }
                          alt={product.name}
                          style={{
                            width: "80px",
                            height: "80px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                      <Typography variant="body1" fontWeight="bold">
                        {product.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Stack direction="row">
                      <Typography>
                        {product.price?.toFixed(2)}{" "}
                        {product.currency === 1 ? "؋" : "$"}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      {product.whole_price?.toFixed(2)}{" "}
                      {product.currency === 1 ? "؋" : "$"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      variant="outlined"
                      size="small"
                      type="number"
                      value={product.userQuantity}
                      onChange={(e) =>
                        handleUpdateCartItemQuantity(
                          product.id,
                          parseInt(e.target.value)
                        )
                      }
                      sx={{ maxWidth: { sm: "10vw", md: "7vw" } }}
                      error={!!validationMessages.quantity[product.id]}
                      helperText={validationMessages.quantity[product.id]}
                    />
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    {product.quantity}
                  </TableCell>
                  <TableCell align="center">
                    <Stack direction="row">
                      <Typography>
                        {product.userQuantity >= product.whole_price_count &&
                        product?.whole_price !== 0
                          ? (
                              product.whole_price * product.userQuantity
                            ).toFixed(2)
                          : (product.price * product.userQuantity).toFixed(
                              2
                            )}{" "}
                        {product.currency === 1 ? "؋" : "$"}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      onClick={() => handleRemoveCartItem(product.id)}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">
                    <MessageIcon
                      onClick={() => {
                        navigate("/chat", { state: { product } });
                      }}
                      sx={{ color: "blue", cursor: "pointer" }}
                      titleAccess={t("product.chatWith")}
                    ></MessageIcon>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper elevation={3} sx={{ padding: 2, height: "69vh" }}>
          <Typography
            variant="h6"
            fontWeight="bold"
            gutterBottom
            sx={{ borderBottom: "3px solid green", pb: 1 }}
            textAlign={"center"}
          >
            {t("product.cartSummary")}
          </Typography>

          <Box display="flex" justifyContent="space-between">
            <Typography variant="body1" fontWeight="bold">
              {t("product.totalUsd")}:
            </Typography>
            <Typography fontWeight="bold">
              {totalDollarsAmount.toFixed(2)}
            </Typography>
          </Box>

          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography variant="body1" fontWeight="bold">
              {t("product.totalAfn")}:
            </Typography>
            <Typography fontWeight="bold">
              {totalAfghanisAmount.toFixed(2)}
            </Typography>
          </Box>

          <Box mb={1}>
            <FormLabel>{t("product.address")}</FormLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              placeholder={t("product.writeAddress")}
              name="address"
              value={formData.address}
              onChange={handleChangeInput}
              InputProps={{
                inputProps: { min: 0 },
              }}
              rows={2}
              multiline
              error={!!validationMessages.address}
              helperText={validationMessages.address}
            />
          </Box>
          <Box mb={1}>
            <FormLabel>{t("product.activeNum")}</FormLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="contact"
              value={formData.contact}
              onChange={handleChangeInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+93</InputAdornment>
                ),
              }}
              error={!!validationMessages.phone}
              helperText={validationMessages.phone}
            />
          </Box>

          <Box mb={1}>
            <FormLabel>{t("product.description")}</FormLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              placeholder={t("product.writeDescription")}
              name="description"
              value={formData.description}
              onChange={handleChangeInput}
              InputProps={{
                inputProps: { min: 0 },
              }}
              rows={3}
              multiline
            />
          </Box>
          <Box mt={1}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
            >
              {t("product.checkout")}
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ShoppingCart;
