import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Paper,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Pagination,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PersonIcon from "@mui/icons-material/Person";
import DateRangeIcon from "@mui/icons-material/DateRange";
import TimeIcon from "@mui/icons-material/AccessTime";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import PersonPinIcon from "@mui/icons-material/PersonPin";

import { useAuth } from "../../../context/AuthContext";
import useUpdate from "../../../api/useUpdate";
import useDelete from "../../../api/useDelete";
import useFetchObjects from "../../../api/useFetchObjects";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import SkeletonLoading from "../../../components/skeletonLoading/SkeletonLoading";
import ErrorPage from "../../../components/errorPage/ErrorPage";
import { useTranslation } from "react-i18next";

const NotificationList = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const userID = user?.user?.id;
  const token = user?.token;
  const [page, setPage] = useState(1);
  const [selectedNotification, setSelectedNotification] = useState(null);

  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["order_notification"],
    `order_notification/?user=${userID}&page=${page}`,
    token
  );
  const handleNotificationClick = async (notification) => {
    setSelectedNotification(notification);

    // Call the function to mark the notification as read
    await markNotificationAsRead(notification);
  };

  const { handleUpdate } = useUpdate("order_notification", token);
  const { handleDelete, ConfirmDialog } = useDelete(
    "order_notification",
    token
  );

  useEffect(() => {
    refetch();
  }, [refetch, page]);

  const markNotificationAsRead = async (notification) => {
    const orderId = notification.order?.id; // Get the order ID from the notification
    handleUpdate(orderId, {
      user_id: userID,
      order_id: orderId,
      is_read: true,
    });
    refetch();
  };

  const handleBackToList = () => {
    setSelectedNotification(null);
  };

  dayjs.extend(relativeTime); // Extend dayjs to support relative time
  // Function that takes an ISO date as a parameter and returns the appropriate format
  function formatDateBasedOnAge(isoDate) {
    const date = dayjs(isoDate);
    const now = dayjs();

    // Check if the date is within the last 7 days
    if (now.diff(date, "day") < 7) {
      // If it's less than 7 days ago, return "time ago" format
      return date.fromNow();
    } else {
      // Otherwise, return the full date and time format
      return date.format("D MMM YYYY, h:mm A");
    }
  }

  // Load the relativeTime plugin
  dayjs.extend(relativeTime);

  function formatDateTime(date, time) {
    // Combine date and time into a single string
    const dateTimeString = `${date} ${time}`;

    // Parse the combined string into a Day.js object
    const dateTime = dayjs(dateTimeString);

    // Get the current time for relative time calculation
    const now = dayjs();

    // Format the date and time into human-readable strings
    const formattedDate = dateTime.format("MMMM D, YYYY"); // e.g., "October 27, 2024"
    const formattedTime = dateTime.format("h:mm A"); // e.g., "12:38 PM"

    // Get relative time (e.g., "in 1 day" or "3 days ago")
    const relativeTimeString = dateTime.from(now);

    return {
      formattedDate,
      formattedTime,
      relativeTime: relativeTimeString,
    };
  }

  const result = formatDateTime(
    selectedNotification?.order?.order_date,
    selectedNotification?.order?.order_time
  );

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  if (isLoading) {
    return (
      <Grid container mt={1} padding={"20px"} spacing={2}>
        {Array.from({ length: 6 }).map((_, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <SkeletonLoading />
          </Grid>
        ))}
      </Grid>
    );
  }

  if (isError) {
    return (
      <Grid container>
        <ErrorPage errorMessage={t("notification.error")} />
      </Grid>
    );
  }

  return (
    <Grid container justifyContent={"center"} bgcolor="#f0f4f8">
      <Grid
        item
        xs={12}
        md={10}
        lg={8}
        sx={{
          p: "10px 25px",

          minHeight: "100vh",
          borderRadius: "10px",
        }}
        mt={3}
      >
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            fontWeight: "bold",
            color: "#3f51b5",
            textAlign: "center",
            mb: 4,
          }}
        >
          {t("notification.notifications")}
        </Typography>

        {selectedNotification ? (
          <Box>
            <Button
              onClick={handleBackToList}
              variant="outlined"
              sx={{ mb: 2 }}
            >
              {t("notification.backNotifications")}
            </Button>
            <Card
              sx={{
                bgcolor: "#fff",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                borderRadius: 2,
              }}
            >
              <CardContent>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={"center"}
                  mb={2}
                >
                  <Avatar sx={{ bgcolor: "#3f51b5", mr: 2 }}>
                    <NotificationsIcon />
                  </Avatar>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", color: "#333", mr: 2 }}
                  >
                    {t("notification.orderId")}{" "}
                    {selectedNotification?.order?.id}
                  </Typography>
                </Box>

                <Paper
                  variant="outlined"
                  sx={{ p: 2, bgcolor: "#f5f5f5", borderRadius: "10px", mb: 2 }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={"center"}
                    mb={1}
                    borderBottom={"5px solid #dbeaf1"}
                  >
                    <Typography variant="h3" fontWeight={"bold"}>
                      {t("notification.buyerInfo")}
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center" mb={1}>
                    <PersonIcon sx={{ color: "#3f51b5", mr: 1 }} />
                    <Typography
                      color="text.secondary"
                      width={"150px"}
                      sx={{ mr: 1 }}
                    >
                      {t("notification.buyerName")}:
                    </Typography>
                    <Typography
                      textTransform={"capitalize"}
                      color="text.secondary"
                    >
                      {`${selectedNotification?.order_user_info?.first_name}${selectedNotification?.order_user_info?.last_name}` ||
                        selectedNotification?.order_user_info?.username}
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center" mb={1}>
                    <PersonPinIcon sx={{ color: "#3f51b5", mr: 1 }} />
                    <Typography
                      color="text.secondary"
                      width={"150px"}
                      sx={{ mr: 1 }}
                    >
                      {t("notification.gender")}:
                    </Typography>
                    <Typography color="text.secondary">
                      {selectedNotification?.order_user_info?.gender === 1
                        ? t("notification.male")
                        : t("notification.female") || "N/A"}
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center" mb={1}>
                    <EmailIcon sx={{ color: "#3f51b5", mr: 1 }} />
                    <Typography
                      color="text.secondary"
                      width={"150px"}
                      sx={{ mr: 1 }}
                    >
                      {t("notification.email")}:
                    </Typography>
                    <Typography color="text.secondary">
                      {selectedNotification?.order_user_info?.email || "N/A"}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <PhoneInTalkIcon sx={{ color: "#3f51b5", mr: 1 }} />
                    <Typography
                      color="text.secondary"
                      width={"150px"}
                      sx={{ mr: 1 }}
                    >
                      {t("notification.phone")}:
                    </Typography>
                    <Typography color="text.secondary">
                      {selectedNotification?.order_user_info?.phone_number ||
                        "N/A"}
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center" mb={1}>
                    <PhoneInTalkIcon sx={{ color: "#3f51b5", mr: 1 }} />
                    <Typography
                      color="text.secondary"
                      width={"150px"}
                      sx={{ mr: 1 }}
                    >
                      {t("notification.activePhone")}:
                    </Typography>
                    <Typography color="text.secondary">
                      {selectedNotification?.order?.contact || "N/A"}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <LocationOnIcon sx={{ color: "#3f51b5", mr: 1 }} />
                    <Typography
                      color="text.secondary"
                      width={"150px"}
                      sx={{ mr: 1 }}
                    >
                      {t("notification.address")}:
                    </Typography>
                    <Typography color="text.secondary">
                      {selectedNotification?.order?.address || "N/A"}
                    </Typography>
                  </Box>
                </Paper>

                <Paper
                  variant="outlined"
                  sx={{ p: 2, bgcolor: "#f5f5f5", borderRadius: "10px", mb: 2 }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={"center"}
                    mb={1}
                    borderBottom={"5px solid #dbeaf1"}
                  >
                    <Typography variant="h3" fontWeight={"bold"}>
                      {t("notification.orderInfo")}
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center" mb={1}>
                    <AttachMoneyIcon sx={{ color: "#3f51b5", mr: 1 }} />
                    <Typography
                      color="text.secondary"
                      width={"160px"}
                      sx={{ mr: 1 }}
                    >
                      {t("notification.totalAmount1")}:
                    </Typography>
                    <Typography color="text.secondary">
                      {`${selectedNotification?.order?.total_amount_afg} ؋` ||
                        "N/A"}
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center" mb={1}>
                    <AttachMoneyIcon sx={{ color: "#3f51b5", mr: 1 }} />
                    <Typography
                      color="text.secondary"
                      width={"160px"}
                      sx={{ mr: 1 }}
                    >
                      {t("notification.totalAmount2")}:
                    </Typography>
                    <Typography color="text.secondary">
                      {`${selectedNotification?.order?.total_amount_usd} $` ||
                        "N/A"}
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center" mb={1}>
                    <DateRangeIcon sx={{ color: "#3f51b5", mr: 1 }} />
                    <Typography
                      color="text.secondary"
                      width={"160px"}
                      sx={{ mr: 1 }}
                    >
                      {t("notification.orderDate")}:
                    </Typography>
                    <Typography color="text.secondary">
                      {result.formattedDate || "N/A"}
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center" mb={1}>
                    <TimeIcon sx={{ color: "#3f51b5", mr: 1 }} />
                    <Typography
                      color="text.secondary"
                      width={"160px"}
                      sx={{ mr: 1 }}
                    >
                      {t("notification.orderTime")}:
                    </Typography>
                    <Typography color="text.secondary">
                      {result.formattedTime || "N/A"}
                    </Typography>
                  </Box>
                </Paper>
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          {t("notification.id")}
                        </TableCell>
                        <TableCell align="center">
                          {t("notification.productName")}
                        </TableCell>
                        <TableCell align="center">
                          {t("notification.price")}
                        </TableCell>
                        <TableCell align="center">
                          {t("notification.wholePrice")}
                        </TableCell>
                        <TableCell align="center">
                          {t("notification.quantity")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedNotification?.order_items.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell align="center">
                            {item.product.id || "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {item.product.name || "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {item.product.price}{" "}
                            {item.product.currency === 1 ? "؋" : "$"}
                          </TableCell>
                          <TableCell align="center">
                            {item.product.whole_price || 0}{" "}
                            {item.product.currency === 1 ? "؋" : "$"}
                          </TableCell>
                          <TableCell align="center">{item.quantity}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Box>
        ) : (
          <Grid container spacing={3}>
            {data?.results && data?.results?.length > 0 ? (
              data?.results?.map((notification, index) => (
                <Grid item xs={12} md={8} lg={6} key={index}>
                  <Card
                    onClick={() => {
                      handleNotificationClick(notification);
                      // Optionally mark as read here, if needed.
                    }}
                    sx={{
                      bgcolor: notification?.is_read ? "#fff" : "#f3f5f7",
                      border: "1px solid white",
                      borderRadius: 2,
                      cursor: "pointer",
                      transition: "transform 0.2s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.03)",
                      },
                    }}
                  >
                    <CardContent>
                      <Grid
                        container
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        borderBottom={"2px solid #dbeaf1"}
                        pb={1}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: "bold",
                            color: "#333",
                          }}
                          textAlign={"center"}
                        >
                          Order ID {notification?.order?.id}
                        </Typography>
                        <Button
                          variant="outlined"
                          color="error"
                          size="small"
                          sx={{ textTransform: "capitalize" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(notification?.id);
                          }}
                        >
                          {t("notification.delete")}
                        </Button>
                      </Grid>
                      <Typography>
                        {t("notification.orderId")} {notification?.order?.id}
                      </Typography>
                      <Grid container mt={1}>
                        <Box>
                          <Avatar
                            src={notification?.order_user_info?.image}
                            alt={notification?.order_user_info?.username}
                            sx={{
                              width: 60,
                              height: 60,
                              mx: "auto",
                              textTransform: "capitalize",
                              margin: "0px 10px",
                            }}
                          />
                        </Box>
                        <Box ml={2}>
                          <Typography
                            color="text.primary"
                            textTransform={"capitalize"}
                          >
                            {`${notification?.order_user_info?.first_name}${notification?.order_user_info?.last_name}` ||
                              notification?.order_user_info?.username}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {formatDateBasedOnAge(notification?.created_at)}
                          </Typography>
                        </Box>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography variant="body1" color="text.secondary" align="center">
                {t("notification.noNotification")}
              </Typography>
            )}
          </Grid>
        )}
        <Grid container justifyContent={"center"} margin={"30px 0px"}>
          <div dir="ltr">
            <Pagination
              count={Math.ceil(data.count / 8)}
              page={page}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </Grid>
      </Grid>
      <ConfirmDialog />
    </Grid>
  );
};

export default NotificationList;
