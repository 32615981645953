import React from 'react'
import {Route, Routes} from "react-router-dom"
import Contact from '../../web/pages/contact/Contact'

const ContactRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Contact />} />
    </Routes>
  )
}

export default ContactRoutes