import React from "react";
import {
  Box,
  Typography,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { tokens } from "../../theme"; // Import your theme tokens
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useTranslation } from "react-i18next";

const ReportDetails = ({ data }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode); // Get colors based on the current theme mode

  const {
    total_customers,
    total_buyers,
    total_sellers,
    total_verified_sellers,
    category_sales_quantity,
    category_with_more_sales,
    most_sold_product_name,
    most_sold_product_quantity,
    products_report,
    total_today_orders,
  } = data;

  const downloadPDF = () => {
    const input = document.getElementById("report-details");

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 190; // Define image width in mm
      const pageHeight = pdf.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      // Set the background color of the PDF
      pdf.setFillColor(255, 255, 255); // White background
      pdf.rect(0, 0, 210, 297, "F"); // Full page rectangle

      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("report-details.pdf");
    });
  };

  return (
    <Box sx={{ padding: 2 }} id="report-details">
      {/* Download Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={downloadPDF}
        sx={{ marginBottom: 2 }}
      >
        {t("dashboard.downloadPdf")}
      </Button>

      {/* Summary Stats Table */}
      <TableContainer
        component={Paper}
        sx={{ marginBottom: 3, backgroundColor: colors.primary[400] }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: colors.grey[100], fontWeight: "bold" }}>
                {t("dashboard.description")}
              </TableCell>
              <TableCell sx={{ color: colors.grey[100], fontWeight: "bold" }}>
                {t("dashboard.value")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ color: colors.grey[100] }}>
                {t("dashboard.totalCustomers")}
              </TableCell>
              <TableCell sx={{ color: colors.greenAccent[500] }}>
                {total_customers}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ color: colors.grey[100] }}>
                {t("dashboard.totalBuyers")}
              </TableCell>
              <TableCell sx={{ color: colors.greenAccent[500] }}>
                {total_buyers}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ color: colors.grey[100] }}>
                {t("dashboard.totalSellers")}
              </TableCell>
              <TableCell sx={{ color: colors.greenAccent[500] }}>
                {total_sellers}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ color: colors.grey[100] }}>
                {t("dashboard.verifiedSellers")}
              </TableCell>
              <TableCell sx={{ color: colors.greenAccent[500] }}>
                {total_verified_sellers}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ color: colors.grey[100] }}>
                {t("dashboard.todayOrders")}
              </TableCell>
              <TableCell sx={{ color: colors.greenAccent[500] }}>
                {total_today_orders}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ color: colors.grey[100] }}>
                {t("dashboard.mostSoldProduct")}
              </TableCell>
              <TableCell sx={{ color: colors.greenAccent[500] }}>
                {most_sold_product_name} ({most_sold_product_quantity} sold)
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ color: colors.grey[100] }}>
                {t("dashboard.categoryMoreSales")}
              </TableCell>
              <TableCell sx={{ color: colors.greenAccent[500] }}>
                {category_with_more_sales} ({t("dashboard.soldAmount")}:{" "}
                {category_sales_quantity})
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* Product Sales Report Table */}
      <Typography variant="h5" gutterBottom>
        {t("dashboard.productSalesReport")}
      </Typography>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: colors.primary[400] }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: colors.grey[100], fontWeight: "bold" }}>
                {t("dashboard.productId")}
              </TableCell>
              <TableCell sx={{ color: colors.grey[100], fontWeight: "bold" }}>
                {t("dashboard.productName")}
              </TableCell>
              <TableCell sx={{ color: colors.grey[100], fontWeight: "bold" }}>
                {t("dashboard.soldCount")}
              </TableCell>
              <TableCell sx={{ color: colors.grey[100], fontWeight: "bold" }}>
                {t("dashboard.orderCount")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products_report?.map((product) => (
              <TableRow key={product.product_id}>
                <TableCell sx={{ color: colors.grey[100] }}>
                  {product.product_id}
                </TableCell>
                <TableCell sx={{ color: colors.grey[100] }}>
                  {product.product_name}
                </TableCell>
                <TableCell sx={{ color: colors.greenAccent[500] }}>
                  {product.sold_count}
                </TableCell>
                <TableCell sx={{ color: colors.greenAccent[500] }}>
                  {product.order_count}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ReportDetails;
