import React from 'react'
import StripePayment from '../../web/pages/products/stripePayment/StripePayment'
import { Route, Routes } from 'react-router-dom'

const StripeRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<StripePayment />} />
    </Routes>
  )
}

export default StripeRoutes