import React from "react";
import { Box, Typography, Button } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";

const NoData = ({ message, onRetry }) => {
  const {t}=useTranslation();
  return (
    <Box
      sx={{
        height: "60vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        bgcolor: "background.paper",
      }}
    >
      <InfoOutlinedIcon sx={{ fontSize: 60, color: "text.secondary", mb: 2 }} />
      <Typography variant="h5" color="text.primary" gutterBottom>
        {t("component.noData")}
     
      </Typography>
      <Typography variant="body1" color="textSecondary" mb={2}>
        {message || t("component.noDataDisplay")}
      </Typography>
      {onRetry && (
        <Button variant="contained" color="primary" onClick={onRetry}>
           {t("component.retry")}
        </Button>
      )}
    </Box>
  );
};

export default NoData;
