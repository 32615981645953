import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Divider,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Box sx={{ textAlign: "center", py: 5 }}>
        <Typography variant="h3" component="h1" fontWeight="bold" gutterBottom>
          {t("about.aboutUs")}
        </Typography>
        <Typography variant="h6" color="textSecondary">
          {t("about.content")}
        </Typography>
      </Box>

      <Box sx={{ py: 3 }}>
        <Typography variant="body1" paragraph>
          <strong> {t("about.asanBawar")}</strong> {""}
          {t("about.content1")}
        </Typography>
        <Typography variant="body1" paragraph>
          {t("about.content2")}
        </Typography>
      </Box>

      <Box
        sx={{
          py: 5,
          backgroundColor: "#f9f9f9",
          borderRadius: 2,
          boxShadow: 1,
          padding: 4,
        }}
      >
        <Grid container spacing={5} sx={{ display: "flex" }}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              component="h2"
              fontWeight="bold"
              gutterBottom
              sx={{ color: "#333" }}
            >
              {t("about.services1")}:
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.6 }}>
              {t("about.services1Content1")}
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: 1.6 }}>
              {t("about.services1Content2")}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              component="h2"
              fontWeight="bold"
              gutterBottom
              sx={{ color: "#333" }} // Darker color for the heading
            >
              {t("about.services2")}:
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.6 }}>
              {t("about.services2Content1")}
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: 1.6 }}>
              {t("about.services2Content2")}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ my: 5 }} />

      {/* Contact Section */}
      <Box sx={{ py: 5, textAlign: "center" }}>
        <Typography variant="h4" component="h2" fontWeight="bold" gutterBottom>
          {t("about.contactUs")}
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          {t("about.questions")}
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>{t("about.email")}</strong>: info@asanbawar.com |{" "}
          <strong>{t("about.whatsApp")}</strong>: 0795586572 |{" "}
          <strong>{t("about.contact")}</strong>: 0747242885
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>{t("about.address")}</strong>
          {t("about.addressContent")}
        </Typography>
        <Button variant="contained" color="primary" size="large" sx={{ mt: 3 }}>
          {t("about.getInTouch")}
        </Button>
      </Box>
    </Container>
  );
};

export default AboutUs;
