import React from "react";
import { Box, Skeleton } from "@mui/material";

const LoadingSkeleton = () => {
  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Skeleton variant="text" width={200} height={40} />
        <Skeleton variant="rectangular" width={40} height={40} />
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        {Array.from({ length: 4 }).map((_, index) => (
          <Box
            key={index}
            gridColumn="span 3"
            bgcolor="#424242" // Dark color
            borderRadius="8px" // Border radius
            overflow="hidden" // To ensure the skeleton fits within the rounded corners
          >
            <Skeleton variant="rectangular" height="100%" />
          </Box>
        ))}

        {/* ROW 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          bgcolor="#424242" // Dark color
          borderRadius="8px" // Border radius
          overflow="hidden"
        >
          <Skeleton variant="text" width="80%" height={40} />
          <Skeleton variant="rectangular" height="250px" />
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          bgcolor="#424242" // Dark color
          borderRadius="8px" // Border radius
          overflow="hidden"
        >
          <Skeleton variant="text" width="80%" height={40} />
          {Array.from({ length: 5 }).map((_, index) => (
            <Skeleton key={index} variant="rectangular" height={60} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default LoadingSkeleton;
