// src/components/ChatError.js
import React from "react";
import { Box, Typography, Button } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useTranslation } from "react-i18next";

const ChatError = ({ messageKey = "chat.error", onRetry }) => {
  const { t } = useTranslation();
  const message = t(messageKey);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "#ffebee",
      }}
    >
      <ErrorOutlineIcon sx={{ fontSize: 50, color: "#d32f2f" }} />
      <Typography
        variant="h6"
        sx={{ marginTop: 2, color: "#d32f2f", fontWeight: 500 }}
      >
        {message}
      </Typography>
      {onRetry && (
        <Button
          variant="contained"
          color="primary"
          sx={{ marginTop: 2 }}
          onClick={onRetry}
        >
          {t("chat.retry")}
        </Button>
      )}
    </Box>
  );
};

export default ChatError;
