import React from "react";
import ChatIndex from "../../web/pages/chat/ChatIndex";
import { Route, Routes } from "react-router-dom";
import Chat from "../../web/pages/chat/Chat";
import NoMessage from "../../web/pages/chat/NoMessage";

const ChatRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ChatIndex />}>
        <Route path="/" element={<NoMessage />} />
        <Route path="/:id" element={<Chat />} />
      </Route>
    </Routes>
  );
};

export default ChatRoutes;
