import React, { useEffect, useState } from "react";
import { AppBar, Box, Container, Grid, ThemeProvider } from "@mui/material";
import NavBar from "./web/layout/navBar/NavBar";
import { Outlet, useLocation } from "react-router-dom";
import Theme from "./theme/Theme";
import Footer from "./web/layout/footer/Footer";

// slider need file
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Search from "./web/layout/navBar/Search";

function App() {
  const location = useLocation();
  const path = location.pathname;

  const footerPaths = ["/", "/products"];

  const [direction, setDirection] = useState("ltr");

  useEffect(() => {
    const updateDirection = () => {
      const savedDirection = localStorage.getItem("direction");
      setDirection(savedDirection || "ltr");
    };

    updateDirection();
  }, []);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  return (
    <Grid container margin={"0px auto"}>
      <ThemeProvider theme={Theme}>
        <AppBar
          position="sticky"
          sx={{
            bgcolor: "#2E2E2E", // Dark footer-like background for consistency
            boxShadow: "none",
            borderBottom: "1px solid #444",
            width: "100%",
          }}
        >
          <Container
            maxWidth="xl"
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <NavBar />
          </Container>

          <Grid
            container
            maxWidth="xl"
            sx={{ display: { xs: "block", md: "none" } }}
          >
            <NavBar />
          </Grid>
        </AppBar>

        <Box
          sx={{
            display: { xs: "block", sm: "none" },
            width: "100%",
            padding: "5px",
            bgcolor: "#2E2E2E",
          }}
        >
          <Search />
        </Box>

        {path !== "/email-verify" &&
        path !== "/login" &&
        path !== "/otp-verify" &&
        path !== "/reset-password" &&
        path !== "/register" &&
        path !== "/chat" ? (
          <Grid container maxWidth={"xl"} mt={"5px"} margin={"0px auto"}>
            <Outlet />
          </Grid>
        ) : (
          <Grid container>
            <Outlet />
          </Grid>
        )}

        {footerPaths.includes(location.pathname) && <Footer />}
      </ThemeProvider>
    </Grid>
  );
}

export default App;
