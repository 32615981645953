import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  InputBase,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DateFormatter from "../../../components/DateFormatter/DateFormatter";
import React, { useState, useEffect } from "react";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import useFetchObjects from "../../../api/useFetchObjects";
import useDelete from "../../../api/useDelete";
import useUpdate from "../../../api/useUpdate";
import { useAuth } from "../../../context/AuthContext";
import {
  InfoOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@mui/icons-material";

import "./style.css";
import OrderDetails from "./OrderDetails";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Orders = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const [buyerInfo, setBuyerInfo] = useState(null);
  const [orderInfo, setOrderInfo] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [orderToConceal, setOrderToConceal] = useState(null);
  const [search, setSearch] = useState("");

  const { data, isLoading, refetch } = useFetchObjects(
    ["OrdersList"],
    `orders/?search=${search}`,
    token
  );

  useEffect(() => {
    refetch();
  }, [search, refetch]);

  const { handleDelete, ConfirmDialog } = useDelete(`orders`, token);
  const { handleUpdate } = useUpdate("orders", token, "/dashboard/orders");

  const handleMoreDetails = (params) => {
    setOrderItems(params.row.order_items_response);
    setOrderInfo(params.row);
    setBuyerInfo(params.row);
    setOpen(true);
  };

  const confirmConceal = () => {
    if (orderToConceal) {
      handleUpdate(orderToConceal, { status: 3 });
      setConfirmOpen(false);
      setOrderToConceal(null);
      refetch();
    }
  };

  const handleStatusChange = (id, newStatus) => {
    handleUpdate(id, { status: newStatus });
    refetch();
  };

  const columns = [
    { field: "id", headerName: t("manage_order.id"), flex: 0.5 },
    {
      field: "order_date",
      headerName: t("manage_order.orderDate"),
      renderCell: (params) => <>{DateFormatter(params?.row?.created_at)}</>,
      flex: 1.5,
    },
    {
      field: "user",
      headerName: t("manage_order.customer"),
      flex: 1.5,
      renderCell: ({ row }) => (
        <Box>
          <Typography>
            {row?.display_user?.firstname && row?.display_user?.lastname
              ? `${row?.display_user?.firstname} ${row?.display_user?.lastname}`
              : t("manage_order.asanbawer")}
          </Typography>
        </Box>
      ),
    },
    {
      field: "total_amount_afg",
      headerName: t("manage_order.totalAmount1"),
      renderCell: (params) => <div>{params?.row?.total_amount_afg}</div>,
      flex: 1,
    },
    {
      field: "total_amount_usd",
      headerName: t("manage_order.totalAmount2"),
      renderCell: (params) => <div>{params?.row?.total_amount_usd}</div>,
      flex: 1,
    },
    {
      field: "status",
      headerName: t("manage_order.status"),
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <FormControl variant="standard" sx={{ minWidth: 120 }}>
            <Select
              value={row.status}
              onChange={(e) => handleStatusChange(row.id, e.target.value)}
            >
              <MenuItem value={1}>{t("manage_order.pending")}</MenuItem>
              <MenuItem value={2}>{t("manage_order.canceled")}</MenuItem>
              <MenuItem onClick={(e) => {}} value={3} disabled>
                {t("manage_order.accepted")}
              </MenuItem>
              <MenuItem onClick={(e) => {}} value={4} disabled>
                {t("manage_order.progress")}
              </MenuItem>
              <MenuItem onClick={(e) => {}} value={5} disabled>
                {t("manage_order.delivered")}
              </MenuItem>
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "more_details",
      headerName: t("manage_order.moreDetails"),
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" width="100%">
          <IconButton
            color="secondary"
            onClick={() => {
              handleMoreDetails(params);
            }}
          >
            <InfoOutlined />
          </IconButton>
        </Box>
      ),
    },

    {
      field: "is_paid",
      headerName: t("manage_order.isPaid"),
      flex: 1.1,
      renderCell: (params) => {
        const isPaid = params.row.is_paid;

        return (
          <Box
            display="flex"
            justifyContent="center"
            width="100%"
          >
            {isPaid ? (
              <Button
                variant="contained"
                color="success"
                size="small"
                sx={{ cursor: "default", textTransform: "capitalize" }}
                disabled
              >
                {t("manage_order.paid")}
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => handleUpdate(params.row.id, { is_paid: true })}
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(255, 0, 0, 0.1)",
                  },
                  padding: "3px",
                  textTransform: "capitalize",
                
                }}
                disabled
              >
                {t("manage_order.markPaid")}
              </Button>
            )}
          </Box>
        );
      },
    },

    {
      field: "action",
      headerName: t("manage_order.actions"),
      flex: 0.5,
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" width="100%">
          <IconButton
            color="secondary"
            onClick={() => handleDelete(params.row.id)}
          >
            <DeleteOutlined />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item xs={6}>
          <Header
            title={t("manage_order.orders")}
            subtitle={t("manage_order.ordersList")}
          />
        </Grid>
        <Grid item xs={6}>
          <Box
            display="flex"
            backgroundColor={colors.primary[400]}
            borderRadius="3px"
          >
            <InputBase
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              sx={{ m: "0em 1em", flex: 1 }}
              placeholder={t("manage_order.search")}
            />
            <IconButton type="button" sx={{ p: 1 }}>
              <SearchOutlined />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      <Box
        m="40px 0 0 0"
        mt={0}
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {isLoading ? (
          t("manage_order.loading")
        ) : (
          <DataGrid
            rows={data && data}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
          />
        )}
      </Box>

      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogContent sx={{ backgroundColor: colors.primary[400] }}>
          <Typography>{t("manage_order.wantCancel")}</Typography>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            backgroundColor: colors.primary[400],
          }}
        >
          <Button onClick={() => setConfirmOpen(false)} sx={{ color: "white" }}>
            {t("manage_order.no")}
          </Button>
          <Button onClick={confirmConceal} sx={{ color: "green" }}>
            {t("manage_order.yes")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle
          style={{
            textAlign: "center",
            backgroundColor: "#3f51b5",
            color: "#fff",
          }}
        >
          <Typography variant="h3">{t("manage_order.orderDetails")}</Typography>
        </DialogTitle>
        <DialogContent className="dialog-content">
          <OrderDetails
            buyerInfo={buyerInfo}
            orderInfo={orderInfo}
            orderItems={orderItems}
          />
        </DialogContent>

        <DialogActions
          sx={{ justifyContent: "center", backgroundColor: "#3f51b5" }}
        >
          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            color="primary"
          >
            {t("manage_order.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmDialog />
    </Box>
  );
};

export default Orders;
