import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, InputBase, Stack } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import useFetchObjects from "../../../api/useFetchObjects";
import ErrorPage from "../../../components/errorPage/ErrorPage";
import { useAuth } from "../../../context/AuthContext";
import useDelete from "../../../api/useDelete";
import Header from "../../components/Header";
import { SearchOutlined } from "@mui/icons-material";
import useAdd from "../../../api/useAdd";
import useUpdate from "../../../api/useUpdate";
import PaymentDialog from "./PaymentDialog";
import formatDateTime from "../global/FormatDateTime";
import { useTranslation } from "react-i18next";
const PaymentList = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [search, setSearch] = useState("");
  const auth = useAuth();
  const token = auth?.user?.token;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["payments"],
    `payments/?search=${search}`,
    token
  );

  const { handleAdd, loading, resetForm } = useAdd(`payments`, token);
  const { handleUpdate, loading: loadingEdit } = useUpdate("payments", token);
  const { handleDelete, ConfirmDialog } = useDelete("payments", token);

  const handleDialogOpen = (order = null) => {
    setSelectedOrder(order);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedOrder(null);
  };

  useEffect(() => {
    if (resetForm) {
      handleDialogClose();
      refetch();
    }
  }, [resetForm]);

  useEffect(() => {
    refetch();
  }, [search]);

  const handleDialogSubmit = (data) => {
    if (selectedOrder) {
      // Update logic
      handleUpdate(selectedOrder.id, data);
      handleDialogClose();
    } else {
      // Add logic
      handleAdd(data);
    }
  };

  // Transform the data to match the expected DataGrid format
  const rows =
    data?.map((item) => ({
      id: item?.id,
      order: item?.order,
      date: item?.date,
      total_amount_afg: item?.total_amount_afg,
      total_amount_usd: item?.total_amount_usd,
    })) || [];

  const columns = [
    { field: "id", headerName: t("payment.id"), flex: 0.5 },
    {
      field: "order",
      headerName: t("payment.order"),
      flex: 1,
    },
    {
      field: "date",
      headerName: t("payment.orderDate"),
      flex: 1,
      renderCell: (params) => <div>{formatDateTime(params.row.date)}</div>,
    },
    {
      field: "total_amount_afg",
      headerName: t("payment.totalAmount1"),
      flex: 1,
      renderCell: (params) => <div>{`${params.row.total_amount_afg} ؋`}</div>,
    },
    {
      field: "total_amount_usd",
      headerName: t("payment.totalAmount2"),
      flex: 1,
      renderCell: (params) => <div>{`${params.row.total_amount_usd} $`}</div>,
    },
    {
      field: "actions",
      headerName: t("payment.actions"),
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ textTransform: "capitalize" }}
            onClick={() => {
              handleDialogOpen(params.row);
              console.log(params);
            }}
          >
            {t("payment.edit")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              handleDelete(params.id);
              
            }}
            style={{margin:"0em 1em"}}
          >
            {t("payment.delete")}
          </Button>
        </Stack>
      ),
    },
  ];

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <Box m={"10px"}>
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item xs={6}>
          <Header
            title={t("payment.payments")}
            subtitle={t("payment.ListPayments")}
          />
        </Grid>
        <Grid item xs={6}>
          <Box
            display="flex"
            backgroundColor={colors.primary[400]}
            borderRadius="3px"
          >
            <InputBase
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              sx={{ m: "0em 1em", flex: 1 }}
              placeholder={t("payment.search")}
            />
            <IconButton type="button" sx={{ p: 1 }}>
              <SearchOutlined />
            </IconButton>
          </Box>
        </Grid>
        <Button
          variant="contained"
          color="secondary"
          sx={{ textTransform: "capitalize" }}
          onClick={() => handleDialogOpen()}
        >
          {t("payment.addPayment")}
        </Button>
      </Grid>

      <Box
        m="40px 0 0 0"
        mt={0}
        height="72vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          rowHeight={60}
          components={{ Toolbar: GridToolbar }}
          loading={isLoading}
          error={isError}
        />
        <ConfirmDialog />
      </Box>

      <PaymentDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onSubmit={handleDialogSubmit}
        initialData={selectedOrder}
        loading={loading}
        editLoading={loadingEdit}
      />
    </Box>
  );
};

export default PaymentList;
