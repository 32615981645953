// CardsSkeleton.js
import React from "react";
import { Grid, Paper, Skeleton } from "@mui/material";

const CardsSkeleton = () => {
  return (
    <Grid container spacing={2} justifyContent="center">
      {Array.from({ length: 9 }).map((_, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3.5} key={index}>
          <Paper
            sx={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <Skeleton variant="rectangular" height={140} />
            <div style={{ padding: "10px", flexGrow: 1 }}>
              <Skeleton variant="text" />
              <Skeleton variant="text" width="80%" />
              <Skeleton variant="text" width="60%" />
              <div style={{ marginTop: "16px", display: "flex" }}>
                <Skeleton variant="rectangular" width="100%" height={36} />
              </div>
            </div>
            <div style={{ padding: "8px", textAlign: "center" }}>
              <Skeleton variant="text" width="60%" />
            </div>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default CardsSkeleton;
