import React from "react";
import { Box, Button, Grid, Stack, TextField } from "@mui/material";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import Header from "../../components/Header"; // Ensure this is your header component
import { useTranslation } from "react-i18next";

const navlinkStyle = {
  textDecoration: "none",
  fontWeight: "bold",
  color: "white",
  margin: "5px",
  fontSize: "1.2rem",
  backgroundColor: "#302f4f",
  padding: "10px",
  borderRadius: "5px",
};
const Index = () => {
  const location = useLocation();
  const {t}=useTranslation();

  const currentPath = location.pathname;
  return (
    <Box m="20px" mt="0">
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item xs={6}>
          <Header title={t("manage_home.home")} subtitle={t("manage_home.home1")} />
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Stack direction={"row"}>
              <NavLink
                to="/dashboard/home"
                style={() =>
                  currentPath === "/dashboard/home"
                    ? { ...navlinkStyle, backgroundColor: "#0e0d29" }
                    : navlinkStyle
                }
              >
              {t("manage_home.slider")}
              </NavLink>
              <NavLink
                to="special-offer"
                style={({ isActive }) =>
                  isActive
                    ? { ...navlinkStyle, backgroundColor: "#0e0d29" }
                    : navlinkStyle
                }
              >
                {t("manage_home.specialOffers")}
              </NavLink>
              <NavLink
                to="featured-brands"
                style={({ isActive }) =>
                  isActive
                    ? { ...navlinkStyle, backgroundColor: "#0e0d29" }
                    : navlinkStyle
                }
              >
                
                {t("manage_home.featuredBrands")}
              </NavLink>
              <NavLink
                to="featured-products"
                style={({ isActive }) =>
                  isActive
                    ? { ...navlinkStyle, backgroundColor: "#0e0d29" }
                    : navlinkStyle
                }
              >
                {t("manage_home.featuredProducts")}
                
              </NavLink>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Outlet />
    </Box>
  );
};

export default Index;
