import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import useFetchObjects from "../../../../api/useFetchObjects";
import useAdd from "../../../../api/useAdd";
import ImageCropper from "./ImageCropper";
import Header from "../../../components/Header";
import { useTranslation } from "react-i18next";
import provinces from "./provinces";
const AddProduct = () => {
  const { t } = useTranslation();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const [filter, setFilter] = useState();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  const {
    data: categories,
    isLoading,
    isError,
    refetch,
  } = useFetchObjects(["categories"], `categories/`, token);

  const [formData, setFormData] = useState({
    name: "",
    price: "",
    whole_price: "",
    whole_price_count: "",
    quantity: "",
    description: "",
    discount: "",
    category: "",
    currency: "",
    color: [], // Initialize color as an array
    size: "",
    uploaded_images: [],
    location: "",
    is_visible_seller_info: true,
    province: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imagePreview, setImagePreview] = useState([]);
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedImage, setCroppedImages] = useState(null);

  const { handleAdd, resetForm, loading } = useAdd(
    "products",
    token,
    "Product added successfully",
    "Failed to add product"
  );

  useEffect(() => {
    if (resetForm) {
      setFormData({
        name: "",
        price: "",
        whole_price: "",
        whole_price_count: "",
        quantity: "",
        discount: "",
        category: "",
        description: "",
        currency: "",
        color: [], // Reset the color field
        size: "",
        location: "",
        is_visible_seller_info: true,
        uploaded_images: [],
        province: "",
      });
      setImagePreview([]);
      setFormErrors({});
    }
  }, [resetForm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const productData = new FormData();

    let errors = {};
    if (!formData.name) errors.name = t("manage_product.nameRequired");
    if (!formData.category)
      errors.category = t("manage_product.categoryRequired");
    if (!formData.currency)
      errors.currency = t("manage_product.currencyRequired");
    if (!formData.description)
      errors.description = t("manage_product.descriptionRequired");
    if (!formData.price) errors.price = t("manage_product.priceRequired");
    if (!formData.quantity)
      errors.quantity = t("manage_product.quantityRequired");
    if (!formData.location)
      errors.location = t("manage_product.locationRequired");
    if (!formData.province)
      errors.province = t("manage_product.locationRequired");

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    Object.keys(formData).forEach((key) => {
      if (key === "uploaded_images" && formData[key].length > 0) {
        formData[key].forEach((file) => {
          productData.append("uploaded_images", file);
        });
      } else if (key === "color") {
        formData[key].forEach((color) => productData.append("color", color)); // Append each color
      } else {
        productData.append(key, formData[key]);
        productData.append("user", user?.user?.id);
      }
    });

    handleAdd(productData);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: "" });
  };

  const handleImageChange = (event) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);

      setFormData({
        ...formData,
        uploaded_images: files,
      });

      const urls = files.map((file) => URL.createObjectURL(file));
      setImagePreview(urls);
    }
  };

  const handleCropComplete = (croppedBlob, index) => {
    const updatedPreviews = [...imagePreview];
    const updatedImages = [...formData.uploaded_images];

    const originalFile = formData.uploaded_images[index];
    const originalExtension = originalFile.name.split(".").pop();
    const originalFilename = originalFile.name
      .split(".")
      .slice(0, -1)
      .join(".");

    const croppedFile = new File(
      [croppedBlob],
      `${originalFilename}.${originalExtension}`,
      { type: originalFile.type }
    );

    const croppedImageURL = URL.createObjectURL(croppedFile);

    if (index >= 0 && index < updatedPreviews.length) {
      updatedPreviews[index] = croppedImageURL;
      updatedImages[index] = croppedFile;
    }

    setImagePreview(updatedPreviews);
    setFormData({
      ...formData,
      uploaded_images: updatedImages,
    });

    setImageSrc(null);
  };

  const handleCancelCrop = () => {
    setImageSrc(null);
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imagePreview.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + imagePreview.length) % imagePreview.length
    );
  };
  const colorOptions = [
    t("product.red"),
    t("product.blue"),
    t("product.green"),
    t("product.yellow"),
    t("product.black"),
    t("product.white"),
    t("product.orange"),
    t("product.purple"),
    t("product.pink"),
    t("product.brown"),
    t("product.gray"),
    t("product.violet"),
    t("product.cyan"),
    t("product.magenta"),
    t("product.turquoise"),
    t("product.lavender"),
    t("product.maroon"),
    t("product.navy"),
    t("product.olive"),
    t("product.teal"),
    t("product.gold"),
    t("product.silver"),
    t("product.beige"),
    t("product.coral"),
    t("product.mint"),
    t("product.peach"),
    t("product.other"),
  ];

  return (
    <Box m="20px">
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <Grid spacing={4} container>
          <Grid sm={4} xs={12} item>
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label={t("manage_product.productName")}
              name="name"
              value={formData.name}
              error={!!formErrors.name}
              helperText={formErrors.name}
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            />
          </Grid>
          <Grid sm={4} xs={12} item>
            <TextField
              select
              fullWidth
              variant="filled"
              type="text"
              label={t("manage_product.category")}
              name="category"
              value={formData.category}
              error={!!formErrors.category}
              helperText={formErrors.category}
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            >
              {categories &&
                categories.map((category) => (
                  <MenuItem value={category.id}>{category.name}</MenuItem>
                ))}
            </TextField>
          </Grid>

          <Grid sm={4} xs={12} item>
            <TextField
              fullWidth
              variant="filled"
              type="number"
              label={t("manage_product.quantity")}
              name="quantity"
              value={formData.quantity}
              error={!!formErrors.quantity}
              helperText={formErrors.quantity}
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            />
          </Grid>

          <Grid xs={12} item>
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label={t("manage_product.description")}
              name="description"
              value={formData.description}
              error={!!formErrors.description}
              helperText={formErrors.description}
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
              multiline
              rows={2}
            />
          </Grid>
          <Grid xs={12} sm={4} item>
            <TextField
              fullWidth
              variant="filled"
              type="number"
              label={t("manage_product.price")}
              name="price"
              value={formData.price}
              error={!!formErrors.price}
              helperText={formErrors.price}
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            />
          </Grid>
          <Grid xs={12} sm={4} item>
            <TextField
              fullWidth
              variant="filled"
              type="number"
              label={t("manage_product.wholePrice")}
              name="whole_price"
              value={formData.whole_price}
              error={!!formErrors.whole_price}
              helperText={formErrors.whole_price}
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            />
          </Grid>

          <Grid xs={12} sm={4} item>
            <TextField
              fullWidth
              variant="filled"
              type="number"
              label={t("manage_product.wholePriceCount")}
              name="whole_price_count"
              value={formData.whole_price_count}
              error={!!formErrors.whole_price_count}
              helperText={formErrors.whole_price_count}
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            />
          </Grid>

          <Grid sm={4} xs={12} item>
            <TextField
              select
              fullWidth
              variant="filled"
              type="text"
              label={t("manage_product.currency")}
              name="currency"
              value={formData.currency}
              error={!!formErrors.currency}
              helperText={formErrors.currency}
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            >
              <MenuItem value="1">{t("manage_product.afg")}</MenuItem>
              <MenuItem value="2">{t("manage_product.usd")}</MenuItem>
            </TextField>
          </Grid>
          <Grid sm={4} xs={12} item>
            <TextField
              fullWidth
              variant="filled"
              type="number"
              label={t("manage_product.discount")}
              name="discount"
              value={formData.discount}
              error={!!formErrors.discount}
              helperText={formErrors.discount}
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
              InputProps={{
                min: 0,
                max: 100,
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid sm={4} xs={12} item>
            <TextField
              select
              fullWidth
              variant="filled"
              type="text"
              label={t("manage_product.size")}
              name="size"
              value={formData.size}
              error={!!formErrors.size}
              helperText={formErrors.size}
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            >
              <MenuItem value="XS"> {t("product.xs")}</MenuItem>
              <MenuItem value="S"> {t("product.s")}</MenuItem>
              <MenuItem value="M"> {t("product.m")}</MenuItem>
              <MenuItem value="L"> {t("product.l")}</MenuItem>
              <MenuItem value="XL"> {t("product.xl")}</MenuItem>
              <MenuItem value="XXL"> {t("product.xxl")}</MenuItem>
            </TextField>
          </Grid>
          <Grid sm={4} xs={12} item>
            <Autocomplete
              multiple
              options={colorOptions || []}
              value={formData.color || []} // Ensure the value is always an array
              onChange={(event, newValue) => {
                setFormData({ ...formData, color: newValue });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t("manage_product.selectColor")}
                  placeholder={t("manage_product.chooseColor")}
                />
              )}
            />
          </Grid>
          <Grid xs={12} sm={4} item>
            <Autocomplete
              options={provinces}
              getOptionLabel={(option) => option.label || ""} // Ensure a string is returned
              value={
                provinces.find(
                  (province) => province.id === formData.province
                ) || null
              } // Ensure the value matches an option
              onChange={(event, newValue) => {
                setFormData({
                  ...formData,
                  province: newValue ? newValue.id : null,
                }); // Update with the selected province's id
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Province"
                  variant="outlined"
                  error={!!formErrors.province}
                  helperText={formErrors.province}
                />
              )}
              isOptionEqualToValue={(option, value) => option.id === value?.id} // Proper equality check
            />
          </Grid>

          <Grid sm={4} xs={12} item>
            <TextField
              select
              fullWidth
              variant="filled"
              type="text"
              label={t("manage_product.location")}
              name="location"
              value={formData.location}
              error={!!formErrors.location}
              helperText={formErrors.location}
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            >
              <MenuItem value={1}>
                {t("manage_product.asanbawarStock")}
              </MenuItem>
              <MenuItem value={2}>{t("manage_product.sellerShop")}</MenuItem>
            </TextField>
          </Grid>

          <Grid sm={4} xs={12} item>
            <FormControl>
              <FormLabel>{t("manage_product.doYouWant")}</FormLabel>
              <RadioGroup
                name="is_visible_seller_info"
                value={formData.is_visible_seller_info}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    is_visible_seller_info: e.target.value === "true",
                  });
                }}
                row
              >
                <FormControlLabel
                  value={true}
                  label={t("manage_product.yes")}
                  control={<Radio />}
                />

                <FormControlLabel
                  value={false}
                  label={t("manage_product.no")}
                  control={<Radio />}
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid xs={12} item>
            <Grid align="center" xs={12} item>
              <input
                type="file"
                accept="image/*"
                id="file-upload"
                name="images"
                onChange={handleImageChange}
                style={{ display: "none" }}
                multiple
              />
              <label htmlFor="file-upload">
                <Button variant="contained" color="secondary" component="span">
                  {t("manage_product.chooseFile")}
                </Button>
              </label>
            </Grid>

            <Grid xs={12} item mt={2}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={prevSlide} disabled={currentIndex === 0}>
                  <ArrowBack />
                </IconButton>
                <Box
                  sx={{
                    display: "flex",
                    overflowX: "hidden",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      transform: `translateX(-${currentIndex * 100}px)`,
                      transition: "transform 0.5s ease-in-out",
                    }}
                  >
                    {imagePreview.length > 0 &&
                      imagePreview.map((image, index) => (
                        <Box
                          key={index}
                          sx={{
                            width: "12rem",
                            height: "12rem",
                            cursor: "pointer",
                            border: "1px solid grey",
                            borderRadius: "8px",
                            overflow: "hidden",
                            marginRight: 2,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: "0.5rem",
                          }}
                        >
                          <img
                            src={image}
                            alt={`Preview ${index}`}
                            style={{
                              width: "100%",
                              height: "80%",
                              objectFit: "cover",
                            }}
                          />
                          <Button
                            variant="contained"
                            color="secondary"
                            sx={{ textTransform: "capitalize", width: "90%" }}
                            onClick={() => setImageSrc(image)}
                          >
                            {t("manage_product.crop")}
                          </Button>
                        </Box>
                      ))}
                  </Box>
                </Box>
                <IconButton
                  onClick={nextSlide}
                  disabled={currentIndex === imagePreview.length - 1}
                >
                  <ArrowForward />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Grid item xs={12} sx={{ mt: 2 }}>
              {imageSrc && (
                <Paper sx={{ padding: "10px" }}>
                  <ImageCropper
                    imageSrc={imageSrc}
                    onCropComplete={(croppedImage) =>
                      handleCropComplete(croppedImage, currentIndex)
                    }
                    onCancel={handleCancelCrop}
                    index={currentIndex}
                  />
                </Paper>
              )}
            </Grid>

            {croppedImage && (
              <Box sx={{ mt: 2 }}>
                <img
                  src={croppedImage}
                  alt="Cropped"
                  style={{ width: "400px", height: "auto", objectFit: "cover" }}
                />
              </Box>
            )}
          </Grid>
          <Grid xs={12} align="right" item>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
            >
              {loading
                ? t("manage_product.submitting")
                : t("manage_product.addProduct")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default AddProduct;
