// provinces.js

const provinces = [
  { id: 1, label: "Badakhshan" },
  { id: 2, label: "Badghis" },
  { id: 3, label: "Baghlan" },
  { id: 4, label: "Balkh" },
  { id: 5, label: "Bamyan" },
  { id: 6, label: "Daykundi" },
  { id: 7, label: "Farah" },
  { id: 8, label: "Faryab" },
  { id: 9, label: "Ghazni" },
  { id: 10, label: "Ghor" },
  { id: 11, label: "Helmand" },
  { id: 12, label: "Herat" },
  { id: 13, label: "Jawzjan" },
  { id: 14, label: "Kabul" },
  { id: 15, label: "Kandahar" },
  { id: 16, label: "Kapisa" },
  { id: 17, label: "Khost" },
  { id: 18, label: "Kunar" },
  { id: 19, label: "Kunduz" },
  { id: 20, label: "Laghman" },
  { id: 21, label: "Logar" },
  { id: 22, label: "Nangarhar" },
  { id: 23, label: "Nimroz" },
  { id: 24, label: "Nuristan" },
  { id: 25, label: "Paktika" },
  { id: 26, label: "Paktia" },
  { id: 27, label: "Panjshir" },
  { id: 28, label: "Parwan" },
  { id: 29, label: "Samangan" },
  { id: 30, label: "Sar-e Pol" },
  { id: 31, label: "Takhar" },
  { id: 32, label: "Uruzgan" },
  { id: 33, label: "Wardak" },
  { id: 34, label: "Zabul" },
];

export default provinces;
