import React, { useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import useFetchObjects from "../../../api/useFetchObjects";
import ErrorPage from "../../../components/errorPage/ErrorPage";
import { useAuth } from "../../../context/AuthContext";
import useDelete from "../../../api/useDelete";
import { useTranslation } from "react-i18next";

const FeaturedBrands = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const auth = useAuth();
  const token = auth?.user?.token;

  const { data, isLoading, isError } = useFetchObjects(
    ["featured-brands"],
    "featured-brands/",
    token
  );
  const { handleDelete, ConfirmDialog } = useDelete("featured-brands", token);

  // Transform the data to match the expected DataGrid format
  const rows =
    data?.map((item) => ({
      id: item?.id,
      name: item?.display_product?.name,
      category: item?.display_product?.category,
      price: item?.display_product?.price,
      whole_price: item?.display_product?.whole_price,
      currency: item?.display_product?.currency,
      quantity: item?.display_product?.whole_price_count,
      created_at: item?.created_at,
      discount: item?.display_product?.discount,
      images: item?.display_product?.images || [], // Fallback to an empty array if images is undefined
    })) || [];

  const columns = [
    { field: "id", headerName: t("manage_home.id"), flex: 0.5 },
    {
      field: "images",
      headerName: t("manage_home.photo"),
      align: "center",
      flex: 1.2,
      renderCell: (params) => {
        // Safely access the first image in the images array
        const imageUrl = params.value.length > 0 ? params.value[0]?.image : "";
        return (
          <img
            src={imageUrl}
            alt="Product"
            style={{ width: "5rem", height: "auto", padding: "4px" }}
          />
        );
      },
    },
    {
      field: "name",
      headerName: t("manage_home.productName"),
      flex: 1,
      cellClassName: "name-column--cell",
    },

    {
      field: "price",
      headerName: t("manage_home.price"),
      renderCell: (params) => (
        <div>
          {params?.row?.currency === 1
            ? `${params?.row?.price} ؋`
            : `${params?.row?.price} $`}
        </div>
      ),
      flex: 1,
    },

    {
      field: "whole_price",
      headerName: t("manage_home.wholePrice"),
      renderCell: (params) => (
        <div>
          {params?.row?.currency === 1
            ? `${params?.row?.whole_price} ؋`
            : `${params?.row?.whole_price} $`}
        </div>
      ),
      flex: 1,
    },

    {
      field: "quantity",
      headerName: t("manage_home.quantity"),
      flex: 1,
    },
    {
      field: "created_at",
      headerName: t("manage_home.created"),
      flex: 1,
      renderCell: (params) => (
        <div>
          <span>{params.row.created_at?.split("T")[0]}</span>
        </div>
      ),
    },
    {
      field: "discount",
      headerName: t("manage_home.discount"),
      renderCell: (params) => (
        <div>
          <span>{params.row.discount}</span>
          <span>%</span>
        </div>
      ),
      flex: 1,
    },
    {
      field: "actions",
      headerName: t("manage_home.actions"),
      flex: 2,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              handleDelete(params.id);
            }}
          >
            {t("manage_home.delete")}
          </Button>
        </Stack>
      ),
    },
  ];

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <Box
      m="40px 0 0 0"
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        rowHeight={80}
        components={{ Toolbar: GridToolbar }}
        loading={isLoading}
        error={isError}
      />
      <ConfirmDialog />
    </Box>
  );
};

export default FeaturedBrands;
