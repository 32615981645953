import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import "./admin/dashboardIndex.css";

import { BrowserRouter } from "react-router-dom";
import ContextProvider from "./context/ContextProvider";
import IndexRouter from "./Routes/IndexRouter";
import { AuthProvider } from "./context/AuthContext";
import * as ServiceWorkerRegistration from "./serviceWorkerRegistration";

// Import ToastContainer from react-toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./lang/i18n";
import ScrollToTop from './Routes/ScrollToTop'
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ContextProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <ScrollToTop />
            <IndexRouter />
          </BrowserRouter>
        </QueryClientProvider>
      </ContextProvider>
    </AuthProvider>

    {/* Place ToastContainer outside of the providers */}
    <ToastContainer />
  </React.StrictMode>
);

ServiceWorkerRegistration.register();
