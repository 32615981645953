import { Grid, TextField, InputAdornment } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import Context from "../../../context/Context";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Search = () => {
  const { t } = useTranslation();
  const { searchTerm, setSearchTerm } = useContext(Context); // Destructure from context
  const [timeoutId, setTimeoutId] = useState(null); // State to store the timeout ID
  const navigate = useNavigate(); // Hook for navigation

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value); // Update the context with the new search term

    // Clear the previous timeout if it exists
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout to redirect after a delay (e.g., 1000 milliseconds)
    const id = setTimeout(() => {
      if (value.trim()) {
        navigate(`/products`); // Redirect to products page with search query
      }
    }, 500); // 1 second delay

    setTimeoutId(id); // Store the timeout ID
  };

  useEffect(() => {
    // Cleanup function to clear timeout on unmount
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          fullWidth
          type="search"
          variant="outlined"
          placeholder={t("navbar.search")}
          value={searchTerm} // Bind the value of the search box to the searchTerm
          onChange={handleSearchChange} // Use the updated change handler
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px", // Professional rounded corners
              backgroundColor: "#f2f5f3", // Light gray background for modern look
              "&.Mui-focused": {
                backgroundColor: "#ffffff", // White background when focused
                borderColor: "#1976d2", // Blue border when focused
              },
            },
            "& .MuiInputBase-input": {
              padding: "8px 12px", // Add some padding for better spacing
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "#1976d2" }} />{" "}
                {/* Professional search icon */}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Search;
