import {
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import { useContext, useState } from "react";
import React from "react";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import Header from "../../components/Header";
import useFetchObjects from "../../../api/useFetchObjects";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../../lang/LanguageSelector";

const iconStyle = {
  color: "#ffff", // White for professionalism to match the dark background
  ":hover": {
    cursor: "pointer",
    color: "#FFB400", // Hover effect matches subscribe button color
  },
};

const Topbar = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();
  const auth = useAuth();
  const user = auth?.user;
  const token = auth?.user?.token;
  const [anchorEl, setAnchorEl] = useState(null);

  const { data: totalNotification } = useFetchObjects(
    ["notification"],
    `unread-order-count/?user_id=${user?.user?.id}`
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    auth.logout();
    navigate("/login");
    handleClose();
  };

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      <Box></Box>

      <Box display="flex" alignItems={"center"}>
        <Link
          to={"/"}
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            color: theme.palette.mode === "dark" ? "white" : "black",
            marginRight: "4px",
          }}
        >
          {t("global.home")}
        </Link>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <Badge
          badgeContent={totalNotification?.unread_count}
          color="error"
          sx={{ mr: 1 }}
          onClick={() => {
            navigate("/notification");
          }}
        >
          <NotificationsIcon sx={iconStyle} />
        </Badge>
        <LanguageSelector />
        <Box>
          <IconButton onClick={handleClick}>
            <PersonOutlinedIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl} // Anchor element for positioning
            open={Boolean(anchorEl)} // Open state based on anchor element
            onClose={handleClose} // Close handler
          >
            <MenuItem component={Link} onClick={handleLogout}>
              {t("global.logout")}
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/profile");
              }}
            >
              {t("global.profile")}
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

export default Topbar;
