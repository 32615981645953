import React, {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Drawer,
  IconButton,
  ListSubheader,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import useFetchObjects from "../../../api/useFetchObjects"; // Move this to the top
import ErrorPage from "../../../components/errorPage/ErrorPage"; // Move this to the top
import { useLocation } from "react-router-dom"; // Move this to the top
import Context from "../../../context/Context"; // Move this to the top
import CardsSkeleton from "./loading/CardsSkeleton"; // Move this to the top
import NoData from "../../../components/noData/NoData"; // Move this to the top
import { useTranslation } from "react-i18next";
import SellerProfile from "../sellerProfile/SellerProfile";
import InfiniteScroll from "react-infinite-scroll-component";
const Cards = lazy(() => import("./Cards")); // Lazy import should remain here

const Products = () => {
  const { t } = useTranslation();

  const location = useLocation(); // For category filtering and user info
  const { state } = location || {};
  const userInfo = state?.userInfo || null;
  const userID = userInfo?.id || ""; // Ensure userID is properly set

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [allProducts, setAllProducts] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const [noDataMessage, setNoDataMessage] = useState(null);
  //  for category flirting

  const catID = state?.category || "";
  const [openList, setOpenList] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filters, setFilters] = useState({ color: [], size: [], category: [] });
  const { searchTerm } = useContext(Context);

  const color = filters.color.join(",") || "";
  const size = filters.size.join(",") || "";
  const category = filters.category.join(",") || "";

  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["ProductsList", page],
    `products/?color=${color}&size=${size}&category=${
      category || catID
    }&search=${searchTerm}&user=${
      userID || ""
    }&user_verified=true&page=${page}&page_size=${rowsPerPage}`
  );

  const { data: categoriesData } = useFetchObjects(
    ["CategoriesList"],
    "categories/"
  );

  // Reset products and page when filters, searchTerm, or userID changes
  useEffect(() => {
    setAllProducts([]);
    setPage(1); // Reset to the first page
  }, [filters, searchTerm, userID]);

  useEffect(() => {
    if (searchTerm || filters || page) {
      refetch();
    }
  }, [filters, searchTerm, page]);

  useEffect(() => {
    setTimeout(() => {
      refetch();
    }, 2500);
  }, [page]);

  // Ensure data is defined before using it
  useEffect(() => {
    if (data && data.results) {
      if (allProducts.length + rowsPerPage >= data.count) {
        setHasMore(false);
        setNoDataMessage("There is no more product");
      }

      const newData = [...allProducts, ...data.results];
      setAllProducts(newData);
    }
  }, [data]); // Only runs when `data` changes

  useEffect(() => {
    refetch();
  }, [userID]);

  const handleClick = (listItem) => {
    setOpenList((prevOpenList) => ({
      ...prevOpenList,
      [listItem]: !prevOpenList[listItem],
    }));
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  // Modify handleFilterChange to allow for multiple selections
  const handleFilterChange = (event, filterType) => {
    const { value } = event.target;
    const currentFilters = filters[filterType];

    if (filterType === "category") {
      const numericValue = Number(value); // Convert to number for categories
      if (currentFilters.includes(numericValue)) {
        setFilters((prevFilters) => ({
          ...prevFilters,
          [filterType]: currentFilters.filter((item) => item !== numericValue),
        }));
      } else {
        setFilters((prevFilters) => ({
          ...prevFilters,
          [filterType]: [...currentFilters, numericValue],
        }));
      }
    } else {
      // For color and size (which are strings)
      if (currentFilters.includes(value)) {
        setFilters((prevFilters) => ({
          ...prevFilters,
          [filterType]: currentFilters.filter((item) => item !== value),
        }));
      } else {
        setFilters((prevFilters) => ({
          ...prevFilters,
          [filterType]: [...currentFilters, value],
        }));
      }
    }
  };

  const colors = [
    t("product.red"),
    t("product.blue"),
    t("product.green"),
    t("product.yellow"),
    t("product.black"),
    t("product.white"),
    t("product.orange"),
    t("product.purple"),
    t("product.pink"),
    t("product.brown"),
    t("product.gray"),
    t("product.violet"),
    t("product.cyan"),
    t("product.magenta"),
    t("product.turquoise"),
    t("product.lavender"),
    t("product.maroon"),
    t("product.navy"),
    t("product.olive"),
    t("product.teal"),
    t("product.gold"),
    t("product.silver"),
    t("product.beige"),
    t("product.coral"),
    t("product.mint"),
    t("product.peach"),
    t("product.other"),
  ];
  const sizes = [
    { id: 1, name: "XS", fname: t("product.xs") },
    { id: 2, name: "S", fname: t("product.s") },
    { id: 3, name: "M", fname: t("product.m") },
    { id: 4, name: "L", fname: t("product.l") },
    { id: 5, name: "XL", fname: t("product.xl") },
    { id: 6, name: "XXL", fname: t("product.xxl") },
  ];

  const FilterList = () => (
    <List
      sx={{ width: "100%", bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          sx={{ fontWeight: "bold", fontSize: "1.2em" }}
          component="div"
          id="nested-list-subheader"
        >
          {t("product.filter")}
        </ListSubheader>
      }
    >
      {/* Filter by Color */}
      <ListItemButton onClick={() => handleClick("color")}>
        <ListItemText primary={t("product.color")} />
        {openList["color"] ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openList["color"]} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Box sx={{ pl: 4 }}>
            <FormGroup>
              {colors.map((color) => (
                <FormControlLabel
                  key={color}
                  control={
                    <Checkbox
                      value={color}
                      checked={filters?.color?.includes(color)} // Check if the color is selected
                      onChange={(e) => handleFilterChange(e, "color")}
                    />
                  }
                  label={color}
                />
              ))}
            </FormGroup>
          </Box>
        </List>
      </Collapse>

      {/* Filter by Size */}
      <ListItemButton onClick={() => handleClick("size")}>
        <ListItemText primary={t("product.size")} />
        {openList["size"] ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openList["size"]} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Box sx={{ pl: 4 }}>
            <FormGroup>
              {sizes.map((size) => (
                <FormControlLabel
                  key={size.id}
                  control={
                    <Checkbox
                      value={size.name}
                      checked={filters.size.includes(size.name)} // Check if the size is selected
                      onChange={(e) => handleFilterChange(e, "size")}
                    />
                  }
                  label={`${size.fname}`}
                />
              ))}
            </FormGroup>
          </Box>
        </List>
      </Collapse>

      {/* Filter by Category */}
      <ListItemButton onClick={() => handleClick("category")}>
        <ListItemText primary={t("product.category")} />
        {openList["category"] ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openList["category"]} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Box sx={{ pl: 4 }}>
            <FormGroup>
              {categoriesData &&
                categoriesData.map((category) => (
                  <FormControlLabel
                    key={category.id}
                    control={
                      <Checkbox
                        value={category.id} // Keep as numeric value
                        checked={filters.category.includes(category.id)} // Check if category ID is selected
                        onChange={(e) => handleFilterChange(e, "category")}
                      />
                    }
                    label={category.name}
                  />
                ))}
            </FormGroup>
          </Box>
        </List>
      </Collapse>
    </List>
  );

  return (
    <Grid
      container
      maxWidth={"xl"}
      justifyContent={"center"}
      color={"text.secondary"}
      padding={"0em 1em"}
      mb={7}
    >
      <Grid
        container
        justifyContent={"center"}
        sx={{ width: { xs: "100%", sm: "90%", md: "100%", lg: "90%" } }}
        mt={1}
      >
        {userInfo && (
          <Grid container>
            <SellerProfile userInfo={userInfo} />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          mb={2}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <FilterList />
        </Grid>

        {/* Drawer for mobile view */}
        <Grid
          item
          xs={12}
          md={2}
          sx={{ display: { xs: "block", md: "none" } }}
          textAlign="left"
        >
          <IconButton
            edge="end"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
          <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
            <FilterList />
          </Drawer>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={10}
          sx={{ display: "flex", justifyContent: "center", mt: 2 }}
        >
          <Suspense fallback={<CardsSkeleton />}>
            {isError ? (
              <ErrorPage />
            ) : (
              <Grid container justifyContent={"center"} spacing={2}>
                <Cards products={allProducts} />

                <InfiniteScroll
                  dataLength={allProducts?.length}
                  next={() => setPage(page + 1)}
                  hasMore={hasMore}
                  loader={
                    <Grid container padding={"10px"}>
                      <CircularProgress />
                    </Grid>
                  }
                />
                {noDataMessage && (
                  <Typography mt={2}>{noDataMessage}</Typography>
                )}
              </Grid>
            )}
          </Suspense>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Products;
