import React from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Avatar,
  Divider,
  Paper,
  Alert,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import useFetchObject from "../../../api/useFetchObject";
import { useAuth } from "../../../context/AuthContext";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";

const Details = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;
  const { id } = useParams();

  const { data, isLoading, isError } = useFetchObject(
    ["userDetails"],
    "admin/users",
    id,
    token
  );

  if (isLoading) {
    return (
      <Grid container justifyContent="center" mt={3}>
        <Typography color={colors.grey[100]}>
          {t("manage_user.loading")}
        </Typography>
      </Grid>
    );
  }

  if (isError) {
    return (
      <Grid container justifyContent="center" mt={3}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {t("manage_user.failedLoad")}
        </Alert>
      </Grid>
    );
  }

  return (
    <Box m="20px">
      <Header
        title={t("manage_user.userDetails")}
        subtitle={`${t("manage_user.detailsFor")} ${data?.username}`}
      />

      <Box
        mt={3}
        p={3}
        backgroundColor={colors.primary[400]}
        borderRadius="8px"
      >
        <Paper
          elevation={3}
          sx={{
            backgroundColor: colors.primary[500],
            padding: { xs: "15px", md: "20px" },
            borderRadius: "8px",
          }}
        >
          {/* Profile Image and Basic Info */}
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={3} textAlign="center">
              <Avatar
                alt={data?.username}
                src={data?.image || "https://via.placeholder.com/150"}
                sx={{
                  width: { xs: 100, md: 150 },
                  height: { xs: 100, md: 150 },
                  mx: "auto",
                }}
              />
            </Grid>

            <Grid item xs={12} md={9}>
              <Typography variant="h5" color={colors.grey[100]} mb={2}>
                {t("manage_user.personalInformation")}
              </Typography>
              <Divider sx={{ backgroundColor: colors.grey[700] }} />

              <Grid container spacing={3} mt={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" color={colors.grey[100]}>
                    {t("manage_user.username")}:
                  </Typography>
                  <Typography variant="body1" color={colors.grey[100]}>
                    {data?.username}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" color={colors.grey[100]}>
                    {t("manage_user.firstName")}:
                  </Typography>
                  <Typography variant="body1" color={colors.grey[100]}>
                    {data?.first_name}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" color={colors.grey[100]}>
                    {t("manage_user.lastName")}:
                  </Typography>
                  <Typography variant="body1" color={colors.grey[100]}>
                    {data?.last_name}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" color={colors.grey[100]}>
                    {t("manage_user.gender")}:
                  </Typography>
                  <Typography variant="body1" color={colors.grey[100]}>
                    {data?.gender === 1
                      ? t("manage_user.male")
                      : t("manage_user.female")}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" color={colors.grey[100]}>
                    {t("manage_user.email")}:
                  </Typography>
                  <Typography variant="body1" color={colors.grey[100]}>
                    {data?.email}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" color={colors.grey[100]}>
                    {t("manage_user.phoneNumber")}:
                  </Typography>
                  <Typography variant="body1" color={colors.grey[100]}>
                    {data?.phone_number}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" color={colors.grey[100]}>
                    {t("manage_user.tazkiraNumber")}:
                  </Typography>
                  <Typography variant="body1" color={colors.grey[100]}>
                    {data?.tazkira_number}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" color={colors.grey[100]}>
                    {t("manage_user.shopName")}:
                  </Typography>
                  <Typography variant="body1" color={colors.grey[100]}>
                    {data?.shop_name || t("manage_user.noProv")}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" color={colors.grey[100]}>
                    {t("manage_user.shopAddress")}:
                  </Typography>
                  <Typography variant="body1" color={colors.grey[100]}>
                    {data?.shop_address || "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {/* Account Information */}
        <Paper
          elevation={3}
          sx={{
            backgroundColor: colors.primary[500],
            padding: { xs: "15px", md: "20px" },
            borderRadius: "8px",
            mt: 3,
          }}
        >
          <Typography variant="h5" color={colors.grey[100]} mb={2}>
            {t("manage_user.accountInformation")}
          </Typography>
          <Divider sx={{ backgroundColor: colors.grey[700] }} />
          <Grid container spacing={3} mt={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" color={colors.grey[100]}>
                {t("manage_user.verifiedSeller")}:
              </Typography>
              <Typography variant="body1" color={colors.grey[100]}>
                {data?.verified ? t("manage_user.yes") : t("manage_user.no")}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="h6" color={colors.grey[100]}>
                {t("manage_user.emailVerified")}:
              </Typography>
              <Typography variant="body1" color={colors.grey[100]}>
                {data?.is_email_verified
                  ? t("manage_user.yes")
                  : t("manage_user.no")}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="h6" color={colors.grey[100]}>
                {t("manage_user.mobileVerified")}:
              </Typography>
              <Typography variant="body1" color={colors.grey[100]}>
                {data?.is_mobile_verified
                  ? t("manage_user.yes")
                  : t("manage_user.no")}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="h6" color={colors.grey[100]}>
                {t("manage_user.accountType")}:
              </Typography>
              <Typography variant="body1" color={colors.grey[100]}>
                {data?.is_seller
                  ? t("manage_user.seller")
                  : t("manage_user.buyer")}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="h6" color={colors.grey[100]}>
                {t("manage_user.userVerifiedUp")}:
              </Typography>
              <Typography variant="body1" color={colors.grey[100]}>
                {data?.temporary_verification_expiration}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="h6" color={colors.grey[100]}>
                {t("manage_user.description")}:
              </Typography>
              <Typography variant="body1" color={colors.grey[100]}>
                {data?.description}
              </Typography>
            </Grid>
          </Grid>
        </Paper>

        {/* Documents */}
        <Paper
          elevation={3}
          sx={{
            backgroundColor: colors.primary[500],
            padding: { xs: "15px", md: "20px" },
            borderRadius: "8px",
            mt: 3,
          }}
        >
          <Typography variant="h5" color={colors.grey[100]} mb={2}>
            {t("manage_user.documents")}
          </Typography>
          <Divider sx={{ backgroundColor: colors.grey[700] }} />
          <Grid container spacing={3} mt={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" color={colors.grey[100]}>
                {t("manage_user.tazkiraCopy")}:
              </Typography>
              {data?.tazkira_copy ? (
                <Button
                  variant="contained"
                  color="secondary"
                  href={data?.tazkira_copy}
                  target="_blank"
                  sx={{ mt: 1 }}
                >
                  {t("manage_user.viewTazkira")}
                </Button>
              ) : (
                <Typography variant="body1" color={colors.grey[100]}>
                  {t("manage_user.noTazkira")}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="h6" color={colors.grey[100]}>
                {t("manage_user.workLicenseCopy")}:
              </Typography>
              {data?.work_license_copy ? (
                <Button
                  variant="contained"
                  color="secondary"
                  href={data?.work_license_copy}
                  target="_blank"
                  sx={{ mt: 1 }}
                >
                  {t("manage_user.viewLicense")}
                </Button>
              ) : (
                <Typography variant="body1" color={colors.grey[100]}>
                  {t("manage_user.noLicense")}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mt: "1em", textAlign: "center" }}>
              <Button
                component={Link}
                to="/dashboard/users"
                variant="contained"
                color="primary"
                style={{
                  color: "white",
                  backgroundColor: "green",
                  height: "5vh",
                  fontWeight: "bold",
                }}
              >
                {t("manage_user.backList")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
};

export default Details;
