import { Grid, Typography, Paper, Avatar } from "@mui/material";
import React from "react";

const SellerProfile = ({ userInfo }) => {
  return (
    <Grid
      container
      justifyContent={"center"}
      sx={{
        color: "textSecondary",
        bgcolor: "#f5f5f5",
        borderRadius: "10px",
        boxShadow: "0px 0px 2px 2px #c5c6c9",
        mt: 1,
      }}
      spacing={2}
      mb={2}
      padding={"20px 0px"}
    >
      <Grid item xs={12} md={3} textAlign={"center"}>
        <Avatar
          alt={userInfo?.first_name}
          src="/path/to/avatar.jpg"
          style={{ width: "100px", height: "100px", margin: "auto" }}
        />
        <Typography variant="h6" gutterBottom>
          {" "}
          {userInfo?.first_name !== "" || userInfo?.last_name !== "" ? (
            <>{`${userInfo?.first_name} ${userInfo?.last_name}`} </>
          ) : (
            userInfo?.username
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography variant="h6" gutterBottom>
          Personal Info
        </Typography>
        <Typography variant="body2">
          <strong>Gender:</strong> {userInfo?.gender === 1 ? "Male" : "Female"}
        </Typography>

        {userInfo?.is_visible_seller_info ||
          (userInfo?.is_staff && (
            <>
              <Typography variant="body2">
                {" "}
                <strong>email:</strong>
                {userInfo?.email}
              </Typography>
              <Typography variant="body2">
                {" "}
                <strong>Phone number:</strong>
                {userInfo?.phone_number}
              </Typography>
            </>
          ))}
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography variant="h6" gutterBottom>
          Shop Info
        </Typography>
        <Typography variant="body2">
          <strong>Shop Name:</strong> {userInfo?.shop_name}
        </Typography>
        <Typography variant="body2">
          <strong>Shop Address:</strong> {userInfo?.shop_address}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SellerProfile;
