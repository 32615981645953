import React from "react";
import { Box, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

const NoMessage = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        padding: "0px 10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          backgroundColor: "#e3f2fd",
        }}
      >
        <Typography variant="h6" color="textSecondary">
          {t("chat.message")}
        </Typography>
      </Box>
    </Box>
  );
};

export default NoMessage;
