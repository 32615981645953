import React from "react";
import { Route, Routes } from "react-router-dom";
import ShoppingCart from "../../web/pages/products/shoppingCart/ShoppingCart";

const ShoppingCartRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ShoppingCart />} />
    </Routes>
  );
};

export default ShoppingCartRoutes;