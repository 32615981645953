import React, { useState } from "react";
import { Box, IconButton, MenuItem, Popover, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import LanguageIcon from "@mui/icons-material/Language";

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: "dr",
    label: "dari",
    icon: `${process.env.REACT_APP_IMAGE_URL}images/flags/Afg.png`,
  },
  {
    value: "ps",
    label: "pashto",
    icon: `${process.env.REACT_APP_IMAGE_URL}images/flags/Afg.png`,
  },
  {
    value: "en",
    label: "english",
    icon: `${process.env.REACT_APP_IMAGE_URL}images/flags/US.png`,
  },
];

// ----------------------------------------------------------------------

const LanguageSelector = () => {
  const [open, setOpen] = useState(null);
  const { t, i18n } = useTranslation();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const setLanguage = (value) => {
    setOpen(null);
    i18n.changeLanguage(value);
    const direction = value === "en" ? "ltr" : "rtl";
    localStorage.setItem("direction", direction);
    document.body.setAttribute("dir", direction);
  };

  const close = () => {
    setOpen(null);
  };

  return (
    <Box>
      <IconButton
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
        }}
      >
        <LanguageIcon sx={{ color: "white" }} fontSize="small" />
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={close}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 180,
          },
        }}
      >
        {LANGS.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => setLanguage(option.value)}
            sx={{ typography: "body2", py: 1 }}
          >
            <Box
              component="img"
              alt={option.label}
              src={option.icon}
              sx={{ width: 28 }}
            />
            <Typography sx={{ m: "0px 10px" }}>
              {t(`language.${option.label}`)}
            </Typography>
          </MenuItem>
        ))}
      </Popover>
    </Box>
  );
};

export default LanguageSelector;
