import React, { useEffect, useId, useState } from "react";
import {
  Box,
  TextField,
  IconButton,
  Typography,
  Avatar,
  Paper,
  Divider,
  List,
  ListItem,
  InputAdornment,
  Grid,
  Button,
  CircularProgress,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import "./style.css";
import SendIcon from "@mui/icons-material/Send";
import dayjs from "dayjs"; // For date formatting
import useFetchObjects from "../../../api/useFetchObjects";
import { useAuth } from "../../../context/AuthContext";
import useAdd from "../../../api/useAdd";
import ChatLoading from "./loading/ChatLoading";
import ChatError from "./error/ChatError";
import EditIcon from "@mui/icons-material/Edit"; // Import Edit icon
import DeleteIcon from "@mui/icons-material/Delete"; // Import Delete icon
import CancelIcon from "@mui/icons-material/Cancel";
import AttachFileIcon from "@mui/icons-material/AttachFile"; // Import an icon for attaching files;
import useUpdate from "../../../api/useUpdate";
import useDelete from "../../../api/useDelete";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import NoMessage from "./NoMessage";

const Chat = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const chatData = location?.state?.chatData;
  const navigate = useNavigate();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;
  const userID = user?.user?.id;
  const [files, setFiles] = useState([]); // Change to array for multiple files
  const [filePreviews, setFilePreviews] = useState([]); // Array for multiple file previews
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [formData, setFormData] = useState({
    user: userID,
    receiver: userID,
    sender: chatData?.receiver,
    message: "",
    product: chatData?.product?.id,
  });

  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["get-messages"],
    `get-messages/${chatData?.sender}/${chatData?.receiver}`,
    token
  );

  const { handleAdd, resetForm, loading } = useAdd(
    "send-messages",
    token,
    t("chat.sendMessage"),
    t("chat.sendField")
  );

  const {
    handleUpdate,
    loading: loadingEdit,
    isSuccess,
  } = useUpdate("update-message", token);

  const { handleDelete, ConfirmDialog } = useDelete("delete-message", token);

  const {
    handleDelete: handleDeleteImage,
    ConfirmDialog: ConfirmDialogDeleteImage,
  } = useDelete("delete-image", token);

  useEffect(() => {
    if (chatData) {
      refetch();
    }
  }, [chatData, resetForm]);

  useEffect(() => {
    if (resetForm) {
      setFormData({ ...formData, message: "", product: "" });
      setFiles([]); // Reset files
      setFilePreviews([]); // Reset file previews
    }
    if (isSuccess) {
      setEditingMessageId(false);
      setFormData({ ...formData, message: "" });
    }
  }, [resetForm, isSuccess]);

  useEffect(() => {
    if (chatData) {
      setFormData({
        ...formData,
        user: userID,
        receiver:
          chatData?.receiver === userID ? chatData?.sender : chatData?.receiver, // Adjust receiver dynamically
        sender: userID, // Always set the sender to the logged-in user
        product: chatData?.product?.id || "",
        message: "",
      });
    }
  }, [chatData]);

  useEffect(() => {
    const handleBackButton = () => {
      // Navigate to the chat page on back button press
      navigate("/chat");
    };

    // Listen for the popstate event when the back button is pressed
    window.addEventListener("popstate", handleBackButton);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate]);

  const handleEditMessage = (msg) => {
    setEditingMessageId(msg.id);
    setFormData({ ...formData, message: msg.message });
  };

  const handleSendMessage = (e) => {
    e.preventDefault(); // Prevent default form submission
    const submitData = new FormData();

    submitData.append("user", userID); // Always include the logged-in user's ID
    submitData.append(
      "receiver",
      chatData?.receiver === userID ? chatData?.sender : chatData?.receiver // Correct receiver based on the current chat
    );
    submitData.append("sender", userID); // Always set the sender to the logged-in user
    submitData.append("message", formData.message.trim());

    if (formData?.product) {
      submitData.append("product", formData.product);
    }

    // Append selected files (images)
    files.forEach((file) => {
      submitData.append("uploaded_images", file);
    });

    if (!formData?.message.trim() && files.length === 0) {
      return; // Prevent empty messages from being sent
    }

    if (editingMessageId) {
      handleUpdate(editingMessageId, submitData);
    } else {
      handleAdd(submitData);
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, message: e.target.value });
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFilePreviews((prevPreviews) => [
      ...prevPreviews,
      ...selectedFiles.map((file) => URL.createObjectURL(file)),
    ]);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]); // Append new files
  };

  const handleRemoveImage = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setFilePreviews((prevPreviews) =>
      prevPreviews.filter((_, i) => i !== index)
    );
  };

  if (isLoading) return <ChatLoading />;
  if (isError)
    return <ChatError message={t("chat.failedLoad")} onRetry={refetch} />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: { xs: "84vh", sm: "90vh", md: "80vh" },
      }}
    >
      {/* Chat Header */}
      <Box
        sx={{
          padding: { xs: 1, md: 2 },
          backgroundColor: "#1976d2",
          display: "flex",
          alignItems: "center",
          color: "#fff",
          justifyContent: "space-between",
        }}
      >
        <Box display="flex" alignItems="center">
          <Avatar
            alt={chatData?.userInfo?.image}
            src={chatData?.userInfo?.image}
          />
          <Typography
            variant="h6"
            sx={{
              marginLeft: 2,
              textTransform: "capitalize",
              fontSize: { xs: "14px", md: "20px" },
            }}
          >
            {chatData?.userInfo?.is_staff ? (
              t("chat.admin")
            ) : (
              <>
                {chatData?.userInfo?.first_name && chatData?.userInfo?.last_name
                  ? `${chatData?.userInfo?.first_name} ${chatData?.userInfo?.last_name}`
                  : chatData?.userInfo?.username}
              </>
            )}
          </Typography>
        </Box>

        <ArrowForwardIcon
          sx={{
            color: "white",
            cursor: "pointer",
            display: { xs: "block", md: "none" },
          }}
          onClick={() => {
            navigate("/chat");
          }}
        />
      </Box>

      {data?.length > 0 ? (
        <>
          {/* Chat Body */}
          <Box
            sx={{
              flexGrow: 1,
              padding: 2,
              backgroundColor: "#e3f2fd",
              display: "flex",
              flexDirection: "column-reverse",
              height: "100%",
              overflowY: "auto",
              overflowX: "hidden", // Prevent horizontal scrolling in the chat body
            }}
          >
            <List sx={{ padding: 0 }}>
              {data?.map((msg, index) => (
                <ListItem
                  key={index}
                  sx={{
                    justifyContent:
                      msg.sender === userID ? "flex-end" : "flex-start",
                    padding: "10px 0",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      maxWidth: { xs: "90%", md: "70%" },
                    }}
                  >
                    {msg.sender !== userID && (
                      <Avatar
                        alt={msg.receiver_profile.username}
                        src={msg.receiver_profile.image}
                        sx={{ marginRight: 1 }}
                      />
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems:
                          msg.sender === userID ? "flex-end" : "flex-start",
                        width: "100%",
                      }}
                    >
                      <Paper
                        sx={{
                          padding:
                            msg?.images?.length > 0 ? "5px 5px" : "12px 15px",
                          backgroundColor:
                            msg.sender === userID ? "#d0d9d8" : "#cef2ef",
                          color: "#171717",
                          borderRadius:
                            msg.sender === userID
                              ? "10px 10px 0 10px"
                              : "10px 10px 10px 0",
                          boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)",
                          wordWrap: "break-word",
                          maxWidth: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            whiteSpace: "pre-wrap",
                            fontSize: "14px",
                            lineHeight: 1.5,
                            color: "#0d111c",
                          }}
                        >
                          {msg.message}
                        </Typography>

                        {msg?.images?.length > 0 && (
                          <>
                            {msg?.images?.map((image, i) => (
                              <Box
                                key={i}
                                ml={1}
                                sx={{
                                  position: "relative",
                                  width: { xs: "60vw", md: "25vw" },
                                  height: { xs: "18vh", md: "25vh" },

                                  textAlign:
                                    msg.sender === userID ? "right" : "left",
                                }}
                              >
                                <img
                                  src={image.image}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    borderRadius: "10px",
                                    marginTop: "10px",
                                  }}
                                />
                                <Box
                                  sx={{
                                    backgroundColor:
                                      "rgba(251, 252, 252, 0.88)",
                                    borderRadius: "50%",
                                    boxShadow: "0px 0px 2px 1px 2px white",
                                    position: "absolute",
                                    bottom: "2px",
                                    left: "2px",
                                  }}
                                >
                                  {" "}
                                  <IconButton
                                    onClick={() => handleDeleteImage(image.id)}
                                    sx={{ color: "red" }}
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </Box>
                              </Box>
                            ))}
                          </>
                        )}

                        {/* Responsive Product Image */}
                        {msg?.product_info?.images?.length > 0 && (
                          <>
                            <Box
                              ml={1}
                              sx={{
                                position: "relative",
                                width: { xs: "60vw", md: "25vw" },
                                height: { xs: "18vh", md: "25vh" },
                                mb: 1,
                                textAlign:
                                  msg.sender === userID ? "right" : "left",
                              }}
                            >
                              {msg.product_info?.images
                                .slice(0, 1)
                                .map((image, idx) => (
                                  <img
                                    key={idx}
                                    src={image.image}
                                    alt={msg?.product_info?.name}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                      borderRadius: "10px",
                                      marginTop: "10px",
                                    }}
                                  />
                                ))}
                            </Box>

                            {/* Static Product Details */}
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: "bold", color: "#333" }}
                            >
                              {msg?.product_info?.name}
                            </Typography>
                            <Typography variant="body2" sx={{ color: "#555" }}>
                              {msg?.product_info?.description}
                            </Typography>
                            <Box display={"flex"} alignItems={"center"}>
                              <Typography
                                variant="body1"
                                sx={{
                                  fontWeight: "bold",
                                  color: "#000",
                                  mt: 1,
                                }}
                              >
                                {msg?.product_info?.price}{" "}
                                {msg?.product_info?.currency === 1 ? "؋" : "$"}
                              </Typography>
                              <div
                                style={{
                                  width: "20px",
                                  height: "4px",
                                  backgroundColor: "green",
                                  marginTop: "12px",
                                  margin: "0px 5px",
                                }}
                              ></div>
                              <Typography
                                variant="body1"
                                sx={{
                                  fontWeight: "bold",
                                  color: "#000",
                                  mt: 1,
                                }}
                              >
                                {msg?.product_info?.whole_price}{" "}
                                {msg?.product_info?.currency === 1 ? "؋" : "$"}
                              </Typography>
                            </Box>
                          </>
                        )}
                        <Box
                          display={"flex"}
                          justifyContent={
                            msg?.images?.length === 0 ? "space-between" : "end"
                          }
                          padding={
                            msg?.images?.length === 0 ? "0px" : "0px 5px"
                          }
                          alignItems={"center"}
                        >
                          {msg.sender === userID && (
                            <>
                              {msg?.images?.length === 0 && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  {!msg?.product_info?.images?.length && (
                                    <IconButton
                                      onClick={() => handleEditMessage(msg)}
                                    >
                                      <EditIcon fontSize="small" />
                                    </IconButton>
                                  )}
                                  <IconButton
                                    onClick={() => handleDelete(msg.id)}
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </Box>
                              )}
                            </>
                          )}

                          <Typography
                            variant="caption"
                            sx={{
                              marginTop: "5px",
                              textAlign: "right",
                              display: "block",
                              color: "gray",
                              fontSize: "10px",
                            }}
                          >
                            {dayjs(msg.send_at).format("hh:mm A")}
                          </Typography>
                        </Box>
                      </Paper>
                    </Box>
                    {msg.sender === userID && (
                      <Avatar
                        alt={msg.sender_profile.username}
                        src={msg.sender_profile.image}
                        sx={{ marginLeft: 1 }}
                      />
                    )}
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        </>
      ) : (
        <NoMessage />
      )}
      {/* Divider */}
      <Divider />

      {filePreviews.length > 0 && (
        <Grid container justifyContent={"center"} pt={2}>
          {filePreviews.map((preview, index) => (
            <Box key={index} position={"relative"} sx={{ marginRight: 1 }}>
              <Box
                component={"img"}
                src={preview}
                sx={{
                  width: "8vw",
                  height: "13vh",
                  mb: 2,
                  borderRadius: "10px",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "-8px",
                  right: "-5px",
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={() => handleRemoveImage(index)}
              >
                <CancelIcon color="red" fontSize="large" />
              </Box>
            </Box>
          ))}
        </Grid>
      )}

      {/* Chat Input */}
      <Box
        sx={{
          padding: { xs: "5px", sm: "10px" },
          display: "flex",
          alignItems: "center",
          backgroundColor: "#fff",
          borderTop: "1px solid #e0e0e0",
          boxShadow: "0px -2px 5px rgba(0,0,0,0.1)", // Subtle shadow for a professional look
        }}
        component={"form"}
        encType="multipart/form-data"
        onSubmit={handleSendMessage}
      >
        <TextField
          variant="outlined"
          fullWidth
          placeholder={t("chat.typeMessage")}
          name="message"
          value={formData.message}
          onChange={handleInputChange}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault(); // Prevent default Enter behavior
              if (!formData?.message.trim() && files.length === 0) {
                return; // Prevent sending empty messages
              } else {
                handleSendMessage(e); // Call the send message function
              }
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  aria-label="attach file"
                  component="label"
                  sx={{
                    backgroundColor: "#f0f0f0",
                    borderRadius: "50%",
                    padding: "8px",
                    "&:hover": { backgroundColor: "#e0e0e0" },
                    transition: "background-color 0.2s ease",
                  }}
                >
                  <input
                    hidden
                    multiple
                    type="file"
                    onChange={handleFileChange}
                  />
                  <AttachFileIcon sx={{ color: "#757575" }} />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  type="submit"
                  sx={{
                    padding: "10px",
                    backgroundColor: loading ? "#e0e0e0" : "#1976d2",
                    color: "#fff",
                    borderRadius: "50%",
                    boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
                    "&:hover": {
                      backgroundColor: loading ? "#e0e0e0" : "#1565c0",
                    },
                    transition: "background-color 0.3s ease",
                  }}
                  onClick={handleSendMessage}
                  disabled={
                    loading ||
                    (formData.message.trim() === "" && files.length === 0) ||
                    loadingEdit
                  }
                >
                  {loading ? (
                    <CircularProgress size={20} sx={{ color: "#79a384" }} />
                  ) : (
                    <SendIcon sx={{ fontSize: { xs: "20px", sm: "24px" } }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            backgroundColor: "#f9f9f9",
            borderRadius: "50px",
            padding: "5px",
            input: {
              padding: "12px 15px",
              fontSize: { xs: "14px", sm: "16px" },
              color: "#333",
            },
            "& fieldset": {
              border: "none", // Remove border for a cleaner look
            },
          }}
          multiline
          maxRows={4} // Set maximum rows if needed
        />
      </Box>

      <ConfirmDialog message={t("chat.deleteMessage")} />
      <ConfirmDialogDeleteImage message={t("chat.deleteImage")} />
    </Box>
  );
};

export default Chat;
