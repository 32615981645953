// components/dashboard/students/SkeletonLoading.js
import React from "react";
import { Grid, Skeleton } from "@mui/material";

const SkeletonLoading = () => {
  return (
    <Grid container mt={1} padding={"20px"} bgcolor={"white"}>
      <Grid container>
        <Grid item xs={8.5}>
          <Skeleton variant="text" width="80%" height={30} />
          <Skeleton variant="text" width="60%" />
          <Skeleton variant="text" width="60%" />
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="40%" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SkeletonLoading;
