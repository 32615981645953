import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { AccountCircle, Lock } from "@mui/icons-material"; // Icons for login and password
import useAdd from "../../../api/useAdd";
import { useAuth } from "../../../context/AuthContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

function Login() {
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const [showPassword, setShowPassword] = useState(false);

  const [formData, setFormData] = useState({
    login: "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const { handleAdd, resetForm, loading, responseData } = useAdd(
    "login",
    "",
    t("user.successFully"),
    t("user.failedLogin")
  );

  useEffect(() => {
    if (resetForm) {
      setFormData({
        login: "",
        password: "",
      });

      auth.login(responseData);
      navigate("/");
      setFormErrors({});
    }
  }, [resetForm, responseData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) errors[key] = t("user.failedRequired");
    });

    if (Object.keys(errors).length === 0) {
      const customerData = new FormData();
      Object.keys(formData).forEach((key) =>
        customerData.append(key, formData[key])
      );
      handleAdd(customerData);
    } else {
      setFormErrors(errors);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: "" });
  };

  const textFieldStyles = {
    input: {
      color: theme.palette.text.primary,
      backdropFilter: "blur(10px)",
      bgcolor: "rgba(255, 255, 255, 0.7)", // More opacity for better readability
      borderRadius: "10px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.grey[300],
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main,
      },
    },
    label: {
      color: theme.palette.grey[600],
      "&.Mui-focused": {
        color: theme.palette.primary.main,
      },
    },
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "88vh",
        width: "100%",

        background: `linear-gradient(135deg, #e0f7fa 30%, #fce4ec 90%)`,
      }}
    >
      <CssBaseline />
      <Grid
        item
        xs={11.5}
        sm={8}
        md={6}
        lg={5}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Card
          elevation={12}
          sx={{
            width: "100%",
            maxWidth: "500px", // Set max width for larger screens
            borderRadius: "10px", // Rounded corners
            padding: isSmallScreen ? 2 : 3, // Adjust padding for smaller screens
            boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.2)", // Subtle shadow
            background: "rgba(255, 255, 255, 0.8)", // Semi-transparent background
            backdropFilter: "blur(20px)", // Glassmorphism effect
            transition: "transform 0.3s ease-in-out",
            "&:hover": {
              transform: "translateY(-10px)", // Subtle hover effect
            },
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                component="h1"
                variant={isSmallScreen ? "h5" : "h4"}
                color={theme.palette.primary.main}
                fontWeight="700"
                sx={{ mb: 2 }}
              >
                {t("user.welcomeAsanBawar")}
              </Typography>
              <Typography
                variant="body2"
                color={theme.palette.text.secondary}
                sx={{ mb: 3, fontStyle: "italic" }}
              >
                {t("user.signYourAccount")}
              </Typography>
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  type="text"
                  size="medium"
                  id="login"
                  label={t("user.login")}
                  name="login"
                  value={formData.login}
                  error={!!formErrors.login}
                  helperText={formErrors.login}
                  onChange={handleChange}
                  autoFocus
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle /> {/* Login Icon */}
                      </InputAdornment>
                    ),
                    sx: textFieldStyles.input,
                  }}
                  InputLabelProps={{
                    sx: textFieldStyles.label,
                  }}
                />
                <TextField
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  label={t("user.password")}
                  size="medium"
                  error={!!formErrors.password}
                  helperText={formErrors.password}
                  sx={{ mt: 3 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock /> {/* Password Icon */}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    sx: textFieldStyles.input,
                  }}
                  InputLabelProps={{
                    sx: textFieldStyles.label,
                  }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 4,
                    mb: 2,
                    width: "100%",
                    padding: "12px",
                    fontWeight: "bold",
                    fontSize: "16px",
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    ":hover": {
                      backgroundColor: theme.palette.primary.dark,
                    },
                    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)", // Light shadow
                    transition: "all 0.3s ease-in-out",
                    transform: loading ? "scale(0.98)" : "scale(1)", // slight shrink effect while loading
                  }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : t("user.signIn")}
                </Button>

                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mt: 2 }}
                >
                  <Grid item xs>
                    <Link to="/reset-password">
                      <Typography
                        variant="body2"
                        color={theme.palette.text.secondary}
                        sx={{
                          ":hover": { color: theme.palette.primary.main },
                        }}
                      >
                        {t("user.forgotPassword")}
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to="/register">
                      <Typography
                        variant="body2"
                        color={theme.palette.text.secondary}
                        sx={{
                          ":hover": { color: theme.palette.primary.main },
                        }}
                      >
                        {t("user.dontHaveAccount")}
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
                <Grid container justifyContent={"center"}>
                  <Link to="/email-verify">
                    <Typography
                      variant="body2"
                      color={theme.palette.text.secondary}
                      sx={{
                        ":hover": { color: theme.palette.primary.main },
                      }}
                    >
                      {t("user.verifyEmail")}
                    </Typography>
                  </Link>
                </Grid>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Login;
