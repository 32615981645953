import React from "react";
import { Box, Skeleton } from "@mui/material";

const InboxLoading = () => {
  return (
    <Box>
      {[...Array(5)].map((_, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "10px 15px",
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          <Skeleton variant="circular" width={40} height={40} />
          <Box sx={{ marginLeft: 2, width: "100%" }}>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="60%" />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default InboxLoading;
