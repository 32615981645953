import { Button, Grid, TextField, Typography, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Rating } from "@mui/material";
import useAdd from "../../../../api/useAdd";
import { useAuth } from "../../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Review = ({ product }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  const [reviewData, setReviewData] = useState({
    user: user?.user.id,
    product: product,
    rating: 0,
    text: "",
  });

  const { handleAdd, resetForm } = useAdd(
    "review",
    token,
    "Your review submitted",
    "Your review field to submit"
  );

  useEffect(() => {
    if (resetForm) {
      setReviewData({
        user: user?.user.id,
        product: product,
        rating: 0,
        text: "",
      });
    }
  }, [resetForm]);

  const handleInputChange = (e) => {
    setReviewData({ ...reviewData, [e.target.name]: e.target.value });
  };

  const handleRatingChange = (event, newValue) => {
    setReviewData({ ...reviewData, rating: newValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (token) {
      handleAdd(reviewData);
    } else {
      navigate("/login");
    }
  };

  return (
    <Grid container justifyContent={"center"}>
      <Grid
        item
        xs={12}
        padding={"20px"}
        component={"form"}
        bgcolor={"#f5f5f5"}
        borderRadius={2}
        boxShadow={3}
        mt={2}
      >
        {" "}
        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          {t("product.rating")}
        </Typography>
        <Rating
          name="rating"
          value={reviewData.rating}
          onChange={handleRatingChange}
        />
        <TextField
          id="comment-input"
          label={t("product.ratingMessage")}
          name="text"
          value={reviewData.text}
          onChange={handleInputChange}
          fullWidth
          multiline
          rows={3}
          variant="outlined"
          sx={{
            backgroundColor: "white",
            borderRadius: "8px",
            mt: 2,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: "1em", borderRadius: 2 }}
          onClick={handleSubmit}
        >
          {t("product.submit")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Review;
