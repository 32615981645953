import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Rating,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Paper,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ImageSlider from "./imageSlider/ImageSlider";
import Comments from "./comments/Comments";
import { Link, useNavigate, useParams } from "react-router-dom";
import Context from "../../../context/Context";
import useFetchObjects from "../../../api/useFetchObjects";
import useFetchObject from "../../../api/useFetchObject";
import { useAuth } from "../../../context/AuthContext";
import Review from "./review/Review";
import SkeletonLoading from "../../../components/skeletonLoading/SkeletonLoading";
import ErrorPage from "../../../components/errorPage/ErrorPage";
import NoData from "../../../components/noData/NoData";
import PinDropIcon from "@mui/icons-material/PinDrop";
import EmailIcon from "@mui/icons-material/Email";
import LinkIcon from "@mui/icons-material/Link";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import MessageTwoToneIcon from "@mui/icons-material/MessageTwoTone";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useTranslation } from "react-i18next";

const typographyStyle = {
  fontSize: "20px",
};

const ProductDetails = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const [newRating, setNewRating] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const { addToCart, cartItems, removeCartItem } = useContext(Context);
  const navigate = useNavigate();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { id } = useParams();

  const {
    data: product,
    isLoading,
    isError,
  } = useFetchObject(["Products"], "products", id);
  const { data: reviewsData } = useFetchObjects(
    ["review"],
    `review/?product=${id}`
  );

  useEffect(() => {
    if (reviewsData?.length) {
      const totalRating = reviewsData.reduce(
        (acc, review) => acc + review.rating,
        0
      );
      setNewRating(totalRating / reviewsData.length);
    }
  }, [reviewsData]);

  const calculateDiscountPrice = (price, discount) => {
    return discount ? (price - (price * discount) / 100).toFixed(2) : price;
  };

  dayjs.extend(relativeTime); // Extend dayjs to support relative time
  // Function that takes an ISO date as a parameter and returns the appropriate format
  function formatDateBasedOnAge(isoDate) {
    const date = dayjs(isoDate);
    const now = dayjs();

    // Check if the date is within the last 7 days
    if (now.diff(date, "day") < 7) {
      // If it's less than 7 days ago, return "time ago" format
      return date.fromNow();
    } else {
      // Otherwise, return the full date and time format
      return date.format("D MMM YYYY, h:mm A");
    }
  }

  if (isLoading) {
    return (
      <Grid container mt={1} padding={"20px"} spacing={2}>
        {Array.from({ length: 6 }).map((_, index) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <SkeletonLoading />
          </Grid>
        ))}
      </Grid>
    );
  }

  if (isError) {
    return (
      <Grid container>
        <ErrorPage />
      </Grid>
    );
  }

  if (product?.length === 0) {
    return (
      <Grid container>
        <NoData />
      </Grid>
    );
  }

  return (
    <Grid container mt={2} spacing={3} padding={"10px"}>
      <Grid item xs={12} md={7} textAlign={"center"} padding={"0px 20px"}>
        <ImageSlider images={product?.images} />
        {/* Seller Information */}
      </Grid>
      <Grid item xs={12} md={5} padding={"0px 20px"}>
        <Typography
          variant="h3"
          fontWeight="bold"
          textTransform="capitalize"
          gutterBottom
        >
          {product?.name}
        </Typography>
        <Box
          sx={{
            backgroundColor: "lightblue",
            color: "darkgreen",
            padding: "5px 10px",
            fontWeight: "bold",
            borderRadius: "10px",
            display: "inline-block",
          }}
        >
          {t("product.inStock")}: {product?.quantity}
        </Box>

        <Box mt={2} display="flex" flexDirection="column" gap={2}>
          <Box display="flex" gap={2} alignItems="center">
            <Typography variant="h6">{t("product.colors")} :</Typography>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {product?.colors?.map((color, index) => (
                <Paper
                  key={index}
                  elevation={3}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 10px",
                    borderRadius: "20px",
                  }}
                >
                  <Box
                    sx={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                      backgroundColor: `${color?.name?.toLowerCase()}`,
                      border: "1px solid #ccc",
                      marginRight: "10px",
                    }}
                  />
                  <Typography variant="body2" textTransform="capitalize">
                    {color?.name}
                  </Typography>
                </Paper>
              ))}
            </Box>
          </Box>

          <Box display="flex" alignItems="center">
            <Rating
              name="simple-controlled"
              value={newRating}
              onClick={() => setConfirmOpen(true)}
              onChange={(event, newRating) => setNewRating(newRating)}
            />
            <Typography ml={1} color="text.secondary" fontSize="12px">
              ({reviewsData?.length} {t("product.reviews")})
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" mt={1} gap={1}>
            <Typography variant="h5" style={typographyStyle}>
              <strong>{t("product.retailPrice")} : </strong>
            </Typography>
            <Typography
              variant="h3"
              fontWeight={"bold"}
              color={"green"}
              fontSize={"23px"}
            >
              {product.price} {product.currency === 1 ? "؋" : "$"}
            </Typography>
            {product.discount > 0 && (
              <Typography
                sx={{
                  textDecoration: "line-through",
                  color: "gray",
                  fontSize: "23px",
                }}
              >
                {calculateDiscountPrice(product.price, product.discount)}{" "}
                {product.currency === 1 ? "؋" : "$"}
              </Typography>
            )}
          </Box>

          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h5" style={typographyStyle}>
              <strong>{t("product.wholePrice")} : </strong>
            </Typography>

            <Typography
              variant="h3"
              display={"flex"}
              alignItems={"center"}
              fontWeight={"bold"}
              color={"green"}
              fontSize={"23px"}
            >
              {product?.whole_price} {product.currency === 1 ? "؋" : "$"}
              <div
                style={{ fontSize: "15px", color: "gray", marginLeft: "5px" }}
              >
                {" "}
                ({t("home.moreThan")} {product?.whole_price_count}{" "}
                {t("product.units")})
              </div>
            </Typography>
          </Box>

          <Box display="flex" alignItems="center">
            <Typography variant="h5" style={typographyStyle}>
              <strong>{t("product.postedDate")} : </strong>
            </Typography>
            <Typography
              variant="h5"
              color={"gray"}
              textTransform={"capitalize"}
              ml={1}
              fontSize={"20px"}
            >
              <strong>{formatDateBasedOnAge(product?.created_at)}</strong>
            </Typography>
          </Box>

          <Grid container spacing={2} mt={1}>
            <Button
              variant={
                cartItems.some((item) => item.id === product.id)
                  ? "outlined"
                  : "contained"
              }
              sx={{ textTransform: "capitalize", margin: "4px" }}
              color="secondary"
              startIcon={<ShoppingCartIcon style={{ margin: "0px 10px" }} />}
              onClick={() => {
                cartItems.some((item) => item.id === product.id)
                  ? removeCartItem(product.id)
                  : addToCart(product, quantity);
              }}
              disabled={product?.quantity === 0}
            >
              {cartItems.some((item) => item.id === product.id)
                ? t("product.added")
                : t("product.addToCard")}
            </Button>

            <Button
              variant="contained"
              sx={{
                textTransform: "capitalize",
                margin: "4px",
                backgroundColor: "#FFB100", // Active state background matches subscribe button
                color: "#000000",
                ":hover": { backgroundColor: "#FFC500" },
              }}
              color="primary"
              onClick={() => {
                navigate("/shopping-cart");
              }}
              disabled={product?.quantity === 0}
            >
              {t("product.placeOrder")}
            </Button>

            <Button
              variant="contained"
              sx={{
                textTransform: "capitalize",
                backgroundColor: "#FFB100", // Active state background matches subscribe button
                color: "#000000",
                ":hover": { backgroundColor: "#FFC500" },
                margin: "4px",
              }}
              color="primary"
              onClick={() => {
                navigate("/chat", { state: { product } });
              }}
            >
              <MessageTwoToneIcon style={{ color: "white" }} />
            </Button>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} md={10.3} lg={9.7} margin={"0px auto"}>
        {/* Product Description */}
        <Box mt={2} p={2} borderRadius="10px" bgcolor="#f9f9f9">
          <Typography variant="h6" gutterBottom>
            {t("product.productDescription")}
          </Typography>
          <Typography variant="body2" color="text.primary">
            {product?.description}
          </Typography>
        </Box>
        <Box mt={2} p={2} borderRadius="10px" bgcolor="#f1f1f1">
          <Typography variant="h6" gutterBottom>
            {t("product.sellerInformation")}
          </Typography>
          <Box display="flex" alignItems="center" gap={1}>
            <PersonPinIcon />
            <Typography variant="body2">
              {t("product.seller")}:{" "}
              {`${product?.display_user?.first_name}  ${product?.display_user?.last_name}` ||
                t("product.notProvided")}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <PinDropIcon />
            <Typography variant="body2">
              {t("user.shopAddress")}:{" "}
              {product?.display_user?.shop_address || "Not provided"}
            </Typography>
          </Box>

          {product?.is_visible_seller_info ||
            (product?.display_user?.is_staff && (
              <>
                <Box display="flex" alignItems="center" gap={1} mt={1}>
                  <EmailIcon />
                  <Typography variant="body2">
                    {t("product.email")}: {product?.display_user?.email}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={1} mt={1}>
                  <PhoneIcon />
                  <Typography variant="body2">
                    {t("product.phone")}: {product?.display_user?.phone_number}
                  </Typography>
                </Box>
              </>
            ))}

          <Box display="flex" alignItems="center" gap={1} mt={1}>
            <LinkIcon />
            <Button
              onClick={() => {
                navigate("/products", {
                  state: { userInfo: product?.display_user },
                });
              }}
              style={{ textTransform: "capitalize" }}
            >
              {t("product.seeAll")}
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Comments product={product?.id} />
      </Grid>

      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle style={{ textAlign: "center" }}>
          {t("product.yourRating")}
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "lightGray",
            width: { xs: "80vw", md: "36vw" },
          }}
        >
          <Review product={product?.id} user={user?.user.id} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmOpen(false)}
            variant="contained"
            color="primary"
          >
            {t("product.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ProductDetails;
